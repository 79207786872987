
import useThread from '@front/composition/use-thread';
import {
  defineComponent,
  computed,
} from '@vue/composition-api';
import VcrToggler from '@components/contact-popup/toggler/toggler.vue';
import VcrContactPopupView from '@components/contact-popup/views/view.vue';

export default defineComponent({
  components: {
    VcrToggler,
    VcrContactPopupView,
  },

  setup() {
    const {
      threads,
      loadDirectConversationsForCurrentBrand,
    } = useThread();

    loadDirectConversationsForCurrentBrand();

    const togglerVisible = computed(() => {
      return threads.value.length > 0;
    });

    return {
      togglerVisible,
    };
  },
});
