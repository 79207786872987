export enum EAppModuleTypes {
  adminEntry = 'AdminEntry',
  postInstall = 'PostInstall',
  visitorEntry = 'VisitorEntry',
}

export interface IAppModule {
  id: string,
  type: string,
  title: string,
  url: string,
}

export interface ICreateAppModule {
  type: string,
  title: string,
  url: string,
}

export interface IEditableAppModule extends ICreateAppModule {}