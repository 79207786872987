import IBaseUser from '@typing/base-user';

export const DELETED_VISITOR_ID = 'removed';

export default interface IVisitorResource extends IBaseUser {
  company?: string;
  language?: string;
  postalCode?: string;
  countryCode?: string;
}

export const isDeletedVisitor = (visitor: IVisitorResource) => visitor.id === DELETED_VISITOR_ID;
