export enum EDateTimeFormat {
  FullDate = 'DD.MM.YYYY',
  Time = 'HH:mm',
  TimeWithTimezone = 'HH:mmZ',
  WeekdayAndDate = 'dddd, D MMM. YYYY',
  WeekdayAndTime = 'dddd, HH:mm',
}

export enum EWeekDay {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export function isWeekDay(dayName: EWeekDay | string): dayName is EWeekDay {
  return Object.values(EWeekDay).map(day => day.toString()).includes(dayName);
}
