import Vue from 'vue';
import {
  ServerTable, ClientTable,
} from 'vue-tables-2';
import DatatableSearch from '@components/datatable/datatable-search.vue';
import DatatableSort from '@components/datatable/datatable-sort.vue';
import vcrTheme from '@components/datatable/theme';
import IContactResource from '@api/resource/contact';

export default () => {
  /* https://matanya.gitbook.io/vue-tables-2/custom-template */
  const customWidgets = {
    genericFilter: DatatableSearch,
    sortControl: DatatableSort,
  };

  const options = {
    preserveState: true,
    customSorting: {
      name(ascending: boolean) {
        return (a: IContactResource, b: IContactResource) => {
          const firstA = a.firstName?.toLowerCase() || '';
          const firstB = b.firstName?.toLowerCase() || '';

          if (ascending) {
            return firstA >= firstB ? 1 : -1;
          }

          return firstA <= firstB ? 1 : -1;
        };
      },
    },
    filterAlgorithm: {
      name(row: IContactResource, query: string) {
        return (row.firstName?.toLowerCase() + ' ' + row.lastName?.toLowerCase()).includes(query.toLowerCase());
      },
    },
  };

  Vue.use(ClientTable, options, false, vcrTheme, customWidgets);
  Vue.use(ServerTable, options, false, vcrTheme, customWidgets);
};
