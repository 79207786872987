import * as api from '@api/video-call';
import i18n from '@front/lang/main';
import updateOrCreate from '@front/composition/helpers/update-or-create';
import {
  IEditVideoCallPayload,
  IVideoCallParticipant,
  EVideoCallParticipantStatus,
  IScheduleVideoCallPayload,
  IGetVideoCallsFilters,
  IVideoCall,
  EVideoCallParticipantStateInCall,
} from '@typing/video-call';
import {
  reactive,
  toRefs,
} from '@vue/composition-api';

const state = reactive({
  videoCalls: [] as Array<IVideoCall>,
});

export default function useVideoCalls() {
  function getVideoCallsByIds (ids: Array<string>) {
    const list = [] as Array<IVideoCall>;

    ids.forEach(videoCallId => {
      const videoCall = state.videoCalls.find(call => call.id === videoCallId);
      videoCall && list.push(videoCall);
    });

    return list;
  }

  function getVideoCallById (id: string) {
    return state.videoCalls.find(call => call.id === id);
  }

  function updateOrCreateVideoCall(videoCallResource: IVideoCall) {
    videoCallResource.participants.forEach(
      participant => {
        /**
         * guest user doesn't have `name` and `position`
         * show text `Guest` as name and email as position
         */
        if (participant.userData.isVideoCallGuest) {
          participant.userData = {
            ...participant.userData,
            get name() {
              return participant.userData.email;
            },
            get position() {
              return String(i18n.t('guest'));
            },
          };
        }
      },
    );
    updateOrCreate(state.videoCalls, videoCallResource);
  }

  async function fetchVideoCalls(filters: IGetVideoCallsFilters = {}) {
    const result = await api.getVideoCalls(filters);
    result.data.data.forEach(updateOrCreateVideoCall);
    return result.data.data;
  }

  async function fetchVideoCall(videoCallId: string) {
    const result = await api.getVideoCall(videoCallId);
    updateOrCreateVideoCall(result.data.data);
    return result.data.data;
  }

  async function scheduleVideoCall(
    expoId: string,
    payload: IScheduleVideoCallPayload,
  ) {
    const result = await api.scheduleVideoCall(expoId, payload);
    updateOrCreateVideoCall(result.data.data);
    return result.data.data;
  }

  async function editVideoCall(
    videoCallId: string,
    payload: IEditVideoCallPayload,
  ) {
    const result = await api.editVideoCall(videoCallId, payload);
    updateOrCreateVideoCall(result.data.data);
    return result.data.data;
  }

  async function cancelVideoCall(videoCallId: string) {
    const result = await api.cancelVideoCall(videoCallId);
    updateOrCreateVideoCall(result.data.data);
    return result.data.data;
  }

  async function setParticipantStatus(
    participant: IVideoCallParticipant,
    status: EVideoCallParticipantStatus,
  ) {
    const result = await api.setParticipantStatus(participant.id, status);
    onChangedParticipantStatus({
      videoCallId: result.data.data.videoCallId,
      participantId: result.data.data.id,
      status: result.data.data.status,
    });
  }

  async function setParticipantStateInCall(
    participantId: string,
    videoCallId: string,
    state: EVideoCallParticipantStateInCall,
  ) {
    const updatedParticipant = await api.setParticipantStateInCall(participantId, state);
    onParticipantUpdated({
      videoCallId,
      participantId,
      updatedParticipant: updatedParticipant.data.data,
    });
  }

  async function refreshStateInCall(
    participantId: string,
  ) {
    await api.refreshStateInCall(participantId);
  }

  function onChangedParticipantStatus(payload: {
    videoCallId: string,
    participantId: string,
    status: EVideoCallParticipantStatus,
  }) {
    const videoCall = state.videoCalls.find(call => call.id === payload.videoCallId);
    if (!videoCall) {
      return;
    }

    const participant = videoCall.participants.find(participant => participant.id === payload.participantId);
    if (!participant) {
      return;
    }

    participant.status = payload.status;
  }

  function onChangedParticipantStateInCall(payload: {
    videoCallId: string,
    participantId: string,
    stateInCall: EVideoCallParticipantStateInCall,
  }) {
    const videoCall = state.videoCalls.find(call => call.id === payload.videoCallId);
    if (!videoCall) {
      return;
    }

    const participant = videoCall.participants.find(participant => participant.id === payload.participantId);
    if (!participant) {
      return;
    }

    participant.stateInCall = payload.stateInCall;
  }

  function onParticipantUpdated(payload: {
    videoCallId: string,
    participantId: string,
    updatedParticipant: IVideoCallParticipant,
  }) {
    const videoCall = state.videoCalls.find(call => call.id === payload.videoCallId);
    if (!videoCall) {
      return;
    }

    const participant = videoCall.participants.find(participant => participant.id === payload.participantId);
    if (!participant) {
      return;
    }

    Object.assign(participant, payload.updatedParticipant);
  }

  return {
    ...toRefs(state),
    getVideoCallById,
    getVideoCallsByIds,
    fetchVideoCalls,
    fetchVideoCall,
    scheduleVideoCall,
    editVideoCall,
    cancelVideoCall,
    setParticipantStatus,
    setParticipantStateInCall,
    refreshStateInCall,
    onChangedParticipantStatus,
    onChangedParticipantStateInCall,
  };
}
