import {
  reactive, computed,
} from '@vue/composition-api';
import IStage from '@typing/stage';
import * as api from '@api/stage';
import ICreateStagePayload from '@api/payload/create-stage';
import IEditStagePayload from '@api/payload/edit-stage';
import IReorderStagePayload from '@api/payload/reorder-stage';

const state = reactive({
  stageList: [] as IStage[],
  loadingList: false,
  loadingStage: [] as string[],
});

export default function useStageList() {
  function updateOrCreate(stageData: IStage) {
    const stage = state.stageList.find(stage => stage.id === stageData.id);
    if (stage) {
      Object.assign(stage, stageData);
    } else {
      state.stageList.push(stageData);
    }
  }

  async function loadStageList(eventId: string) {
    if (state.loadingList) {
      return null;
    }

    state.loadingList = true;
    try {
      const result = await api.loadAll(eventId);
      for (const stage of result.data.data) {
        updateOrCreate(stage);
      }
      return result;
    } finally {
      state.loadingList = false;
    }
  }

  async function createStage(eventId: string, payload: ICreateStagePayload) {
    const result = await api.create(eventId, payload);
    updateOrCreate(result.data.data);
    return result;
  }

  function isLoadingStage(stageId: string) {
    return state.loadingStage.includes(stageId);
  }

  async function loadStage(stageId: string) {
    if (isLoadingStage(stageId)) {
      return null;
    }

    state.loadingStage.push(stageId);
    try {
      const result = await api.load(stageId);
      updateOrCreate(result.data.data);
      return result;
    } finally {
      state.loadingStage = state.loadingStage.filter(id => id !== stageId);
    }
  }

  async function editStage(stageId: string, payload: IEditStagePayload) {
    const result = await api.edit(stageId, payload);
    updateOrCreate(result.data.data);
    return result;
  }

  function getStage(stageId: string) {
    return state.stageList.find(stage => stage.id === stageId);
  }

  function getStagesForEvent(eventId: string) {
    return state.stageList.filter(stage => stage.eventId === eventId);
  }

  async function removeStage(stageId: string) {
    const result = await api.remove(stageId);
    state.stageList = state.stageList.filter(stage => stage.id !== stageId);
    return result;
  }

  async function reorderStage(payload: IReorderStagePayload) {
    if (state.loadingList) {
      return;
    }
    state.loadingList = true;
    try {
      const result = await api.reorder(payload);
      state.stageList = result.data.data;
      return result;
    } finally {
      state.loadingList = false;
    }
  }

  return {
    stageList: computed(() => state.stageList),
    loadingList: computed(() => state.loadingList),
    loadStageList,
    createStage,
    getStage,
    loadStage,
    isLoadingStage,
    editStage,
    getStagesForEvent,
    removeStage,
    reorderStage,
  };
}
