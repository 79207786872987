
import {
  computed,
  defineComponent,
} from '@vue/composition-api';
import useError from '@front/composition/use-error';

export default defineComponent({
  setup(_, ctx) {
    const {
      isError404,
    } = useError();

    const texts = computed(() => {
      if (isError404.value) {
        return {
          title: ctx.root.$t('pageErrors.error404.title'),
          description: ctx.root.$t('pageErrors.error404.description'),
        };
      }

      return {
        title: ctx.root.$t('pageErrors.error500.title'),
        description: ctx.root.$t('pageErrors.error500.description'),
        ctaLink: ctx.root.$t('pageErrors.error500.ctaLink'),
        ctaText: ctx.root.$t('pageErrors.error500.ctaText'),
      };
    });

    return {
      isError404,
      texts,
    };
  },
});
