import {
  TEventTask,
} from '@typing/event-task-manager';

const useEventTaskManager = () => {
  let list: Array<TEventTask> = [];

  const addTask = (task: TEventTask) => {
    list.push(task);
  };

  const removeTask = (task: TEventTask) => {
    list = list.filter(taskFromList => {
      return task !== taskFromList;
    });
  };

  const runTasks = (event?: unknown) => {
    list.forEach(task => {
      task(event);
    });
  };

  const removeAllTasks = () => {
    list = [];
  };

  return {
    addTask,
    removeTask,
    runTasks,
    removeAllTasks,
  };
};

export default useEventTaskManager;
