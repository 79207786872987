import api from '@api/api';
import IHttpResponse from '@api/http-response';
import IUser from '@typing/user';
import IRegisterUser from '@api/payload/register-user';
import IResetPassword from '@api/payload/reset-password';
import IRegisterUserForBrandInvite from '@api/payload/register-user-for-brand-invite';
import PREVENT_ERROR_INTERCEPTOR from '@utils/prevent-error-interceptor';

export const getCurrentUser = (): Promise<IHttpResponse<IUser>> => {
  return api.get('/api/me');
};

export const updateCurrentUser = (payload: FormData): Promise<IHttpResponse<IUser>> => {
  return api.post('/api/me', payload, {
    headers: {
      [PREVENT_ERROR_INTERCEPTOR]: true,
    },
  });
};

export const login = (payload: { email: string; password: string }) => {
  return api.post('/api/_login', payload, {
    headers: {
      [PREVENT_ERROR_INTERCEPTOR]: true,
    },
  });
};

export const logout = () => {
  return api.post('/api/_logout');
};

export const registerUser = (
  invite: string,
  payload: IRegisterUser,
): Promise<IHttpResponse<any>> => {
  return api.post(`/api/_register/${invite}`, payload, {
    headers: {
      [PREVENT_ERROR_INTERCEPTOR]: true,
    },
  });
};

export const registerUserForBrandInvite = (
  invite: string,
  payload: IRegisterUserForBrandInvite,
): Promise<IHttpResponse<any>> => {
  return api.post(`/api/_register-brand/${invite}`, payload, {
    headers: {
      [PREVENT_ERROR_INTERCEPTOR]: true,
    },
  });
};

export const requestResetPasswordEmail = (payload: {
  email: string;
}): Promise<IHttpResponse<any>> => {
  return api.post('/api/_forgot-password', payload);
};

//? any
export const resetPassword = (payload: IResetPassword): Promise<IHttpResponse<any>> => {
  return api.post('/api/_password-reset', payload);
};
