import * as api from '@api/auth';
import IBaseUser from '@typing/base-user';
import IUser from '@typing/user';
import {
  computed, reactive,
} from '@vue/composition-api';
import IUpdateCurrentUser from '@api/payload/update-current-user';
import initializeBroadcastingForAssociate from '@front/broadcasting/salescalls/index';
import IPermission from '@typing/permission';
import {
  EPermission,
} from '@front/composition/use-permissions-list';

const state = reactive({
  user: null as null | IUser,
});

export default function useUser() {
  async function loadCurrentUser() {
    const user = await api.getCurrentUser();
    setUser(user.data.data);
    initializeBroadcastingForAssociate(user.data.data.id);
  }

  function setUser(user: IUser) {
    state.user = user;
  }

  async function updateCurrentUser(payload: IUpdateCurrentUser) {
    const formData = new FormData();

    if (typeof payload.salutation === 'string') {
      formData.append('salutation', payload.salutation);
    }

    if (typeof payload.firstName === 'string') {
      formData.append('firstName', payload.firstName);
    }
    if (typeof payload.lastName === 'string') {
      formData.append('lastName', payload.lastName);
    }

    if (typeof payload.position === 'string') {
      formData.append('position', payload.position);
    }

    if (typeof payload.preferredLocale === 'string') {
      formData.append('preferredLocale', payload.preferredLocale);
    }

    if (typeof payload.password === 'string') {
      formData.append('password', payload.password);
    }

    if (payload.avatar) {
      formData.append('avatar', payload.avatar);
    }

    const result = await api.updateCurrentUser(formData);
    setUser(result.data.data);
    return result;
  }

  const permissionNames = computed((): EPermission[] | undefined => {
    return state.user?.permissions?.map((permission: IPermission) => permission.name);
  });

  function hasPermission(name: EPermission) {
    return permissionNames.value?.includes(name);
  }

  function hasPermissionIn(names: EPermission[]) {
    return permissionNames.value
      ?.some(permission => names.includes(permission));
  }

  function isMe(associateId: string) {
    return state.user?.id === associateId;
  }

  const canAccessContacts = computed(() => {
    return hasPermissionIn([
      EPermission.createAndEditAllContacts,
      EPermission.createAndEditPersonalContacts,
      EPermission.deleteContacts,
    ]);
  });

  const canAccessSales = computed(() => {
    return hasPermissionIn([
      EPermission.manageLeadsAndSalesCalls,
      EPermission.downloadLeadsReport,
    ]);
  });

  const canAccessApiIntegration = computed(() => {
    return hasPermission(EPermission.editApiAndWebhooks);
  });

  const canAccessWebhooks = computed(() => {
    return hasPermission(EPermission.editApiAndWebhooks);
  });

  const canAccessApps = computed(() => {
    return hasPermission(EPermission.installApps);
  });

  function getFullName(user: IBaseUser) {
    if (user.name) {
      return user.name;
    }

    return `${user.firstName || ''} ${user.lastName || ''}`.trim();
  }


  return {
    currentUser: computed(() => state.user),
    loadCurrentUser,
    setUser,
    updateCurrentUser,
    hasPermission,
    hasPermissionIn,
    canAccessContacts,
    canAccessSales,
    canAccessApiIntegration,
    canAccessWebhooks,
    canAccessApps,
    isMe,
    getFullName,
  };
}
