import {
  reactive,
  toRefs,
} from '@vue/composition-api';
import {
  IBrand,
} from '@typing/brand';
import * as brandApi from '@api/brand';
import ILanguage from '@front/types/lang';
import IUpdateBrand from '@api/payload/update-brand';
import ITranslations from '@front/types/translations';
import useLang from './use-lang';
import useBrandList from './use-brand-list';

const state = reactive({
  name: '' as  null | string,
  logoUrl:'' as null | string,
  logoFile: null as null | File,
  headerImageUrl: '' as null | string,
  headerImageFile: null as null | File,
  color: null as null | string,
  description: '' as null | string,
  descriptionTranslations: {} as ITranslations,
  contactDetails: '' as null | string,
  contactDetailsTranslations: {} as ITranslations,
  imprint: '' as null | string,
  imprintTranslations: {} as ITranslations,
  privacyPolicy: '' as null | string,
  privacyPolicyTranslations: {} as ITranslations,
  isBroadcasting: false,
  languages: [] as ILanguage[],
  loading: false,
  updating: false,
});

const {
  getLanguagesFromLocales,
  getLocalesFromLanguages,
  systemLanguages,
} = useLang();

const {
  setCurrentBrand,
} = useBrandList();

export default function useBrand() {
  function setInitialData(brandData: IBrand) {
    state.name = brandData.name;
    state.logoUrl = brandData.logoUrl;
    state.color = brandData.color;
    state.description = brandData.description;
    state.contactDetails = brandData.contactDetails;
    state.headerImageUrl = brandData.headerImage;
    state.logoFile = null;
    state.headerImageFile = null;
    state.imprint = brandData.imprint;
    state.privacyPolicy = brandData.privacyPolicy;
    state.isBroadcasting = brandData.isBroadcasting;
    state.languages = brandData.supportedLocales.length ? getLanguagesFromLocales(brandData.supportedLocales) : systemLanguages.value;
    state.descriptionTranslations = brandData.descriptionTranslations;
    state.contactDetailsTranslations = brandData.contactDetailsTranslations;
    state.imprintTranslations = brandData.imprintTranslations;
    state.privacyPolicyTranslations = brandData.privacyPolicyTranslations;

    setCurrentBrand(brandData);
  }

  async function loadBrand() {
    state.loading = true;
    try {
      const result = await brandApi.load();
      const isBroadcastingData = await brandApi.loadBrandBroadcastStatus();
      setInitialData(result.data.data);
      state.isBroadcasting = isBroadcastingData.data.data.isBroadcasting;
    } finally {
      state.loading = false;
    }
  }

  async function save(payload: IUpdateBrand = {
    name: state.name || undefined,
    color: state.color || undefined,
    description: JSON.stringify(state.descriptionTranslations),
    contactDetails: JSON.stringify(state.contactDetailsTranslations),
    logo: state.logoFile || undefined,
    headerImage: state.headerImageFile || undefined,
    imprint: JSON.stringify(state.imprintTranslations),
    privacyPolicy: JSON.stringify(state.privacyPolicyTranslations),
    supportedLocales: JSON.stringify(getLocalesFromLanguages(state.languages)),
  }) {
    if (state.updating) {
      return;
    }

    state.updating = true;
    try {
      const result = await brandApi.editBrand(payload);
      setInitialData(result.data.data);
    } finally {
      state.updating = false;
    }
  }

  async function updateBrandBroadcastStatus(isBroadcasting: number) {
    if(state.updating){
      return;
    }

    state.updating = true;
    try{
      const result = await brandApi.editBrandBroadcastStatus(isBroadcasting);
      state.isBroadcasting = result.data.data.isBroadcasting;
    }finally {
      state.updating = false;
    }
  }

  return {
    ...toRefs(state),

    updateBrandBroadcastStatus,
    loadBrand,
    save,
    setInitialData,
  };

}
