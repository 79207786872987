import api from '@api/api';
import {
  IUserTrackingPayload,
} from '@typing/tracking-user';

export const sendUserTrackingRequest = (payload: IUserTrackingPayload): Promise<void> => {
  return api.post('/api/_manage-associate-activity', payload);
};

export const sendImAliveRequest = (): Promise<void> => {
  return api.post('/api/refresh-availability-state');
};
