import { render, staticRenderFns } from "./nav-menu.vue?vue&type=template&id=c0d89ce8&scoped=true&"
import script from "./nav-menu.vue?vue&type=script&lang=ts&"
export * from "./nav-menu.vue?vue&type=script&lang=ts&"
import style0 from "./nav-menu.vue?vue&type=style&index=0&id=c0d89ce8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0d89ce8",
  null
  
)

export default component.exports