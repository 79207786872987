import api from '@api/api';
import IHttpResponse from '@api/http-response';
import IStage from '@typing/stage';
import ICreateStagePayload from '@api/payload/create-stage';
import IEditStagePayload from '@api/payload/edit-stage';
import IReorderStagePayload from '@api/payload/reorder-stage';

export const loadAll = (eventId: string): Promise<IHttpResponse<IStage[]>> => {
  return api.get(`/api/_brand/events/${eventId}/stages`);
};

export const create = (eventId: string, payload: ICreateStagePayload): Promise<IHttpResponse<IStage>> => {
  const formData = new FormData();
  formData.append('name', JSON.stringify(payload.name));
  formData.append('image', payload.image);
  return api.post(`/api/_brand/events/${eventId}/stages`, formData);
};

export const load = (stageId: string): Promise<IHttpResponse<IStage>> => {
  return api.get(`/api/_brand/stages/${stageId}`);
};

export const edit = (stageId: string, payload: IEditStagePayload): Promise<IHttpResponse<IStage>> => {
  const formData = new FormData();
  if (typeof payload.name !== 'undefined') {
    formData.append('name', JSON.stringify(payload.name));
  }

  if (payload.image) {
    formData.append('image', payload.image);
  }
  return api.uploadFile(`/api/_brand/stages/${stageId}`, formData);
};

export const remove = (stageId: string): Promise<IHttpResponse<any>> => {
  return api.delete(`/api/_brand/stages/${stageId}`);
};

export const reorder = ({
  stageId,
  order,
}: IReorderStagePayload): Promise<IHttpResponse<IStage[]>> => {
  return api.put(`/api/_brand/stages/${stageId}/order`, {
    order,
  });
};
