import {
  reactive, toRefs,
} from '@vue/composition-api';
import * as api from '@api/expo';
import IExpo from '@typing/expo';
import IEditExpoPayload from '@api/payload/edit-expo';
import ICreateExpo from '@api/payload/create-expo';
import IExpoBrand from '@typing/expo-brand';
import IReorderExpoBrand from '@api/payload/reorder-expo-brand';
import IStoredExpoBrand from '@typing/stored-expo-brand';
import IStoredExpoContact from '@typing/stored-expo-contact';
import IContactResource from '@api/resource/contact';
import IStoredExpoAssociate from '@typing/stored-expo-associate';
import IUser from '@typing/user';
import IStoredExpoProduct from '@typing/stored-expo-product';
import IStoredExpoShowroom from '@typing/stored-expo-showroom';
import IAttachedProduct from '@typing/attached-product';
import IAttachedShowroom from '@typing/attached-showroom';
import IReorderExpoProduct from '@api/payload/reorder-expo-product';
import IReorderExpoShowroom from '@api/payload/reorder-expo-showroom';
import IEditExpoBrandSettings from '@api/payload/edit-expo-brand-settings';
import IStoredExpoApp from '@typing/stored-expo-app';
import {
  IApp,
} from '@typing/app';

const state = reactive({
  loading: false,
  expoList: [] as IExpo[],
  ladingExpo: [] as string[],
  brandList: [] as IStoredExpoBrand[],
  attachedContactList: [] as IStoredExpoContact[],
  loadingAttachedContactList: [] as string[],
  attachedAssociates: [] as IStoredExpoAssociate[],
  loadingAttachedAssociates: [] as string[],
  attachedProductList: [] as IStoredExpoProduct[],
  loadingAttachedProducts: [] as string[],
  attachedShowroomList: [] as IStoredExpoShowroom[],
  loadingAttachedShowrooms: [] as string[],
  loadingAttachedApps: [] as string[],
  attachedApps: [] as IStoredExpoApp[],
});

export default function useExpoList() {
  function loadExpoList() {
    if (state.loading) {
      return false;
    }
    state.loading = true;
    return api
      .getAll()
      .then(result => {
        state.expoList = result.data.data;
      })
      .finally(() => {
        state.loading = false;
      });
  }

  function updateOrCreate(expoData: IExpo) {
    const expo = state.expoList.find(expo => expo.id === expoData.id);
    if (expo) {
      Object.assign(expo, expoData);
    } else {
      state.expoList.push(expoData);
    }
  }

  function isLoadingExpo(expoId: string) {
    return state.ladingExpo.includes(expoId);
  }

  async function loadExpo(expoId: string) {
    if (isLoadingExpo(expoId)) {
      return null;
    }

    state.ladingExpo.push(expoId);

    try {
      const result = await api.get(expoId);
      updateOrCreate(result.data.data);
      return result;
    } finally {
      state.ladingExpo = state.ladingExpo.filter(id => id !== expoId);
    }
  }

  async function updateExpo(expoId: string, payload: IEditExpoPayload) {
    const formData = new FormData();

    if (typeof payload.name !== 'undefined') {
      formData.append('name', JSON.stringify(payload.name));
    }

    if (typeof payload.description !== 'undefined') {
      formData.append('description', JSON.stringify(payload.description));
    }

    if (typeof payload.defaultLocale === 'string') {
      formData.append('defaultLocale', payload.defaultLocale);
    }

    if (payload.logo) {
      formData.append('logo', payload.logo);
    }

    if (payload.headerImage) {
      formData.append('headerImage', payload.headerImage);
    }

    if (typeof payload.primaryColor === 'string') {
      formData.append('primaryColor', payload.primaryColor);
    }

    if (payload.isPublic !== undefined) {
      formData.append('isPublic', payload.isPublic.toString());
    }

    if (payload.active !== undefined) {
      formData.append('active', payload.active.toString());
    }

    const result = await api.update(expoId, formData);
    updateOrCreate(result.data.data);
    return result;
  }

  async function updateExpoBrandSettings(expoId: string, brandId: string, payload: IEditExpoBrandSettings) {
    const updatedBrand = (await api.updateBrandSettings(expoId,  brandId, payload)).data.data;

    // Update the brand in state with new data
    setBrandList(getBrandsForExpo(expoId).map(brand => {
      return brand.id === updatedBrand.id
        ? updatedBrand
        : brand;
    }), expoId);
  }

  async function createExpo(payload: ICreateExpo) {
    const result = await api.create(payload);
    updateOrCreate(result.data.data);
    return result;
  }

  function getExpo(expoId: string) {
    return state.expoList.find(expo => expo.id === expoId);
  }

  function setBrandList(brandList: IExpoBrand[], expoId: string) {
    state.brandList = brandList
      .map(brand =>
        Object.assign({}, brand, {
          expoId,
        }),
      )
      .sort((a, b) => (a.order || 0) - (b.order || 0));
  }

  async function loadBrands(expoId: string) {
    const result = await api.loadBrands(expoId);
    setBrandList(result.data.data, expoId);
    return result;
  }

  async function reorderBrand(payload: IReorderExpoBrand) {
    const result = await api.reorderBrand(payload);
    setBrandList(result.data.data, payload.expoId);
    return result;
  }

  async function detachBrands(expoId: string, brands: string[]) {
    const result = await api.detachBrands(expoId, brands);
    setBrandList(result.data.data, expoId);
    return result;
  }

  function getBrandsForExpo(expoId: string) {
    return state.brandList.filter(brand => brand.expoId === expoId);
  }

  function setAttachedContactList(contactList: IContactResource[], expoId: string) {
    state.attachedContactList = contactList.map(contact =>
      Object.assign({}, contact, {
        expoId,
      }),
    );
  }

  function isLoadingAttachedContactList(expoId: string) {
    return state.loadingAttachedContactList.includes(expoId);
  }

  async function loadAttachedContacts(expoId: string) {
    if (isLoadingAttachedContactList(expoId)) {
      return null;
    }

    state.loadingAttachedContactList.push(expoId);
    try {
      const result = await api.loadAttachedContacts(expoId);
      setAttachedContactList(result.data.data, expoId);
      return result;
    } finally {
      state.loadingAttachedContactList = state.loadingAttachedContactList.filter(
        id => id !== expoId,
      );
    }
  }

  async function detachContacts(expoId: string, contacts: string[]) {
    await api.detachContacts(expoId, contacts);
    loadAttachedContacts(expoId);
  }

  function setAttachedAssociates(associateList: IUser[], expoId: string) {
    state.attachedAssociates = associateList.map(associate =>
      Object.assign({}, associate, {
        expoId,
      }),
    );
  }

  function isLoadingAttachedAssociates(expoId: string) {
    return state.loadingAttachedAssociates.includes(expoId);
  }

  async function loadAttachedAssociates(expoId: string) {
    if (isLoadingAttachedAssociates(expoId)) {
      return null;
    }

    state.loadingAttachedAssociates.push(expoId);
    try {
      const result = await api.loadAttachedAssociates(expoId);
      setAttachedAssociates(result.data.data, expoId);
      return result;
    } finally {
      state.loadingAttachedAssociates = state.loadingAttachedAssociates.filter(id => id !== expoId);
    }
  }

  async function detachAssociates(expoId: string, associates: string[]) {
    await api.detachAssociates(expoId, associates);
    loadAttachedAssociates(expoId);
  }

  function setAttachedProductList(productList: IAttachedProduct[], expoId: string) {
    state.attachedProductList = productList
      .map(product =>
        Object.assign({}, product, {
          expoId,
        }),
      )
      .sort((a, b) => a.order - b.order);
  }

  function isLoadingAttachedProducts(expoId: string) {
    return state.loadingAttachedProducts.includes(expoId);
  }

  async function loadAttachedProducts(expoId: string) {
    if (isLoadingAttachedProducts(expoId)) {
      return null;
    }

    state.loadingAttachedProducts.push(expoId);

    try {
      const result = await api.loadAttachedProducts(expoId);
      setAttachedProductList(result.data.data, expoId);
      return result;
    } finally {
      state.loadingAttachedProducts = state.loadingAttachedProducts.filter(id => id !== expoId);
    }
  }

  async function detachProducts(expoId: string, products: string[]) {
    const result = await api.detachProducts(expoId, products);
    setAttachedProductList(result.data.data, expoId);
  }

  async function reorderProduct(payload: IReorderExpoProduct) {
    const result = await api.reorderProduct(payload);
    setAttachedProductList(result.data.data, payload.expoId);
    return result;
  }

  function setAttachedShowroomList(showroomList: IAttachedShowroom[], expoId: string) {
    state.attachedShowroomList = showroomList
      .map(showroom =>
        Object.assign({}, showroom, {
          expoId,
        }),
      )
      .sort((a, b) => a.order - b.order);
  }

  function isLoadingAttachedShowrooms(expoId: string){
    return state.loadingAttachedShowrooms.includes(expoId);
  }

  async function loadAttachedShowrooms(expoId: string) {
    if(isLoadingAttachedShowrooms(expoId)){
      return null;
    }

    state.loadingAttachedShowrooms.push(expoId);

    try{
      const res = await api.loadAttachedShowrooms(expoId);
      setAttachedShowroomList(res.data.data, expoId);
      return res;
    }finally{
      state.loadingAttachedShowrooms = state.loadingAttachedShowrooms.filter(id => id !== expoId);
    }
  }

  async function detachShowrooms(expoId: string, showrooms: string[]) {
    const res = await api.detachShowrooms(expoId, showrooms);
    setAttachedShowroomList(res.data.data, expoId);
  }

  async function reorderShowroom(payload: IReorderExpoShowroom){
    const res = await api.reorderShowroom(payload);
    setAttachedShowroomList(res.data.data, payload.expoId);
    return res;
  }

  async function removeExpos(expos: string[]) {
    await api.removeExpos(expos);
    state.expoList = state.expoList.filter(expo => !expos.includes(expo.id));
  }

  function setAttachedApps(appList: IApp[], expoId: string) {
    state.attachedApps = appList.map(app =>
      Object.assign(
        {},
        app,
        {
          expoId,
        },
      ),
    );
  }

  function isLoadingAttachedApps(expoId: string) {
    return state.loadingAttachedApps.includes(expoId);
  }

  async function loadAttachedApps(expoId: string) {
    if (isLoadingAttachedApps(expoId)) {
      return null;
    }

    state.loadingAttachedApps.push(expoId);
    try {
      const result = await api.loadAttachedApps(expoId);
      setAttachedApps(result.data.data, expoId);
      return result;
    } finally {
      state.loadingAttachedApps = state.loadingAttachedApps.filter(id => id !== expoId);
    }
  }

  async function detachApp(expoId: string, appId: string) {
    return api.detachApp(expoId, appId);
  }

  return {
    ...toRefs(state),
    loadExpoList,
    updateOrCreate,
    loadExpo,
    updateExpo,
    updateExpoBrandSettings,
    getExpo,
    createExpo,
    loadBrands,
    reorderBrand,
    detachBrands,
    getBrandsForExpo,
    loadAttachedContacts,
    isLoadingAttachedContactList,
    detachContacts,
    isLoadingAttachedAssociates,
    loadAttachedAssociates,
    detachAssociates,
    isLoadingExpo,
    loadAttachedProducts,
    detachProducts,
    isLoadingAttachedProducts,
    reorderProduct,
    isLoadingAttachedShowrooms,
    loadAttachedShowrooms,
    detachShowrooms,
    reorderShowroom,
    removeExpos,
    isLoadingAttachedApps,
    loadAttachedApps,
    detachApp,
  };
}
