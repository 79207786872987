import PREVENT_ERROR_INTERCEPTOR from '@utils/prevent-error-interceptor';
import axios from 'axios';
import router, {
  RouterName, unauthorizedRoutes,
} from '@front/router';
import useBrandList from '@front/composition/use-brand-list';
import {
  useToaster,
} from '@convidera-team/virtrex-design-system';
import transformErrorToMessage from '@utils/transform-error-to-message';
import {
  is404Error, is5XXError,
} from '@utils/errors';

const api = axios.create();

api.interceptors.response.use(
  response => response,
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    const {
      status,
    } = error.response;
    if (status === 401) {

      if (
        typeof router.currentRoute.name === 'string'
        && !unauthorizedRoutes.includes(router.currentRoute.name)
        || !router.currentRoute.name
      ) {
        router
          .push({
            name: RouterName.login,
          })
          .catch(() => {});
      }

      return Promise.reject(error);
    }

    if (status === 419) {
      return window.location.reload();
    }

    if (is404Error(error) || is5XXError(error)) {
      return Promise.reject(error);
    }

    if (error.response.config?.headers && error.response.config.headers[PREVENT_ERROR_INTERCEPTOR] === true) {
      return Promise.reject(error);
    }

    useToaster().error({
      message: transformErrorToMessage(error, status === 422),
    });

    return Promise.reject(error);
  },
);

api.interceptors.request.use(
  config => {
    const {
      currentBrand,
    } = useBrandList();

    if (currentBrand.value && currentBrand.value.id) {
      config.headers['BrandId'] = currentBrand.value.id;
    }
    return config;
  },
  error => Promise.reject(error),
);

const uploadFile = (uri: string, data: FormData, headers: {} = {}) => {
  data.append('_method', 'PUT');

  return api.post(uri, data, {
    headers,
  });
};

export default {
  ...api,
  uploadFile,
};
