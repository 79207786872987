import * as api from '@api/associate-tracking-activity';
import {
  IUserTrackingPayload,
} from '@typing/tracking-user';
import {
  EUserEventName,
} from '@typing/tracking-user';

const {
  v4: uuidv4,
} = require('uuid');

const sessionHash = <string>uuidv4();

let imAliveTimer: number | null = null;
let imAliveRequestSendingStarted = false;

const IM_REQUEST_INTERVAL = 60; // seconds

export default function useUserTracking () {
  function sendEventVideoCallCreated(relatedId: string){
    const payload: IUserTrackingPayload = {
      activityName: EUserEventName.VideoCallCreated,
      relatedId,
      socketId: sessionHash,
    };

    api.sendUserTrackingRequest(payload).catch(() => {});
  }

  function sendEventParticipantVideoCallJoined(relatedId: string){
    const payload: IUserTrackingPayload = {
      activityName: EUserEventName.ParticipantVideoCallJoined,
      relatedId,
      socketId: sessionHash,
    };

    api.sendUserTrackingRequest(payload).catch(() => {});
  }

  function sendEventParticipantVideoCallEnded(relatedId: string){
    const payload: IUserTrackingPayload = {
      activityName: EUserEventName.ParticipantVideoCallEnded,
      relatedId,
      socketId: sessionHash,
    };

    api.sendUserTrackingRequest(payload).catch(() => {});
  }

  function initImAliveRequest() {
    if (imAliveRequestSendingStarted) {
      return;
    }

    imAliveRequestSendingStarted = true;

    function sendRequest() {
      api.sendImAliveRequest()
        .then(() => {
          imAliveTimer = window.setTimeout(() => {
            sendRequest();
          }, IM_REQUEST_INTERVAL * 1000);
        });
    }

    sendRequest();
  }

  function stopImAliveRequest() {
    imAliveTimer && window.clearTimeout(imAliveTimer);
  }

  return {
    sendEventParticipantVideoCallJoined,
    sendEventParticipantVideoCallEnded,
    sendEventVideoCallCreated,
    initImAliveRequest,
    stopImAliveRequest,
  };
}
