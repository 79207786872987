import useEventTaskManager from '@front/composition/use-event-task-manager';

const {
  addTask: onRecentActivitiesUpdated,
  removeTask: offRecentActivitiesUpdated,
  runTasks: runRecentActivitiesUpdated,
} = useEventTaskManager();

const joinChannel = (associateId?: string) => {
  if (!window.Echo || !associateId) {
    return;
  }

  window.Echo.private(`salescalls.${associateId}`)
    .notification(() => {
      runRecentActivitiesUpdated();
    });
};

export {
  joinChannel,
  onRecentActivitiesUpdated,
  offRecentActivitiesUpdated,
};
