
import {
  defineComponent, computed, watch, 
} from '@vue/composition-api';
import {
  VtxModal,
} from '@convidera-team/virtrex-design-system';
import useModal, {
  EModal,
} from '@front/composition/use-modal/use-modal';

export default defineComponent({
  components: {
    VtxModal,
  },

  setup(_, context) {
    const {
      name, payload, close,
    } = useModal();

    const component = computed(() => {
      switch (name.value) {
        case EModal.createExpo:
          return () => import('@components/create-expo-modal/create-expo-modal.vue');
        case EModal.inviteAssociate:
          return () => import('@components/invite-associate-modal/invite-associate-modal.vue');
        case EModal.confirmation:
          return () => import('@components/confirmation-modal/confirmation-modal.vue');
        case EModal.editAssociate:
          return () => import('@components/edit-associate-modal/edit-associate-modal.vue');
        case EModal.userDetails:
          return () => import('@components/user-details-modal/user-details-modal.vue');
        case EModal.createContact:
          return () => import('@components/contact-creation-modal/contact-creation-modal.vue');
        case EModal.editContact:
          return () => import('@components/edit-contact-modal/edit-contact-modal.vue');
        case EModal.createShowroom:
          return () => import('@components/create-showroom-modal/create-showroom-modal.vue');
        case EModal.editShowroom:
          return () => import('@components/edit-showroom-modal/edit-showroom-modal.vue');
        case EModal.manageExpoBrands:
          return () => import('@components/expo-settings/brands/modal/modal.vue');
        case EModal.manageExpoVisitors:
          return () => import('@components/expo-settings/visitors/modal/modal.vue');
        case EModal.manageExpoAssociates:
          return () => import('@components/expo-settings/associates/modal/modal.vue');
        case EModal.manageExpoApps:
          return () => import('@components/expo-settings/apps/modal/modal.vue');
        case EModal.manageExpoProducts:
          return () => import('@components/expo-settings/products/modal/modal.vue');
        case EModal.manageExpoShowrooms:
          return () => import('@components/expo-settings/showrooms/modal/modal.vue');
        case EModal.manageExpoTeaser:
          return () => import('@components/expo-settings/teaser/modal/modal.vue');
        case EModal.scheduleExpoEmail:
          return () => import('@components/expo-settings/email/modal/modal.vue');
        case EModal.createEvent:
          return () => import('@components/create-event-modal/create-event-modal.vue');
        case EModal.editProductVariant:
          return () => import('@components/edit-product-variant-modal/edit-product-variant-modal.vue');
        case EModal.editEvent:
          return () => import('@components/edit-event-modal/edit-event-modal.vue');
        case EModal.createStage:
          return () => import('@components/create-stage-modal/create-stage-modal.vue');
        case EModal.editStage:
          return () => import('@components/edit-stage-modal/edit-stage-modal.vue');
        case EModal.createPanel:
          return () => import('@components/create-panel-modal/create-panel-modal.vue');
        case EModal.editPanel:
          return () => import('@components/edit-panel-modal/edit-panel-modal.vue');
        case EModal.managePanelProducts:
          return () => import('@components/panel-products-modal/modal.vue');
        case EModal.createProduct:
          return () => import ('@components/create-product-modal/create-product-modal.vue');
        case EModal.createProductVariant:
          return () => import ('@components/create-product-variant-modal/create-product-variant-modal.vue');
        case EModal.createRole:
          return () => import ('@components/create-role-modal/create-role-modal.vue');
        case EModal.editRole:
          return () => import ('@components/edit-role-modal/edit-role-modal.vue');
        case EModal.viewRole:
          return () => import ('@components/view-role-modal/view-role-modal.vue');
        case EModal.uploadPanelVideo:
          return () => import ('@components/upload-panel-video-modal/upload-panel-video-modal.vue');
        case EModal.editTag:
          return () => import('@components/edit-tag-modal/edit-tag-modal.vue');
        case EModal.downloadLeadsList:
          return () => import ('@components/download-leads-list-modal/download-leads-list-modal.vue');
        case EModal.selectBrandTag:
          return () => import('@components/select-brand-tag-modal/select-brand-tag-modal.vue');
        case EModal.createPackage:
          return () => import('@components/create-package-modal/create-package-modal.vue');
        case EModal.editPackage:
          return () => import('@components/edit-package-modal/edit-package-modal.vue');
        case EModal.leadVisitHistoryList:
          return () => import('@components/lead/visit-history-list-modal/visit-history-list-modal.vue');
        case EModal.selectProvider:
          return () => import('@components/select-provider-modal/select-provider-modal.vue');
        case EModal.alert:
          return () => import ('@components/alert-modal/alert-modal.vue');
        case EModal.apiToken:
          return () => import('@components/api-token/create-api-token-modal.vue');
        case EModal.purchaseLicence:
          return () => import('@components/purchase-licence-modal/modal/modal.vue');
        case EModal.editWebhooks:
          return () => import('@components/edit-webhooks-modal/edit-webhooks-modal.vue');
        case EModal.editWorkingHours:
          return () => import('@components/edit-working-hours-modal/edit-working-hours-modal.vue');
        case EModal.languageSupport:
          return () => import('@components/language-support-modal/language-support-modal.vue');
        case EModal.installableApp:
          return () => import('@components/installable-app-modal/modal.vue');
        default:
          return null;
      }
    });

    watch(() => context.root.$route, () => close());

    return {
      payload,
      close,
      component,
    };
  },
});
