import * as api from '@api/auth';
import useUser from '@front/composition/use-user';
import {
  RouterName,
} from '@front/router';
import VueRouter, {
  RawLocation,
} from 'vue-router';
import IRegisterUser from '@api/payload/register-user';
import IResetPassword from '@api/payload/reset-password';
import IRegisterUserForBrandInvite from '@api/payload/register-user-for-brand-invite';

export default function useAuth() {
  function registerNewUser(invite: string, payload: IRegisterUser) {
    return api.registerUser(invite, payload);
  }

  function registerUserForBrandInvite(invite: string, payload: IRegisterUserForBrandInvite) {
    return api.registerUserForBrandInvite(invite, payload);
  }

  function checkIfUserLoggedIn(router: VueRouter, to?: RawLocation | null) {
    const {
      loadCurrentUser,
    } = useUser();
    loadCurrentUser()
      .then(() => {
        router.push(to || {
          name: RouterName.home,
        });
      })
      .catch(() => {});
  }

  function comparePasswords(password: string, passwordConfirmation: string) {
    if (password.length === 0 || passwordConfirmation.length === 0) {
      return false;
    }
    return password === passwordConfirmation;
  }

  function resetPasswordEmail(email: string) {
    return api.requestResetPasswordEmail({
      email: email,
    });
  }

  function resetPassword(payload: IResetPassword) {
    return api.resetPassword(payload);
  }

  return {
    comparePasswords,
    registerNewUser,
    resetPasswordEmail,
    resetPassword,
    checkIfUserLoggedIn,
    registerUserForBrandInvite,
  };
}
