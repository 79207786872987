import useEventTaskManager from '@front/composition/use-event-task-manager';
import {
  IThread, 
} from '@typing/thread';

const {
  addTask: onDirectChatThreadWithAssociateCreated,
  removeTask: offDirectChatThreadWithAssociateCreated,
  runTasks: runDirectChatThreadWithAssociateCreated,
} = useEventTaskManager();

const joinChannel = (associateId: string) => {
  if (!window.Echo) {
    return;
  }

  window.Echo.private(`salescalls.${associateId}`)
    .listen( '.App\\Events\\Broadcast\\ChatThread\\DirectChatThreadWithAssociateCreated', (thread: IThread) => {
      runDirectChatThreadWithAssociateCreated(thread);
    });
};

export {
  joinChannel,
  onDirectChatThreadWithAssociateCreated,
  offDirectChatThreadWithAssociateCreated,
};
