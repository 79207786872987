import IVisitorResource from '@api/resource/visitor';
import IExpo from '@typing/expo';

export default interface IContactRequest {
  id: string,
  type: EContactRequestType,
  status: EContactRequestStatus,
  state: EContactRequestStates,
  visitorIsOnExpo: boolean,
  visitor: IVisitorResource,
  expo: IExpo,
  isAnonymous: boolean,
  conversationId: null | string
  recentActivityAt: string
}

export enum EContactRequestType {
  outgoing = 'outgoing',
  incoming = 'incoming',
  possible = 'possible',
}

export enum EContactRequestStatus {
  new = 'new',
  declined = 'declined',
  finished = 'finished',
  lead = 'lead',
}

export enum EContactRequestStates {
  offline = 'offline',
  onExpo = 'onExpo',
  onBrand = 'onBrand',
}
