import {
  extend,
  configure,
  setInteractionMode,
} from 'vee-validate';
import {
  required,
  email,
  confirmed,
  min,
  min_value,
  max,
  max_value,
  ext,
  image,
  size,
} from 'vee-validate/dist/rules';
import i18n from '@front/lang/main';

setInteractionMode('eager');

configure({
  defaultMessage: (_, values) => String(i18n.t(`formValidation.messages.${values._rule_}`, values)),
});

extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('min', min);
extend('min_value', min_value);
extend('max', max);
extend('max_value', max_value);
extend('ext', ext);
extend('image', image);
extend('size', size);
