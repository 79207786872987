import api from '@api/api';
import IHttpResponse from '@api/http-response';
import IExpo from '@typing/expo';
import ICreateExpo from '@api/payload/create-expo';
import IExpoBrand from '@typing/expo-brand';
import IReorderExpoBrand from '@api/payload/reorder-expo-brand';
import IContactResource from '@api/resource/contact';
import IUser from '@typing/user';
import IPendingBrandInvite from '@api/resource/pending-brand-invite';
import IAttachedProduct from '@typing/attached-product';
import IAttachableProduct from '@typing/attachable-product';
import IAttachedShowroom from '@typing/attached-showroom';
import IAttachableShowroom from '@typing/attachable-showroom';
import IReorderExpoProduct from '@api/payload/reorder-expo-product';
import IReorderExpoShowroom from '@api/payload/reorder-expo-showroom';
import IInviteBrand from '@api/payload/invite-brand';
import IEditExpoBrandSettings from './payload/edit-expo-brand-settings';
import {
  IBrand,
} from '@typing/brand';
import {
  IApp,
} from '@typing/app';

export const getAll = (params?: {
  [key in string]: any
}): Promise<IHttpResponse<IExpo[]>> => {
  return api.get('/api/_brand/expos', {
    params,
  });
};

export const get = (expoId: string): Promise<IHttpResponse<IExpo>> => {
  return api.get(`/api/_brand/expos/${expoId}`);
};

export const update = (expoId: string, payload: FormData): Promise<IHttpResponse<IExpo>> => {
  return api.uploadFile(`/api/_brand/expos/${expoId}`, payload);
};

export const create = (payload: ICreateExpo): Promise<IHttpResponse<IExpo>> => {
  return api.post('/api/_brand/expos/', payload);
};

export const loadBrands = (expoId: string): Promise<IHttpResponse<IExpoBrand[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/brands`);
};

export const loadPendingBrandIvites = (
  expoId: string,
): Promise<IHttpResponse<IPendingBrandInvite[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/brands/invites`);
};

export const inviteBrand = (
  expoId: string,
  payload: IInviteBrand,
): Promise<IHttpResponse<IPendingBrandInvite>> => {
  return api.post(`/api/_brand/expos/${expoId}/brands`, payload);
};

export const cancelBrandInvite = (
  expoId: string,
  invites: string[],
): Promise<IHttpResponse<IPendingBrandInvite[]>> => {
  return api.delete(`/api/_brand/expos/${expoId}/brands/invites`, {
    params: {
      invites,
    },
  });
};

export const reorderBrand = ({
  expoId,
  brandId,
  order,
}: IReorderExpoBrand): Promise<IHttpResponse<IExpoBrand[]>> => {
  return api.put(`/api/_brand/expos/${expoId}/brands/${brandId}`, {
    order,
  });
};

export const updateBrandSettings = (expoId: string, brandId: string, payload: IEditExpoBrandSettings) : Promise<IHttpResponse<IBrand>> => {
  return api.put(`/api/_brand/expos/${expoId}/brands/${brandId}/settings`, payload);
};

export const detachBrands = (
  expoId: string,
  brands: string[],
): Promise<IHttpResponse<IExpoBrand[]>> => {
  return api.delete(`/api/_brand/expos/${expoId}/brands`, {
    params: {
      brands,
    },
  });
};

export const loadAttachedContacts = (
  expoId: string,
): Promise<IHttpResponse<IContactResource[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/contacts`, {
    params: {
      invited: 1,
    },
  });
};

export const loadPendingContacts = (
  expoId: string,
): Promise<IHttpResponse<IContactResource[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/contacts`, {
    params: {
      invited: 1,
      pending: 1,
    },
  });
};

export const detachContacts = (expoId: string, contacts: string[]): Promise<IHttpResponse<any>> => {
  return api.delete(`/api/_brand/expos/${expoId}/contacts`, {
    params: {
      contacts,
    },
  });
};

export const loadAvailableContacts = (
  expoId: string,
): Promise<IHttpResponse<IContactResource[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/contacts`, {
    params: {
      invited: 0,
    },
  });
};

export const attachContacts = (expoId: string, contacts: string[]): Promise<IHttpResponse<any>> => {
  return api.post(`/api/_brand/expos/${expoId}/contacts`, {
    contacts,
  });
};

export const attachContactsNow = (expoId: string, contacts: string[]): Promise<IHttpResponse<any>> => {
  return api.post(`/api/_brand/expos/${expoId}/contacts/now`, {
    contacts,
  });
};

export const loadAttachedAssociates = (expoId: string): Promise<IHttpResponse<IUser[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/associates`, {
    params: {
      attached: 1,
    },
  });
};

export const loadAvailableAssociates = (expoId: string): Promise<IHttpResponse<IUser[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/associates`, {
    params: {
      attached: 0,
    },
  });
};

export const detachAssociates = (
  expoId: string,
  associates: string[],
): Promise<IHttpResponse<any>> => {
  return api.delete(`/api/_brand/expos/${expoId}/associates`, {
    params: {
      associates,
    },
  });
};

export const attachAssociates = (
  expoId: string,
  associates: string[],
): Promise<IHttpResponse<any>> => {
  return api.post(`/api/_brand/expos/${expoId}/associates`, {
    associates,
  });
};

export const loadAttachedProducts = (
  expoId: string,
): Promise<IHttpResponse<IAttachedProduct[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/products`, {
    params: {
      attached: 1,
    },
  });
};

export const loadAvailableProducts = (
  expoId: string,
): Promise<IHttpResponse<IAttachableProduct[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/products`, {
    params: {
      attached: 0,
    },
  });
};

export const detachProducts = (
  expoId: string,
  products: string[],
): Promise<IHttpResponse<IAttachedProduct[]>> => {
  return api.delete(`/api/_brand/expos/${expoId}/products`, {
    params: {
      products,
    },
  });
};

export const attachProducts = (
  expoId: string,
  products: string[],
): Promise<IHttpResponse<IAttachedProduct[]>> => {
  return api.post(`/api/_brand/expos/${expoId}/products`, {
    products,
  });
};

export const reorderProduct = ({
  expoId,
  productId,
  order,
}: IReorderExpoProduct): Promise<IHttpResponse<IAttachedProduct[]>> => {
  return api.put(`/api/_brand/expos/${expoId}/products/${productId}`, {
    order,
  });
};

export const loadAttachedShowrooms = (
  expoId: string,
): Promise<IHttpResponse<IAttachedShowroom[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/showrooms`, {
    params: {
      attached: 1,
    },
  });
};

export const loadAvailableShowrooms = (
  expoId: string,
): Promise<IHttpResponse<IAttachableShowroom[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/showrooms`, {
    params: {
      attached: 0,
    },
  });
};

export const attachShowrooms = (expoId: string, showrooms: string[]): Promise<IHttpResponse<IAttachedShowroom[]>> => {
  return api.post(`/api/_brand/expos/${expoId}/showrooms`, {
    showrooms,
  });
};

export const detachShowrooms = (expoId: string, showrooms: string[]): Promise<IHttpResponse<IAttachedShowroom[]>> => {
  return api.delete(`/api/_brand/expos/${expoId}/showrooms`, {
    params: {
      showrooms,
    },
  });
};

export const reorderShowroom = ({
  expoId,
  showroomId,
  order,
}: IReorderExpoShowroom): Promise<IHttpResponse<IAttachedShowroom[]>> => {
  return api.put(`/api/_brand/expos/${expoId}/showrooms/${showroomId}`, {
    order,
  });
};

export const removeExpos = (expos: string[]): Promise<IHttpResponse<any>> => {
  return api.delete('/api/_brand/expos', {
    params: {
      expos,
    },
  });
};

export const requestLicenceForExpo = (expoId: string): Promise<IHttpResponse<any>> => {
  return api.get(`/api/_brand/expos/${expoId}/purchase-licence`);
};

export const loadAttachedApps = (expoId: string): Promise<IHttpResponse<IApp[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/apps`);
};

export const loadAvailableApps = (expoId: string): Promise<IHttpResponse<IApp[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/apps`, {
    params: {
      available: 1,
    },
  });
};

export const detachApp = (expoId: string, appId: string): Promise<IHttpResponse<void>> => {
  return api.delete(`/api/_brand/expos/${expoId}/apps/${appId}`);
};

export const attachApps = (
  expoId: string,
  appIds: string[],
): Promise<IHttpResponse<IApp[]>> => {
  return api.post(`/api/_brand/expos/${expoId}/apps`, {
    appIds,
  });
};
