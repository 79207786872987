export enum EContactPopupView {
  Thread = 'thread',
  AllThreads = 'all-threads',
}

export enum EContactPopupNotificationRelatedType {
  NewMessage = 'new-message',
}

export interface IContactPopupNotification {
  relatedId: string
  relatedType: EContactPopupNotificationRelatedType
}
