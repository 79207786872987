import {
  ESalutation, 
} from '@front/types/salutation';
import {
  EWeekDay,
} from '@typing/date-formats';

export default {
  languages: {
    en: 'Englisch',
    de: 'Deutsch',
    fr: 'Französisch',
    es: 'Spanisch',
    it: 'Italienisch',
    nl: 'Niederländisch',
    pl: 'Polnisch',
  },
  guest: 'gast',
  hidden: 'Hidden',
  anonymous: 'Anonymous',
  backLinkText: 'Zurück',
  deletedVisitor: 'Ehemaliger Besucher',

  dateTimeFormats: {
    calendar: {
      sameDay: '[Heute]',
      nextDay: '[Morgen]',
      lastDay: '[Gestern]',
    },
  },

  weekDays: {
    [EWeekDay.Monday]: 'Montag',
    [EWeekDay.Tuesday]: 'Dienstag',
    [EWeekDay.Wednesday]: 'Mittwoch',
    [EWeekDay.Thursday]: 'Donnerstag',
    [EWeekDay.Friday]: 'Freitag',
    [EWeekDay.Saturday]: 'Samstag',
    [EWeekDay.Sunday]: 'Sonntag',
  },

  common: {
    salutation: {
      [ESalutation.MALE]: 'Herr',
      [ESalutation.FEMALE]: 'Frau',
      [ESalutation.DIVERS]: 'ohne Anrede',
    },
  },

  topbar: {
    title: 'Virtuelle Messen',
    greeting: 'Hallo {userName}, willkommen in Virtrex!',
  },
  legal: {
    virtrex: 'Virtrex',
    text: 'All rights reserved.',
    imprint: 'Impressum',
    privacyPolicy: 'Datenschutz',
    imprintUrl: window.$vcrConfig.references.impressum,
    privacyPolicyUrl: window.$vcrConfig.references.privacyPolicyDE,
  },
  navMenu: {
    expos: 'Expos',
    products: 'Produkte',
    showrooms: 'Showrooms',
    associates: 'Team',
    contacts: 'Adressbuch',
    brand: 'Markeneinstellungen',
    salesCalls: 'Kundengespräche',
    sales: 'Sales',
    downloadAnalytics: 'Insights',
    settings: 'Settings',
    teamManagement: 'Teammanagement',
    apiIntegration: 'API-Integration',
    apps: 'Apps',
    webhooks: 'Webhooks',
  },

  general: {
    active: 'Aktiv',
    inactive: 'Inaktiv',
    close: 'Schließen',
    saveChanges: 'Angaben speichern',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    delete: 'Löschen',
    edit: 'Bearbeiten',
  },

  table: {
    allRowsSelected: 'Alle Einträge ausgewählt',
    count: 'Einträge {from} bis {to} von {count}|{count} Einträge|1 Eintrag',
    noResults: 'Es sind keine passenden Einträge vorhanden',
  },

  brandDetailsPage: {
    actionBar: {
      actions: {
        title: 'Livestream verwalten',
        activate: 'Livestream stoppen',
        deactivate: 'Livestream starten',
      },
    },
    title: 'Markeneinstellungen',
    name: {
      label: 'Markenname',
    },
    logo: {
      title: 'Logo',
      description:
        'Laden Sie hier ein Logo hoch, um Ihre Marke zu präsentieren.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 1:1; max. 1MB; .png oder .svg.',
    },
    headerImage: {
      title: 'Titelbild hochladen',
      description:
        'Laden Sie ein Titelbild hoch, das Ihre Marke repräsentiert. Dieses Bild wird auf Ihrem Markenprofil verwendet.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
    },
    color: {
      title: 'Primäre Farbe auswählen',
      description:
        'Wählen Sie Ihre Markenfarbe mittels RGB, HSL oder HEX-Code aus.',
      note:
        'Für ein optimales Ergebnis wählen Sie eine Farbe aus, welche Besuchende mit Ihrer Marke verbinden.',
    },
    language: {
      title: 'Sprachen',
      description: 'Bitte wählen Sie die Sprachen Ihrer Marke und speichern Sie die Beschreibung Ihrer Marke und Ihrer Produkte in diesen Sprachen ab. Die Sprachen einer Expo können Sie in den Expo-Einstellungen auswählen.',
      warning: {
        description: 'Wenn Sie Sprache(n) löschen, werden alle übersetzten Inhalte für diese Sprache(n) entfernt (z. B. Markeneinstellungen, Produktseiten, Showrooms, usw.). Wenn Sie die Löschung bestätigen, können die Inhalte nicht wieder hergestellt werden.',
      },
    },
    otherSettings: {
      title: 'Markenbeschreibung',
      description: 'In den folgenden Abschnitten können Sie zusätzliche Informationen über Ihre Marke hinzufügen. Füllen Sie die Eingabefelder unten aus, um eine vollständige Darstellung Ihrer Marke zu erhalten.',
      label: {
        description: 'Beschreiben Sie Ihre Marke',
        contactDetails: 'Kontaktinformationen',
      },
    },
    livestream: {
      title: 'Livestream Markenprofil',
      action: 'Stream-Anbieter wechseln',
      provider: {
        title: 'Stream-Anbieter',
      },
      streamKey: {
        title: 'Stream-Key',
        emptyText: 'Ein Fehler ist aufgetreten. Es konnte kein Stream-Key für Sie angelegt werden. Bitte wenden Sie sich an Ihren Kundenbetreuer.',
        clickHere: 'Hinzufügen',
      },
      serverLink: {
        title: 'Server-Link',
        emptyText: 'Ein Fehler ist aufgetreten. Es konnte kein Stream-Key für Sie angelegt werden. Bitte wenden Sie sich an Ihren Kundenbetreuer.',
        clickHere: 'Hinzufügen',
      },
    },
    legal: {
      title: 'Impressum & Datenschutz Einstellungen',
      imprint: 'Impressum',
      privacyPolicy: 'Datenschutz',
    },
    saveChanges: 'Angaben speichern',
  },

  expoCard: {
    cta: 'Expo betreten',
  },

  attachBrandToPanel: {
    title: 'Beitrag erstellen',
    closeButton: 'Abbrechen',
    description: 'Sie erstellen einen Beitrag, indem Sie das Datum und die Uhrzeit festlegen. Ebenso legen Sie hier die Marke fest, der den Beitrag durchführt.',
    button: 'Beitrag erstellen',
    language: {
      title: 'Sprache des Beitrags',
      label: 'Sprache auswählen',
    },

    dateTime: {
      title: 'Zeitraum auswählen',
      at: 'um',
      till: 'bis',
    },

    brand: {
      title: 'Marken',
      label: 'Wählen Sie eine Marke',
    },
  },

  panelCard: {
    menu: {
      edit: 'Bearbeiten',
      delete: 'Löschen',
    },
    deleteConfirmation: {
      title: 'Beitrag löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Beitrag löschen',
      description:
        'Wenn Sie den Beitrag <b>{name}</b> löschen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf „Beitrag löschen“.',
      namePlaceholder: 'Platzhalter',
    },
  },

  panelDetailsPage: {
    title: 'Beitragdetails',
    backButton: 'Zurück zur Programmübersicht',
    at: 'um',
    till: 'bis',
    teaser: 'Teaser des Beitrags',
    preview: 'Bildvorschau',
    streamKey: {
      title: 'Stream-Key',
      emptyText: 'Ein Fehler ist aufgetreten. Es konnte kein Stream-Key für Sie angelegt werden. Bitte wenden Sie sich an Ihren Kundenbetreuer.',
      clickHere: 'Hinzufügen',
    },
    serverLink: {
      title: 'Server-Link',
      emptyText: 'Ein Fehler ist aufgetreten. Es konnte kein Server-Link für Sie angelegt werden. Bitte wenden Sie sich an Ihren Kundenbetreuer.',
      clickHere: 'Hinzufügen',
    },
    speaker: {
      title: 'Sprechende',
    },
    broadcastVideo: {
      title: 'Archiv Aufzeichnung',
    },
    actionBar: {
      editButton: 'Beitrag bearbeiten',
      actions: {
        title: 'Beitrag archivieren',
        uploadVideo: 'Video hochladen',
        deleteVideo: 'Video löschen',
      },
    },
    products: {
      title: 'Produkte',
      cta: 'Produkte verwalten',
      defaultText:
        'Ziehen Sie die Produkte in die gewünschte Reihenfolge, in der sie dargestellt werden sollen.',
    },
    detachConfirmation: {
      title: 'Produkt löschen',
      buttonText: 'Produkt löschen',
      description: 'Das Produkt <b>{name}</b> wirklich löschen?',
    },
    manageModal: {
      title: 'Produkte verwalten',
      close: 'Abbrechen',
      description:
        'Fügen Sie hier Produkte aus Ihrem Produktkatalog Ihrem Event hinzu. Diese werden anschließend für die Besuchenden sichtbar. Zudem können Sie Ihre Produkte in die gewünschte Reihenfolge ziehen, in der sie dargestellt werden sollen.',
      attached: 'Aktive Produkte',
      available: 'Inaktive Produkte',
      button: 'Angaben speichern',
    },
    deleteConfirmation: {
      title: 'Video löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Video löschen',
      description:
        'Wenn Sie das Video löschen möchten, geben Sie bitte „{confirmationText}“ ein und klicken Sie auf „Video löschen“.',
      confirmationText: 'Video löschen',
    },
  },

  editPanelModal: {
    title: 'Beitrag bearbeiten',
    closeButton: 'Abbrechen',
    description: 'Bearbeiten Sie hier bereits vorgenommene Einstellungen.',
    button: 'Angaben speichern',
    generalInformation: {
      title: 'Allgemeine Informationen',
      label: {
        name: 'Name des Beitrags',
        description: 'Beschreibung des Beitrags',
        teaser: 'Teaser des Beitrags',
        at: 'um',
        till: 'bis',
      },
    },

    speaker: {
      title: 'Informationen zu den Sprechenden',
      label: {
        name: 'Namen der Sprechenden',
        description: 'Weitere Informationen zu den Sprechenden',
      },
    },

    language: {
      title: 'Sprache des Beitrags:',
      label: 'Sprache auswählen',
    },
  },

  createPanelModal: {
    title: 'Beitrag erstellen',
    closeButton: 'Abbrechen',
    description: 'Sie erstellen einen neuen Beitrag, indem Sie das Datum und die Uhrzeit festlegen und die Sprechenden des Beitrags angeben.',
    button: 'Beitrag erstellen',
    generalInformation: {
      title: 'Allgemeine Informationen',
      label: {
        name: 'Name des Beitrags',
        description: 'Beschreibung des Beitrags',
        at: 'um',
        till: 'bis',
      },
    },

    speaker: {
      title: 'Informationen zu den Sprechenden',
      label: {
        name: 'Namen der Sprechenden',
        description: 'Weitere Informationen zu den Sprechenden',
      },
    },

    previewImage:{
      title: 'Bildvorschau',
      description: 'Laden Sie ein Vorschaubild für Ihr Produkt hoch. Dieses Bild wird in verschiedenen Bereichen von Virtrex als Vorschau angezeigt.',
      note: 'Für ein optimales Ergebnis, laden Sie die Datei bitte als .jpeg, .HEIC oder .png hoch.',
    },

    language: {
      title: 'Sprache des Beitrags',
      label: 'Sprache auswählen',
    },
  },

  editStageModal: {
    title: 'Programmeinstellungen',
    closeButton: 'Abbrechen',
    description: 'Bearbeiten Sie hier bereits vorgenommene Einstellungen.',
    button: 'Angaben speichern',
    label: {
      name: 'Name des Programms',
    },
    uploadImage: {
      title: 'Bildvorschau',
      description: 'Fügen Sie ein Vorschaubild hinzu, welches den Besuchenden angezeigt wird.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
    },
  },

  stageDetailsPage: {
    title: 'Programmeinstellungen',
    backButton: 'Zurück zum Event',
    agenda: {
      title: 'Programm',
      createPanel: 'Beitrag erstellen',
    },
    actionBar: {
      editButton: 'Programmeinstellungen',
      delete: {
        title: 'Programm löschen',
        button: 'Programm löschen',
      },
    },

    deleteConfirmation: {
      title: 'Programm löschen',
      inputLabel: 'Hier Text eingeben',
      description: 'Wenn Sie das Programm löschen möchten, geben Sie bitte den Namen des Programms Namen ein und klicken Sie auf „Programm löschen“.',
      confirmationText: 'Programm löschen',
      buttonText:'Programm löschen',
    },

  },

  createStageModal: {
    title: 'Programm hinzufügen',
    closeButton: 'Abbrechen',
    description: 'Sie können Ihre Beiträge auf mehrere Programme verteilen, sodass diese parallel stattfinden und thematisch zugeordnet werden können.',
    button: 'Programm hinzufügen',
    label: {
      name: 'Name des Programms',
    },
    uploadImage: {
      title: 'Bildvorschau',
      description: 'Fügen Sie ein Vorschaubild hinzu, welches den Besuchenden angezeigt wird.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
    },
  },

  eventDetailsPage: {
    title: 'Eventplaner',
    backButton: 'Zurück zur Expo',
    imagePreviewTitle: 'Bildvorschau',
    pageCta: {
      title: 'Programm hinzufügen',
      text: 'Jetzt loslegen!',
    },
    actionBar: {
      editButton: 'Event bearbeiten',
      delete: {
        title: 'Event löschen',
        button: 'Event löschen',
      },
    },
    stages: {
      title: 'Programme',
      description: 'Hier finden Sie eine Übersicht Ihrer Programme.',
      cta: 'Programm hinzufügen',
    },

    deleteConfirmation: {
      title: 'Event löschen',
      inputLabel: 'Hier Text eingeben',
      description: 'Wenn Sie das Event löschen möchten, geben Sie bitte „{confirmationText}“ ein und klicken Sie auf „Event löschen“.',
      confirmationText: 'Event löschen',
      buttonText: 'Event löschen',
    },
  },

  expoDemo: {
    message: {
      noLicence: {
        title: 'Keine Lizenz vorhanden',
        description: 'Um eine Expo zu veröffentlichen, müssen Sie eine Lizenz anfragen. Bitte kontaktieren Sie unsere Kundenberater, um ein individuelles Angebot zu erhalten.',
        button: 'Lizenz anfragen',
      },

      noBrandInvite: {
        title: 'Hinweis',
        description: 'Sie haben <b>{inviteCount} von {inviteTotal}</b> verfügbaren Marken zu Ihrer Expo eingeladen. Wenn Sie Ihr Kontingent erweitern möchten, kontaktieren Sie bitte unsere Kundenberater.',
        button: 'Lizenz anfragen',
      },
    },

    tooltip: {
      brandInviteGuests: 'Gäste können noch nicht eingeladen werden. Bitte wenden Sie sich an Ihren Organisator.',
      ownerInviteGuests: 'Sie benötigen eine aktive Lizenz, um Gäste einzuladen.',
      publishExpo: 'Sie benötigen eine aktive Lizenz, um eine Expo zu veröffentlichen.',
    },
  },

  expoLicenceModal: {
    button: 'Mit Ihrer Demoversion fortfahren',

    request: {
      title: 'Informationen zur Lizenz',
      description: 'Um eine Expo zu veröffentlichen und alle Funktionalitäten auf Virtrex zu nutzen, benötigen Sie eine Lizenz. Wenn Sie nicht direkt eine Lizenz anfragen möchten, können Sie gern mit unserer Demoversion starten, um sich umzusehen und mit der Planung Ihrer Expo zu beginnen.',
      buttonPrimaryText: 'Lizenz anfragen',
      buttonSecondaryText: 'Bitte kontaktieren Sie unser Virtrex Team, um eine Lizenz für Ihre Expo anzufragen.',
    },

    success: {
      title: 'Wir haben Ihre Lizenzanfrage erhalten',
      description: 'Vielen Dank für Ihre Anfrage. In Kürze werden wir uns mit Ihnen in Verbindung setzen. Wenn Sie vorab bereits Fragen haben, können Sie sich gerne direkt an unser Virtrex Team wenden: support@virtrex.de.',
    },
  },

  exposPage: {
    title: 'Alle Expos',
    create: 'Expo erstellen',
    delete: 'Expo(s) löschen',
    multipleExposSelected: '{count} Expos ausgewählt',
    deleteConfirmation: {
      title: 'Expo(s) löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Expo(s) löschen',
      description: {
        single:
          'Wenn Sie Ihre Expo <b>{name}</b> löschen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf „Expo(s) löschen“.',
        multiple:
          'Wenn Sie {count} Expos löschen möchten, geben Sie bitte „{text}“ ein und klicken Sie auf „Expo(s) löschen“.',
      },
      validationText: '{count} Expos löschen',
    },
  },

  expoSettingsPage: {
    title: 'Einstellungen',
    goBack: 'Zurück zur Expo',
    visibility: {
      title: 'Exposichtbarkeit',
      description:
        'Entscheiden Sie, ob Ihre Expo für die Öffentlichkeit zugänglich sein soll oder ob Besuchende eingeladen werden müssen, um an der Expo teilnehmen zu können.',
    },
    language: {
      title: 'Sprachen',
      description: 'Bitte wählen Sie die Sprachen, die Sie Ihren Besuchenden auf der Expo anbieten möchten.',
      subtext: 'Unterstützte Sprachen:',
      warning: {
        description: 'Wenn Sie Sprache(n) löschen, werden die entsprechenden Expo Titel und Beschreibungen entfernt. Wenn Sie die Löschung bestätigen, können die Inhalte nicht wieder hergestellt werden.',
      },
    },
    headerImage: {
      title: 'Bildvorschau',
      description:
        'Laden Sie hier ein Titelbild für die Startseite Ihrer Expo hoch.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
    },

    logo: {
      title: 'Logo',
      description:
        'Laden Sie Ihr Markenlogo hoch, um Ihre Markenpräsenz auf der Expo zu verbessern.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 1:1; max. 1MB; .png oder .svg.',
    },

    primaryColor: {
      title: 'Primäre Farbe auswählen',
      description:
        'Bestimmen Sie eine Farbe, in der die Banner sowie die Buttons Ihrer Expo angezeigt werden.',
      note:
        'Für ein optimales Ergebnis, wählen Sie eine Farbe aus welche Besuchende mit Ihrer Marke verbinden.',
    },

    general: {
      title: 'Titel und Beschreibung der Expo',
      expoNameLabel: 'Title der Expo',
      expoDescriptionLabel: 'Beschreibung der Expo',
    },
  },

  createExpoModal: {
    title: 'Expo erstellen',
    closeButton: 'Abbrechen',
    type: {
      title: 'Expotyp',
      navigationTitle: 'Typ',
      label: 'Wählen Sie den Expo Typ aus',
      singleBrand: 'Meine Marke',
      multiBrand: 'Weitere Marken',
    },
    languages: {
      navigationTitle: 'Sprachen',
    },
    titleAndDescription: {
      title: 'Titel und Beschreibung der Expo',
      navigationTitle: 'Titel und Beschreibung der Expo',
      description: 'Der Titel und die Beschreibung werden auf der Expo sowie in den Einladungen verwendet.',
      nameLabel: 'Titel der Expo',
      descriptionLabel: 'Beschreiben Sie Ihre Expo',
    },
    defaultLanguage: {
      description: 'Diese Sprache wird automatisch verwendet, wenn Besuchende keine Sprache auswählen.',
      label: 'Bitte wählen Sie eine Standardsprache für Ihre Expo',
      title: 'Standardsprache',
    },
    visibility: {
      title: 'Exposichtbarkeit',
      description:
        'Entscheiden Sie, ob Ihre Expo öffentlich zugänglich sein soll oder ob Besuchende eingeladen werden müssen, um an der Expo teilnehmen zu können.',
    },
    error: {
      noLanguageSelected: 'Bitte wählen Sie eine Sprache, um fortzufahren',
      missingRequiredData: 'Bitte geben Sie für jede Sprache Ihrer Expo einen Titel und eine Beschreibung an',
    },
    nextButton: 'Weiter',
    submitButton: 'Jetzt erstellen',
  },

  expoIsPublicOptionList: {
    public: 'Öffentliche Expo, die mit einem Link frei zugänglich ist.',
    private: 'Private Expo, die nur mit einer personalisierten Einladung zugänglich ist.',
  },

  associatesPage: {
    title: 'Teammitglieder hinzufügen',
    inviteButton: 'Teammitglieder hinzufügen',
    multipleElementsSelected: '{count} ausgewählte Teammitglieder',
    delete: 'Teammitglied(er) löschen',
    invites: {
      title: 'Ausstehende Einladungen',
      pendingLabel: 'Einladung steht aus',
    },
    deleteConfirmation: {
      title: 'Teammitglied(er) löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Teammitglied(er) löschen',
      description: {
        single:
          'Wenn Sie Ihr Teammitglied <b>{name}</b> entfernen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf „Teammitglied(er) löschen“.',
        multiple:
          'Wenn Sie {count} Teammitglieder entfernen möchten, geben Sie bitte „{text}“ ein und klicken Sie auf „Teammitglied(er) löschen“.',
      },
      validationText: '{count} Teammitglieder löschen',
    },
  },

  confirmationModal: {
    closeButton: 'Abbrechen',
    goBackText: 'Zurück',
  },

  inviteAssociateModal: {
    title: 'Teammitglieder hinzufügen',
    closeButton: 'Abbrechen',
    description: 'Laden Sie hier Teammitglieder ein, die Sie dann bei Ihren Expos unterstützen können. Geben Sie dazu die E-Mail-Adressen Ihrer Teammitglieder ein und bestätigen Sie jede Ihrer Eingaben mit der Enter-Taste, um weitere E-Mail-Adressen hinzufügen zu können.',
    button: 'Teammitglieder hinzufügen',
    generalInformation: {
      title: 'Allgemeine Informationen',
      label: {
        email: 'Hier E-Mail-Adressen eingeben',
      },
    },
  },

  brandSwitcher: {
    button: 'Account betreten',
    title: 'Willkommen in Ihrem Virtrex.',
    description:
      'Verwalten Sie hier Ihr Markenprofil, Produktinformationen sowie Kontaktmöglichkeiten.',
  },

  userProfilePage: {
    title: 'Profildetails',
    logout: 'Ausloggen',
    edit: 'Profil bearbeiten',
  },

  associatePage: {
    title: 'Profil des Mitarbeitenden',
    backButton: 'Zurück zur Übersicht',
    edit: 'Profil bearbeiten',
    permissionManagement: 'Berechtigungsmanagement',
    permissionCallout: {
      text: 'Um die spezifischen Berechtigungen einer Rolle zu verwalten, gehen Sie bitte zu den Einstellungen oder klicken hier auf',
      link: 'Berechtigungen bearbeiten',
    },
  },

  editAssociateModal: {
    title: 'Profil bearbeiten',
    closeButton: 'Abbrechen',
    description:
      'Bitte vervollständigen Sie Ihr Profil. Die hier eingetragenen Informationen werden den Besuchenden der Expo angezeigt.',
    generalInformation: {
      title: 'Allgemeine Informationen',
      label: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        position: 'Position',
        email: 'E-Mail-Adresse',
        preferredLocale: 'Bitte wählen Sie Ihre präferierte Sprache.',
      },
    },
    avatar: {
      title: 'Profilbild',
      description:
        'Fügen Sie ein Profilbild hinzu. Dieses wird den Besuchenden der Expo angezeigt.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 1:1; max. 1MB; .jpeg, .png oder .heic.',
    },
    button: 'Angaben speichern',
  },

  userDetailsModal: {
    title: 'Profil bearbeiten',
    closeButton: 'Abbrechen',
    description:
      'Bitte vervollständigen Sie Ihr Profil. Die hier eingetragenen Informationen werden den Besuchenden der Expo angezeigt.',
    generalInformation: {
      title: 'Allgemeine Informationen',
      label: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        position: 'Stellenbezeichnung',
        email: 'E-Mail-Adresse',
        preferredLocale: 'Bitte wählen Sie Ihre präferierte Sprache.',
      },
    },
    avatar: {
      title: 'Profilbild',
      description:
        'Fügen Sie ein Profilbild hinzu. Dieses wird den Besuchenden der Expo angezeigt.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 1:1; max. 1MB; .jpeg, .png oder .heic.',
    },
    password: {
      button: 'Passwort ändern',
      label: 'Geben Sie Ihr neues Passwort ein',
    },
    button: 'Angaben speichern',
  },

  contactsPage: {
    title: 'Alle Kontakte',
    createButton: 'Kontakte hinzufügen',
    delete: 'Kontakt(e) löschen',
    multipleContactsSelected: '{count} ausgewählte Kontakte',

    deleteConfirmation: {
      title: 'Kontakt(e) löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Kontakt(e) löschen',
      description: {
        single:
          'Wenn Sie Ihren Kontakt <b>{name}</b> löschen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf „Kontakt(e) löschen“.',
        multiple:
          'Wenn Sie Ihre {count} Kontakte löschen möchten, geben Sie bitte „{text}“ ein und klicken Sie auf „Kontakt(e) löschen“.',
      },
      validationText: '{count} Kontakte löschen',
    },
  },

  createEventModal: {
    title: 'Event hinzufügen',
    closeButton: 'Abbrechen',
    description:
      'Erstellen Sie hier ein Event. Die eingetragenen Informationen sind für die Besuchenden einsehbar.',
    label: {
      name: 'Name des Events',
      description: 'Beschreibung des Events',
      startDate: 'Wählen Sie das Startdatum',
      endDate: 'Wählen Sie das Enddatum',
    },
    button: 'Angaben speichern',

    uploadImage: {
      title: 'Bildvorschau',
      description:
        'Fügen Sie ein Bild hinzu, welches den Besuchenden als Vorschau zu Ihrem Event angezeigt wird.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
    },
  },
  createPackageModal: {
    title: 'Lizenzpaket',
    buttonClose: 'Abbrechen',
    description: 'Sollten Sie eine Limitierungsmöglichkeit auslassen, wird dieser automatisch keine Grenze gesetzt. Es steht Ihnen frei, wie viele Pakete Sie erstellen.',
    information: 'Allgemeine Informationen',
    placeholderName: 'Name des Pakets',
    placeholderPrice: 'Preis',
    placeholderSelectSize: 'Größe der Anzeigekachel wählen',
    selectTitle: 'Größe der Anzeigekachel',
    S: 'Klein',
    M: 'Mittel',
    L: 'Groß',
    selectSizeNote: 'Bestimmt, wie groß und prominent die Logo-Kachel der zugewiesenen Marken angezeigt wird.',
    limitation: 'Limitierung von Features',
    placeholderMaxProducts: 'Maximale Anzahl an Produkten',
    placeholderMaxShowrooms: 'Maximale Anzahl an Showrooms',
    placeholderMaxVip: 'Maximale Anzahl an Einladungen für Besuchende',
    buttonSubmit: 'Angaben speichern',
  },
  editPackageModal: {
    title: 'Lizenzpaket editieren',
    buttonClose: 'Abbrechen',
    description: 'Sollten Sie eine Limitierungsmöglichkeit auslassen, wird dieser automatisch keine Grenze gesetzt. Es steht Ihnen frei, wie viele Pakete Sie erstellen.',
    information: 'Allgemeine Informationen',
    placeholderName: 'Name des Pakets',
    placeholderPrice: 'Preis',
    placeholderSelectSize: 'Größe der Anzeigekachel wählen',
    selectTitle: 'Größe der Anzeigekachel',
    S: 'Klein',
    M: 'Mittel',
    L: 'Groß',
    selectSizeNote: 'Bestimmt, wie groß und prominent die Logo-Kachel der zugewiesenen Marken angezeigt wird.',
    limitation: 'Limitierung von Features',
    placeholderMaxProducts: 'Maximale Anzahl an Produkten',
    placeholderMaxShowrooms: 'Maximale Anzahl an Showrooms',
    placeholderMaxVip: 'Maximale Anzahl an Einladungen für Besuchende',
    buttonSubmit: 'Angaben speichern',
  },
  deletePackageModal: {
    title: 'Lizenzpaket löschen',
    inputLabel: 'Hier Text eingeben',
    description: 'Wenn sie dieses Lizenzpaket löschen wollen, geben Sie bitte: „{confirmationText}“ ein und klicken Sie anschließend auf „Lizenzpaket löschen“.',
    buttonText: 'Lizenzpaket löschen',
  },
  selectBrandPackage: {
    placeholder: 'Paket auswählen',
  },
  editTagModal: {
    title: {
      add: 'Kategorie hinzufügen',
      edit: 'Kategorie bearbeiten',
    },
    closeButton: 'Schließen',
    description: 'Als Organisator können Sie eine unbegrenzte Anzahl an Themenfiltern für eine Expo erstellen. Die Themenfilter dienen Ihnen, den teilnehmenden Marken und den Besuchenden dazu, Marken und Produkte der Expo unabhängig voneinander zu kategorisieren.',
    cardTitle: 'Expokategorien',
    cardDescription: 'Bitte bedenken Sie, die Kategorien in der gewünschten Reihenfolge einzutragen, da sich diese im Nachgang nicht mehr bearbeiten lässt. Sobald sich eine Marke einer Kategorie zugeordnet hat, können Sie die Kategorie nicht mehr löschen.',
    cardNote: 'Die Eingabe erfolgt durch Trennen der einzelnen Kategorien mit einem Komma oder durch Drücken der Eingabetaste.',
    placeholder: 'Kategorie Titel',
    button: 'Speichern',
  },
  selectBrandTagModal: {
    title: 'Kategorien verwalten',
    closeButton: 'Abbrechen',
    description: 'Bitte wählen Sie die Kategorien aus, unter welchen Besuchende Sie auffinden sollen. Sie können Ihrer Marke bis zu vier Kategorien zuordnen.',
    cardTitle: 'Markenkategorien',
    placeholder: 'Kategorien verwalten',
    button: 'Angaben speichern',
  },
  selectProductTagModal: {
    placeholder: 'Tag hinzufügen',
  },
  editEventModal: {
    title: 'Event bearbeiten',
    closeButton: 'Abbrechen',
    description:
      'Erstellen Sie hier ein Event. Die eingetragenen Informationen sind für die Besuchenden einsehbar.',
    label: {
      name: 'Name des Events',
      description: 'Beschreibung des Events',
      startDate: 'Wählen Sie das Startdatum',
      endDate: 'Wählen Sie das Enddatum',
    },
    button: 'Angaben speichern',

    uploadImage: {
      title: 'Bildvorschau',
      description:
        'Fügen Sie ein Bild hinzu, welches den Besuchenden als Vorschau zu Ihrem Event angezeigt wird.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
    },
  },

  contactCreationModal: {
    modeSelection: {
      title: 'Kontakte hinzufügen',
      closeButton: 'Abbrechen',
      legalConfirmation: 'Hiermit bestätige ich, dass alle verwendeten Kontaktdaten rechtmäßig erworben wurden.',
      description:
        'Fügen Sie bestehende Kontakte zu Virtrex hinzu, um diese zu Ihren Expos einzuladen. Die Daten der Besuchenden liefern Ihnen zusätzliche Statistiken, die Sie zur Verbesserung Ihrer Verkaufsstrategie nutzen können. Wir bieten Ihnen mehrere Möglichkeiten, diese Kontaktdaten hochzuladen.',
      options: {
        import: {
          title: 'Kontakte per Excel-Datei hinzufügen',
          description: 'Fügen Sie Ihre Kontakte durch Importieren einer Excel-Datei hinzu.',
        },
        creation: {
          title: 'Kontakte manuell hinzufügen',
          description: 'Fügen Sie Ihre Kontakte einzeln hinzu.',
        },
      },
    },
    upload: {
      title: 'Kontakte per Excel-Datei hinzufügen',
      closeButton: 'Abbrechen',
      goBack: 'Zurück',
      description:
        'Bitte folgen Sie der in der Excel-Datei vorgegebenen Struktur für einen erfolgreichen und korrekten Upload Ihrer Kontakte. Bitte verwenden Sie die unten bereitgestellte Excel-Datei.',
      csv: {
        title: 'Excel-Datei importieren',
        description:
          'Bitte folgen Sie den Upload-Anweisungen und verwenden Sie die bereitgestellte Muster-Datei.',
        note: 'Klicken Sie hier, um ein Muster der Excel-Datei herunterzuladen. Füllen Sie die Excel-Datei aus und laden Sie sie hoch, um Ihre Kontakte erfolgreich hinzuzufügen:',
        link: 'Muster herunterladen',
      },
      button: 'Kontakte importieren',
    },

    create: {
      title: 'Kontakte manuell hinzufügen',
      closeButton: 'Abbrechen',
      goBack: 'Zurück',
      description:
        'Bearbeiten Sie die Kontaktinformationen, um Ihre Datenbank zu vervollständigen. Achten Sie dabei auf die Genauigkeit der Angaben, um diese für weitere Analysezwecke zu verwenden.',
      button: 'Kontakt hinzufügen',
      label: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail-Adresse des Kontakts',
        company: 'Firmenname des Kontakts (optional)',
        associate: 'Verantwortlicher Sales Manager (optional)',
        other: 'Sonstiges (optional)',
      },
    },
  },

  contactDetailsPage: {
    title: 'Kontaktdetails',
    associateTitle: 'Verantwortlicher Sales Manager',
    backButton: 'Zurück zur Übersicht',
    editButton: 'Kontakt bearbeiten',
  },

  leadDetailPage: {
    title: 'Profildetails',
    logout: 'Ausloggen',
    statusOnline: 'Online',
    statusOffline: 'Offline',
    contactState: 'Aktueller Kontaktstatus',
    exhibitionFolder: 'Messemappe',
    emptyMessemappeList: 'Keine Produkte gefunden',
    contactHistory: 'Kontaktverlauf',
    currentlyVisit: {
      currentlyViewing: 'Betrachtet gerade',
      product: 'Produkt',
      brands: 'Marke',
      showroom: 'Showroom',
      panel: 'Beitrag',
      events: 'Veranstaltung',
      title: '{type} seite: {name}',
      openedAt: 'Geöffnet um:',
      duration: 'Dauer:',
    },
    visitHistory: {
      title: 'Besuchshistorie',
      emptyDataText: 'Es wurde keine Besuchshistorie gefunden',
      duration: 'Dauer',
      filters: 'Filter',
      type: {
        product: 'Produkt',
        brand: 'Marke',
        showroom: 'Showroom',
        panel: 'Panel',
      },
      filterNames: {
        product: 'Produktseiten',
        showroom: 'Showrooms',
        brand: 'Markenseite',
      },
      viewFullVisitList: 'Komplette Besuchshistorie anzeigen',
      modal: {
        title: 'Besuchshistorie',
      },
    },
    leadInfo: {
      expo: 'Messe',
      company: 'Unternehmen',
      role: 'Position',
      mail: 'E-Mail',
      phone: 'Telefon',
      mobilePhone: 'Mobiltelefon',
    },
    actionBar: {
      createCall: 'Neuen Anruf erstellen',
    },
    interestList: {
      title: 'Interessen',
      emptyDataText: 'Es wurden bisher noch keine Interessen hinterlegt.',
    },
  },

  editContactModal: {
    title: 'Kontakt bearbeiten',
    closeButton: 'Abbrechen',
    goBack: 'Zurück',
    description:
      'Bearbeiten Sie die Angaben, um eine vollständige Datenbank mit potenziellen Kontakten zu haben. Bitte geben Sie diese Informationen so genau wie möglich an, da die Daten für Analysezwecke verwendet werden können.',
    button: 'Angaben speichern',
    label: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail-Adresse des Kontakts',
      company: 'Firmenname des Kontakts (optional)',
      associate: 'Verantwortlicher Sales Manager (optional)',
      other: 'Sonstige Bemerkungen (optional)',
    },
  },

  expoDetailsPage: {
    title: 'Expodetails',
    languages: 'Sprachen',

    visitors: {
      title: '{count} eingeladene Gäste',
      text: 'Gäste verwalten',
      disabled: 'Hinweis',

      modal: {
        manage: {
          title: 'Gäste verwalten',
          close: 'Abbrechen',
          description:
            'Wählen Sie die Kontakte aus, die Sie zu Ihrer Expo einladen möchten und klicken Sie auf „Angaben speichern“. Anschließend erhalten Ihre ausgewählten Kontakte eine Einladung per E-Mail. Um Ihre Kontakte einzuladen, fügen Sie sie bitte zunächst in Ihrem {link} hinzu.',
          descriptionLink: 'Adressbuch',
          attached: 'Eingeladene Gäste',
          available: 'Verfügbare Kontakte',
          buttonInvite: 'Kontakte einladen',
          buttonSave: 'Angaben speichern',
          buttonSchedule: 'Versand der Einladung terminieren',
        },
        scheduling: {
          title: 'Einladungsversand terminieren',
          sendNow: 'Einladungen direkt versenden',
          sendLater: 'Einladungsversand terminieren',
          sendWhenScheduled: 'Einladungen zum ausgewählten Termin versenden',
        },
        detachConfirmation: {
          title: 'Kontakt löschen',
          buttonText: 'Kontakt löschen',
          description: 'Möchten Sie den Kontakt <b>{name}</b> löschen?',
        },
      },
    },

    preview: {
      title: 'Webseitenteaser für Ihre Expo:',
      description: {
        empty: 'Schalten Sie eine Webseite, die vor dem Start Ihrer Expo für Interessenten erreichbar ist. Dort finden diese einen Countdown und können sich für eine Benachrichtigung zum Start der Expo anmelden.',
        filled: 'Sie haben die Benachrichtigung zum Start Ihrer Expo aktiviert. Somit werden alle registrierten Besuchenden zum Start der Expo benachrichtigt.',
      },
      startTime: {
        title: 'Startdaten der Expo',
        button: {
          empty: 'Daten hinzufügen',
          filled: 'Startdaten ändern',
        },
        description: 'Bitte fügen Sie das Startdatum und die Uhrzeit Ihrer Expo hinzu, damit Besuchenden rechtzeitig eine Benachrichtigung erhalten.',
        at: 'um',
      },
      numberOfVisitors: {
        title: 'Anzahl der Besuchenden, die sich für die Benachrichtigung angemeldet haben',
        count: '{count} Gast | {count} Gäste',
      },
      modal: {
        title: 'Startdaten der Expo',
        close: 'Abbrechen',
        description: 'Legen Sie zum Einrichten des Countdowns das Startdatum und die Startzeit Ihrer Expo fest. Zu diesem Zeitpunkt wird eine E-Mail an alle angemeldeten Besuchende versendet, um diese über den Beginn der Expo zu benachrichtigen.',
        datepicker: {
          label: 'Datum auswählen',
        },
        at: 'um',
        saveButton: 'Angaben speichern',
        warning: {
          title: 'Hinweis',
          description: 'Sind Sie sicher, dass Sie den Versand der Benachrichtigungen verschieben möchten? Es haben sich bereits {visitorCount} für die Benachrichtigung registriert. Wenn Sie das Startdatum ändern, werden die Benachrichtigungen entsprechend verschoben.',
          count: '{count} Besuchender | {count} Besuchende',
        },
        reschedule: {
          title: 'Startdaten anpassen',
          button: 'Startdaten löschen',
        },
      },
    },

    brands: {
      title: 'Marken verwalten',
      cta: {
        invite: 'Marken hinzufügen',
        manage: 'Marken verwalten',
      },
      defaultText: {
        empty: 'Sie haben noch keine Marken hinzugefügt.',
        filled:
          'In diesem Abschnitt können Sie die Marken verwalten, die bei Ihrer Expo mitwirken werden. Sollten Sie für Ihre Expo Lizenzpakete erstellt haben, können Sie diese hier den teilnehmenden Marken zuweisen.',
      },
      modal: {
        manage: {
          title: 'Marken verwalten',
          close: 'Abbrechen',
          description:
            'Verwalten Sie hier die teilnehmenden Marken Ihrer Expo. Sie können neue Marken zu Ihrer Expo hinzufügen, indem Sie auf „Neue Marke einladen“ klicken. Bis eine Marke Ihre Einladung annimmt, wird der Status „Einladung ausstehend“ angezeigt.',
          brandsTitle: 'Teilnehmende Marken',
          button: 'Neue Marke einladen',
          pendingInvitesTitle: 'Ausstehende Einladung(en)',
        },
        detachConfirmation: {
          title: 'Marken löschen',
          inputLabel: 'Hier Text eingeben',
          buttonText: 'Marke löschen',
          description:
            'Wenn Sie die Marke <b>{name}</b> löschen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf „Marke löschen“.',
        },
        cancelInviteConfirmation: {
          title: 'Einladung zurückziehen',
          inputLabel: 'Bitte fügen Sie einen Text ein',
          buttonText: 'Einladung abbrechen',
          description:
            'Wenn Sie die Einladung von <b>{name}</b> zurückziehen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf abbrechen.', //!need translation
        },
        invite: {
          title: 'Marken einladen',
          closeButton: 'Abbrechen',
          description:
            'Laden Sie eine Marke ein, indem Sie eine Kontaktperson der Marke hinzufügen. Sobald Sie die Einladung abgeschickt haben, erhält die Kontaktperson eine Einladung zur Expo, die sie annehmen muss.', //! need to check
          generalInformation: {
            title: 'Allgemeine Informationen',
            label: {
              email: 'E-Mail-Adresse eingeben',
              brandName: 'Firmenname',
            },
          },
          button: 'Marken einladen',
        },
      },
    },

    email: {
      title: 'Einladung zur Expo',
      description: 'Wenn Sie den Versand der Einladungen zu dieser Expo auf einen Tag terminieren möchten, können Sie unter „Datum auswählen” ein Datum für den Versand der E-Mail bestimmen. Wenn Sie keine Einstellung vornehmen, werden die Einladungen direkt nach der Auswahl der Kontakte versandt.',
      expoStarted: 'Da Ihre Expo {name} bereits begonnen hat, können Sie Einladungen nicht mehr terminieren. Um weitere Gäste einzuladen, klicken Sie bitte direkt auch den Button „Gäste einladen“. Ihre Einladungen werden dann im Anschluss direkt versandt.',
      scheduling: {
        title: 'Versand der Einladung',
        description: 'Bitte terminieren Sie ein Datum für den Versand der Einladungen per E-Mail an Ihre Gäste.',
        confirmation: 'Ihre Einladungen sind für den Versand per E-Mail am <b>{date}</b> terminiert.',
        buttonUnscheduled: 'Datum auswählen',
        buttonScheduled: 'Datum ändern',
        modal: {
          title: 'Einladungsversand terminieren',
          subtitle: 'Datum anpassen',
          description: 'Hier können Sie den Versand Ihrer Einladungen terminieren. Die Einladungen werden am ausgewählten Tag an Ihre Gäste per E-Mail versandt.',
          invites: '<b>{count} Gäste werden eingeladen</b>',
          selectDate: 'Datum auswählen',
          cancelButton: 'Einladung stornieren',
          cancelTitle: 'Einladungen stornieren',
          cancelText: 'Wenn Sie den Versand der Einladungen aussetzen, werden Ihre Gäste nicht per E-Mail eingeladen.',
          cancelSubmit: 'Löschung bestätigen',
        },
      },
      branding: {
        title: 'Branding der Einladungen',
        description: 'In Ihren Markeneinstellungen können Sie eine primäre Farbe auswählen und Ihr Logo für die Einladung hochladen. Bitte beachten Sie, diese Einstellungen betreffen Ihren gesamten Markenauftritt.',
        button: 'Markeneinstellungen',
      },
    },

    events: {
      title: 'Events',
      cta: 'Event hinzufügen',
      text: 'In der Veranstaltungssektion können Sie Ihre Veranstaltung erstellen und verwalten. Erstellen Sie ein Ereignis, indem Sie auf die Schaltfläche „Event hinzufügen“ klicken. Sie können das Ereignis weiter bearbeiten, indem Sie darauf klicken.',
      defaultText: 'Übersicht Ihrer Events:',
    },

    packages: {
      title: 'Lizenzpakete:',
      cta: 'Paket erstellen',
      defaultText: 'Wenn Sie möchten, können Sie Lizenzpakete für diese Expo erstellen und diese Ihren teilnehmenden Marken zuweisen. Wenn Sie keine Lizenzpakete erstellen möchten, ist jede Marke unlimitiert, d.h. die Marke kann beliebig viele Produkte, Besuchende etc. zur Expo hinzufügen.',
      settingEdit: 'Paket bearbeiten',
      settingDelete: 'Paket löschen',
    },

    categories: {

      select: {
        title: 'Markenkategorien',
        cta: 'Kategorien verwalten',
        text: 'Hier finden Sie alle Kategorien, welche Sie zur Auffindbarkeit Ihre Marke ausgewählt haben. Möchten Sie eine Kategorie hinzufügen oder entfernen, klicken Sie bitte auf „Kategorien verwalten“.',
      },
      manage: {
        title: 'Expokategorien',
        cta: 'Kategorien verwalten',
        text: 'Hier finden Sie alle angelegten Kategorien Ihrer Expo. Möchten Sie eine Kategorie hinzufügen, bearbeiten oder entfernen, klicken Sie bitte auf „Kategorien verwalten“.',
        actions: {
          edit: 'Kategorie bearbeiten',
          delete: 'Kategorie löschen',
        },
      },
    },

    products: {
      title: 'Produkte',
      cta: 'Produkte verwalten',
      defaultText: 'Ziehen Sie die Produkte in die gewünschte Reihenfolge, in der sie dargestellt werden sollen. Klicken Sie auf „Produkte verwalten“, um weitere Produkte auszuwählen, Produkte zu löschen oder Kategorie-Tags zu vergeben.',
      modal: {
        manage: {
          title: 'Produkte verwalten',
          close: 'Abbrechen',
          description:
            'In diesem Abschnitt fügen Sie Produkte zu Ihrer Expo hinzu, diese Produkte werden automatisch in den Veranstaltungen, an denen Sie teilnehmen, und auf Ihrem Markenprofil angezeigt. Über den kleinen Pfeil können Sie für jedes Produkt Kategorie-Tags auswählen, damit Besuchende Ihre Produkte besser finden können.',
          attached: 'Aktive Produkte',
          available: 'Inaktive Produkte',
          button: 'Angaben speichern',
        },
        detachConfirmation: {
          title: 'Produkt entfernen',
          buttonText: 'Produkt entfernen',
          description: 'Das Produkt <b>{name}</b> wirklich entfernen?',
        },
      },
      tagsSelected: 'Tags ausgewählt',
      noTagsSelected: 'Keine Tags ausgewählt',
    },

    showrooms: {
      title: 'Showrooms',
      cta: 'Showrooms verwalten',
      defaultText: 'Ziehen Sie die Showrooms in die gewünschte Reihenfolge, in der sie dargestellt werden sollen.',
      modal: {
        manage: {
          title: 'Showrooms verwalten',
          close: 'Abbrechen',
          description:
            'Wählen Sie die Showrooms aus, die Sie zu Ihrer Expo hinzufügen oder aus dieser löschen möchten.',
          attached: 'Hinzugefügte Showrooms',
          available: 'Bestehende Showrooms',
          button: 'Angaben speichern',
        },
        detachConfirmation: {
          title: 'Showroom löschen',
          buttonText: 'Showroom löschen',
          description: 'Den Showroom <b>{name}</b> wirklich löschen?',
        },
      },
    },

    associates: {
      title: 'Team',
      cta: 'Teammitglieder verwalten',
      defaultText:
        'Fügen Sie hier bereits angelegte Mitarbeitende hinzu, die an der Expo teilnehmen werden. Die hinterlegten Mitarbeitenden erscheinen auf Ihrem Markenprofil und Ihren Produktseiten, wo sie von Besuchenden kontaktiert werden können, insofern sie auch über die entsprechenden Berechtigungen, um den Sales-Bereich zu sehen, verfügen.',
      modal: {
        manage: {
          title: 'Teammitglieder verwalten',
          close: 'Abbrechen',
          description: 'Wählen Sie aus Ihrem Team die Ansprechpartner für diese Expo aus.',
          attached: 'Hinzugefügte Teammitglieder',
          available: 'Verfügbare Teammitglieder',
          empty: 'Sie haben noch kein Team hinzugefügt.',
          button: 'Angaben speichern',
        },
        detachConfirmation: {
          title: 'Teammitglied löschen',
          buttonText: 'Teammitglied löschen',
          description: 'Ihr Teammitglied <b>{name}</b> wirklich löschen?',
        },
      },
    },

    apps: {
      title: 'Applikationen',
      cta: 'Applikationen verwalten',
      defaultText: 'Hier können Sie Besucher Apps für Ihre Expo auswählen und hinzufügen.',
      modal: {
        manage: {
          title: 'Applikationen verwalten',
          close: 'Abbrechen',
          description: 'Wählen Sie hier die Besucher Apps aus, die Sie Ihren Gästen auf Ihre Expo zur Verfügung stellen möchten.',
          attached: 'Aktive Besucher Apps:',
          available: 'Verfügbare Besucher Apps:',
          button: 'Angaben speichern',
        },
        detachConfirmation: {
          title: 'Applikationen löschen',
          description: 'Ihr Applikationen <b>{title}</b> wirklich löschen?',
          buttonText: 'Applikationen löschen',
        },
      },
    },

    actionBar: {
      cta: 'Expovorschau öffnen',
      actions: {
        title: 'Expo verwalten',
        brands: 'Marken verwalten',
        products: 'Produkte verwalten',
        events: 'Event hinzufügen',
        showrooms: 'Showrooms verwalten',
        associates: 'Teammitglieder verwalten',
        settings: 'Expoeinstellungen',
        apps: 'Expo Applikationen',
        activate: 'Expo veröffentlicht',
        deactivate: 'Expo nicht veröffentlicht',
        licence: 'Lizenz anfragen',
      },
    },
  },
  auth: {
    login: {
      title: 'Login',
      description:
        'Verwenden Sie Ihre Anmeldedaten zum Einloggen. Sollten Sie Ihr Passwort vergessen haben, klicken Sie bitte auf „Passwort vergessen“. Sie erhalten in Kürze eine E-Mail zur Passwortänderung.',
      email: {
        label: 'Ihre E-Mail-Adresse',
        placeholder: 'Ihre E-Mail-Adresse',
      },
      password: {
        label: 'Ihr Passwort',
        placeholder: 'Ihr Passwort',
      },
      resetPassButton: 'Passwort vergessen',
      submitButton: 'Jetzt einloggen',
    },

    welcome: {
      title: 'Willkommen!',
      description:
        'Um Virtrex nutzen zu können, müssen Sie einen Account erstellen. Klicken Sie auf die Schaltfläche „Lassen Sie uns loslegen!“, um sich zu registrieren.',
      link: 'Lassen Sie uns loslegen!',
    },

    registration: {
      title: 'Accounterstellung',

      firstName: {
        label: 'Ihr Vorname',
        placeholder: 'Ihr Vorname',
      },
      lastName: {
        label: 'Ihr Nachname',
        placeholder: 'Ihr Nachname',
      },

      description: 'Füllen Sie alle folgenden Felder aus, um Ihren Account zu registrieren.',
      email: {
        label: 'Ihre E-Mail-Adresse',
        placeholder: 'Ihre E-Mail-Adresse',
      },
      position: {
        label: 'Position angeben',
        placeholder: 'Position angeben',
      },
      preferredLocale: 'Bitte wählen Sie Ihre präferierte Sprache.',
      password: {
        label: 'Ihr Passwort',
        placeholder: 'Ihr Passwort (Mindestens 8 Buchstaben)',
      },
      passwordConfirmation: {
        label: 'Wiederholen Sie Ihr Passwort',
        placeholder: 'Wiederholen Sie Ihr Passwort',
      },
      accept: {
        text: 'Ich habe die {0} gelesen und akzeptiert.',
        linkText: 'Datenschutzerklärung',
      },
      submitButton: 'Account erstellen',

      actionBarLink: 'Ich habe bereits einen Account',
    },

    forgot: {
      title: 'Neues Passwort beantragen',
      description:
        'Wenn Sie Ihr Passwort vergessen haben, senden wir Ihnen gerne einen Link zum Zurücksetzen des aktuellen Passworts zu. Bitte geben Sie dazu die E-Mail-Adresse an, mit der Sie sich bei Virtrex registriert haben und klicken Sie auf „Passwort zurücksetzen“.',
      email: 'Ihre E-Mail-Adresse',
      submitButton: 'Passwort zurücksetzen',

      titleConfirm: 'Wir haben Ihnen eine E-mail geschickt!',
      descriptionConfirm:
        'Sofern ein Account unter der angegebenen E-Mail-Adresse besteht, finden Sie den Link zum zurücksetzen Ihres Passworts in Ihrem Posteingang. Vergewissern Sie sich auch, ob die Mail im Spam Ordner gelandet sein könnte.',
      actionConfirm: 'Zurück zur Startseite',
    },

    reset: {
      title: 'Passwort zurücksetzen',
      description:
        'Wenn Sie Ihr Passwort vergessen haben, senden wir Ihnen gern einen Link zum Zurücksetzen des aktuellen Passworts zu. Bitte geben Sie dazu die E-Mail-Adresse an, mit der Sie sich bei Virtrex registriert haben und klicken Sie auf „Passwort zurücksetzen“.',
      password: 'Neues Passwort (Mindestens 8 Zeichen)',
      passwordConfirmation: 'Passwort wiederholen',
      submitBtn: 'Passwort zurücksetzen',

      titleConfirm: 'Ihr Passwort wurde erfolgreich zurückgesetzt',
      descriptionConfirm:
        'Ihr Passwort wurde erfolgreich zurückgesetzt, Sie können nun zum Login zurückkehren und sich wie gewohnt einloggen',
      actionConfirm: 'Zurück zum Login',

      actionBarLink: 'Ich habe mein Passwort gefunden',
    },
  },
  showroomsPage: {
    title: 'Alle Showrooms',
    edit: 'Team verwalten',
    createButton: 'Showroom hinzufügen',
    delete: 'Showroom(s) löschen',

    multipleShowroomSelected: '{count} ausgewählte Showrooms',

    deleteConfirmation: {
      title: 'Showroom(s) löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Showroom(s) löschen',
      description: {
        single:
          'Wenn Sie Ihren Showroom <b>{name}</b> löschen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf „Showroom(s) löschen“. Bitte beachten Sie, dass gelöschte Showrooms nicht wiederhergestellt werden können.',
        multiple:
          'Wenn Sie Ihre {count} Showrooms löschen möchten, geben Sie bitte <b>„{text}“</b>  ein und klicken Sie auf „Showroom(s) löschen“. Bitte beachten Sie, dass gelöschte Showrooms nicht wiederhergestellt werden können.',
      },
      validationText: '{count} Showroom(s) löschen',
    },

    showroomCreationModal: {
      modeSelection: {
        title: 'Showroom hinzufügen',
        closeButton: 'Abbrechen',
        description:
          'Hier können Sie einen 3D-Showroom zur Präsentation Ihrer 3D-Produkte erstellen.',
        list: {
          description: 'Wählen Sie ein Template aus',
          button: 'Template auswählen',
        },
      },
      create: {
        title: 'Showroom hinzufügen',
        closeButton: 'Abbrechen',
        goBack: 'Zurück',
        description:
          'Füllen Sie die folgenden Felder aus, um einen 3D-Showroom zu erstellen. Nachdem Sie Ihren Showroom erfolgreich erstellt haben, können Sie ihn zu Ihrer Expo hinzufügen.',
        button: 'Angaben speichern',

        generalInformation: {
          title: 'Allgemeine Informationen',
          name: 'Name des Showrooms',
          description: 'Beschreibung des Showrooms',
        },

        previewImage: {
          title: 'Bildvorschau',
          description:
            'Laden Sie hier ein Vorschaubild für Ihren Showroom hoch.',
          note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
        },

        previewBrandLogotype: {
          title: 'Showroom Branding (optional)',
          description:
            'Sie können ein Bild mit einem 2:1 Verhältnis (z.B. 2000x1000px) hochladen, das im Showroom platziert wird. Sie können einen transparenten Hintergrund nutzen, um andere Formen oder Verhältnisse zu erzielen.',
          note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 2:1; max. 1MB; .jpeg, .png oder .heic.',
        },
      },
    },

    editShowroomModal: {
      title: 'Showroom bearbeiten',
      closeButton: 'Abbrechen',
      goBack: 'Zurück',
      description:
        'Hier haben Sie die Möglichkeit, Ihren 3D-Showroom zu bearbeiten.',
      button: 'Angaben speichern',
      generalInformation: {
        title: 'Allgemeine Informationen:',
        name: 'Name des Showrooms',
        description: 'Beschreibung des Showrooms',
      },

      previewImage: {
        title: 'Bildvorschau',
        description:
          'Laden Sie hier ein Vorschaubild für Ihren Showroom hoch.',
        note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
      },
    },
  },

  showroomDetailsPage: {
    title: 'Ihr Showroom',
    backButton: 'Zurück zur Übersicht',

    preview: 'Vorschau',
    edit: 'Showroom bearbeiten',
    scene: 'Szene',
    scenePreview: 'Szene {sceneOrder} Vorschau',
    scenePreviewHint: 'Hinweis: Dies ist ein 360°-Bild und wird daher verzerrt dargestellt.',

    products: {
      addProducts: 'Produkte hinzufügen',
      viewProducts: 'Produkte überblick',
      addProductHint: 'Es können nur Produkte im Format einer 3D-Datei (.glb) hinzugefügt werden.',
      label: 'Produkt an Position {n} setzen (optional)',
      none: 'Keine',
      settings: {
        title: 'Produkteinstellungen (optional)',
        description:
          'Passen Sie die Platzierung Ihres 3D-Produkts individuell an.',

        variances: 'Varianten',

        orientation: 'Orientierung (in ˚ )',
        orientationNote:
          'Falls die Frontansicht Ihres Produkts nicht in die richtige Richtung zeigt, können Sie die Ausrichtung mit diesem Wert anpassen.',

        farScale: 'Mit diesem Wert können Sie die Größe Ihres Produkts anpassen.',
        farScaleNote:
          'Wenn die Größe des Produkts nicht korrekt dargestellt wird, können Sie sie mit diesem Wert vergrößern oder verkleinern',

        zoomedScale: 'Produktgröße bei Zoom',
        zoomedScaleNote:
          'Bestimmen Sie, wie groß das Produkt in der Detailansicht angezeigt wird.',

        button: 'Angaben speichern',
      },
    },
  },

  productsPage: {
    title: 'Alle Produkte',
    createButton: 'Produkt hinzufügen',
    edit: 'Produkt bearbeiten',
    delete: 'Produkt(e) löschen',
    multipleProductsSelected: '{count} Produkte ausgewählt',

    deleteConfirmation: {
      title: 'Produkt(e) löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Produkt(e) löschen',
      description: {
        single:
          'Wenn Sie Ihr Produkt <b>{name}</b> löschen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf „Produkt(e) löschen“.',
        multiple:
          'Wenn Sie Ihre {count} Produkte löschen möchten, geben Sie bitte „{text}“ ein und klicken Sie auf „Produkt(e) löschen“.',
      },
      validationText: '{count} Produkte löschen',
    },
  },

  productCreationModal: {
    title: 'Produkt hinzufügen',
    close: 'Abbrechen',
    button: 'Angaben speichern',
    description: 'Füllen Sie die folgenden Felder aus, um ein Produkt hinzuzufügen. Nachdem Sie Ihr Produkt erfolgreich hinzugefügt haben, können Sie es auf einer Expo präsentieren.',
    generalInformation: {
      title: 'Allgemeine Informationen',
      name: 'Produktname',
      description: 'Produktbeschreibung',
      teaser: 'Produktteaser (zur Vorschau auf Ihrem Markenprofil)',
      associate: 'Produktexperten hinzufügen',
      associateLabel: 'Produktexperten',
      tags: 'Fügen Sie Tags hinzu (optional)',
      note: 'Trennen Sie Tags mit einem Komma oder der Enter-Taste.',
      linkAR: 'AR-Link',
    },
    previewImage: {
      name: 'Bildvorschau',
      title: 'Bildvorschau',
      description: 'Laden Sie hier ein Vorschaubild für Ihr Produkt hoch.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
    },
    slider: {
      title: 'Bilder',
      description: 'Laden Sie hier weitere Bilder Ihres Produkts hoch. Diese werden als Slideshow dargestellt. Bitte beachten Sie beim Anlegen die gewünschte Reihenfolge.',
    },

  },

  productVariantCreationModal: {
    title: 'Produktvariante für „{productName}“ hinzufügen',
    close: 'Abbrechen',
    button: 'Produktvariante hinzufügen',
    generalInformation: {
      title: 'Allgemeine Informationen',
      name: 'Name',
    },
    object3d: {
      title: '3D-Objekt',
      description: 'Laden Sie hier ein 3D-Modell Ihres Produkts hoch.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: max. 20MB; .glb.',
    },
    usdz: {
      title: '3D-AR-Objekt (optional)',
      description: 'Laden Sie für die Verwendung von Augmented Reality auf iOS-Geräten eine separate .usdz-Datei hoch.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: .usdz.',
    },
    variantColor: {
      title: 'Farbe auswählen (optional)',
      description: 'Wählen Sie die primäre Farbe Ihrer Variante aus.',
      note: 'Für ein optimales Ergebnis wählen Sie eine Farbe aus, welche diese Variante am besten repräsentiert.',
    },

  },

  uploadAttachmentCard: {
    empty: {
      title: 'Dokument hochladen',
    },
    uploaded: {
      placehoder: 'Dateiname',
    },
  },

  productEdit: {
    button: 'Angaben speichern',
    description: 'Hier haben Sie die Möglichkeit, Ihr Produkt zu bearbeiten.',
    generalInformation: {
      title: 'Allgemeine Informationen',
      name: 'Produktname',
      description: 'Produktbeschreibung',
      teaser: 'Produktteaser (zur Vorschau auf Ihrem Markenprofil)',
      associate: 'Produktexperten auswählen',
      tags: 'Fügen Sie Tags hinzu (optional)',
      note: 'Trennen Sie Tags mit einem Komma oder mit der Enter-Taste.',
      linkAR: 'AR-Link',
    },
    previewImage: {
      title: 'Bildvorschau',
      description: 'Laden Sie hier ein Vorschaubild für Ihr Produkt hoch.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: 16:9; max. 1MB; .jpeg, .png oder .heic.',
    },
    slider: {
      title: 'Bilder',
      description: 'Laden Sie hier weitere Bilder Ihres Produkts hoch. Diese werden als Slideshow dargestellt. Bitte beachten Sie beim Anlegen die gewünschte Reihenfolge.',
    },
  },

  productVariantEditModal: {
    title: 'Produktvariante {variantName} von {productName} bearbeiten',
    close: 'Abbrechen',
    button: 'Angaben speichern',
    generalInformation: {
      title: 'Allgemeine Informationen',
      name: 'Variantenname',
    },
    object3d: {
      title: '3D-Objekt',
      description: 'Laden Sie hier ein 3D-Modell Ihres Produkts hoch.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: max. 20MB; .glb.',
    },
    usdz: {
      title: '3D-AR-Objekt (optional)',
      description: 'Laden Sie für die Verwendung von Augmented Reality auf iOS-Geräten eine separate .usdz-Datei hoch.',
      note: 'Laden Sie bitte für ein optimales Ergebnis folgende Datei hoch: .usdz.',
    },
    variantColor: {
      title: 'Farbe auswählen',
      description: 'Wählen Sie die primäre Farbe Ihrer Variante aus.',
      note: 'Für ein optimales Ergebnis wählen Sie eine Farbe aus, welche diese Variante am besten repräsentiert.',
    },
  },

  productDetailsPage: {
    title: 'Produktdetails',
    backButton: 'Zurück zur Übersicht',
    about: {
      name: 'Bildvorschau',
      preview: 'Bildvorschau',
      variants: 'Produktvarianten',
      addVariant: 'Produktvariante hinzufügen',
      imageSlider: 'Bilder',
      teaser: 'Teaser',
    },

    panelCard: {
      file: 'Datei',
      uploadTime: 'Hochgeladen am {time}',
    },

    deleteConfirmation: {
      title: 'Produktvariante löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Produktvariante löschen',
      description: 'Wenn Sie die Produktvariante <b>{name}</b> löschen möchten, tippen Sie bitte „{name}“ ein und klicken Sie auf „Produktvariante löschen“.',
      validationText: '{name} Produktvariante löschen',
    },
    documents: {
      title: 'Dokumente',
      description: 'Laden Sie Dokumente zu Ihrem Produkt hoch, um Ihren Besuchenden mehr Informationen darüber bereitzustellen. ',
    },
  },

  wysiwyg: {
    embed: 'Möchten Sie Medien einbinden?',
    embedWebsite: 'Möchten Sie Ihre Webseite einbinden?',
    confirmation: 'Ja',

    messageYoutube: 'Machen Sie sich keine Sorgen, wir zeigen Ihr Youtube-Video, nachdem Sie Ihre Bearbeitung abgeschlossen haben.',
    messageVimeo: 'Machen Sie sich keine Sorgen, wir zeigen Ihr Vimeo-Video, nachdem Sie Ihre Bearbeitung abgeschlossen haben.',
    messageIframe: 'Machen Sie sich keine Sorgen, wir zeigen Ihre Webseite erst, nachdem Sie Ihre Bearbeitung abgeschlossen haben.',

    toolbar: {
      bold: 'Fett',
      bullets: 'Liste',
      byte:  'Byte',
      bytes: 'Bytes',
      captionPlaceholder: 'Bildunterschrift hinzufügen' ,
      code: 'Code',
      heading1: 'Titel',
      indent: 'Einzug vergrößern',
      italic: 'Kursiv',
      link: 'Link',
      numbers: 'Aufzählung',
      outdent: 'Einzug verkleinern',
      quote: 'Zitat',
      redo: 'Wiederherstellen',
      remove: 'Löschen',
      strike: 'Durchgestrichen',
      undo: 'Rückgängig',
      unlink: 'Link löschen',
      url: 'URL',
      urlPlaceholder: 'URL eingeben',
      GB: 'GB',
      KB: 'KB',
      MB: 'MB',
      PB: 'PB',
      TB: 'TB',
    },
  },

  brandInvitePage: {
    header: 'Willkommen auf der digitalen Virtrex Plattform!',
    description: '{invitingBrandName} hat Sie und Ihre Marke {invitedBrandName} zur Expo {expoName}. Um zu beginnen, wählen Sie bitte eine der folgenden Möglichkeiten.<br><br><b>Einloggen und Marke verlinken</b>: Wenn Sie und Ihre Marke bereits einen Account haben, müssen Sie nach dem Einloggen auswählen, welche Marke Sie haben möchten.<br><br><b>Neuen Account erstellen</b>: wenn Sie und Ihre Marke noch keinen Account bei Virtrex haben.',
    options: {
      logIn: {
        title: 'Einloggen und Marke verlinken',
        description: 'Anmelden und Ihre Marke anhängen, wenn sie bereits vorhanden ist.',
      },
      createNew: {
        title: 'Neuen Account erstellen',
        description: 'Klicken Sie hier, wenn Sie und Ihre Marke noch keinen Virtrex-Account haben.',
      },
    },
  },

  layout: {
    manage: {
      backToHomePage: 'Zurück zur Startseite',
    },
  },

  attachBrandToExpoPage: {
    header: 'Marke verlinken',
    description: 'Wählen Sie die Marke aus, die Sie mit der Expo verbinden möchten {expoName}. Die Marke {invitingBrandName} hat die Marke {invitedBrandName}.',
    label: {
      selectBrand: 'Marke auswählen',
    },
    button: {
      help: 'Brauchen Sie Hilfe?',
      submit: 'Marke mit Expo verbinden',
    },
  },

  salesLandingPage: {
    supportCard: {
      headline: 'Kundengespräche',
      description: 'Planen Sie Ihre Kundengespräche oder sehen Sie, wer Sie kontaktieren möchte.',
      textLink: 'Zu Kundengesprächen',
    },
    leadsCard: {
      headline: 'Leads',
      description: 'Sehen Sie, welche Besuchenden sich auf Ihren Seiten befinden und beginnen Sie eine Unterhaltung.',
      textLink: 'Zu meinen Leads',
    },
  },

  // TODO: remove 'leadsPage'
  leadsPage: {
    goToLeadsPage: 'Zurück zu Leads',
    showMoreText: 'Mehr anzeigen ({count} verbleibend)',
    newLeads: {
      headline: 'Potenzielle Leads',
      description: 'Hier finden Sie alle Besuchenden, die sich derzeit auf einer Ihrer Seiten befinden oder schon einmal dort waren und mit denen Sie noch keinen Kontakt aufgenommen haben. Sprechen Sie Ihre potenziellen Leads an, indem Sie eine Kontaktanfrage senden.',
      showMoreText: 'Alle potenziellen Leads anzeigen',
      noItemsText: 'Noch keine Besuchenden',
    },
    incomingLeads: {
      headline: 'Eingehende Kontaktanfragen',
      description: 'Hier finden Sie alle Besuchenden, die um eine Kontaktaufnahme gebeten haben.',
      showMoreText: 'Alle eingehenden Kontaktanfragen anzeigen',
      noItemsText: 'Noch keine eingehenden Kontaktanfragen',
    },
    outgoingLeads: {
      headline: 'Gesendete Kontaktanfragen',
      description: 'Hier finden Sie alle Besuchenden, denen Sie bereits eine Kontaktanfrage gesendet haben.',
      showMoreText: 'Alle gesendeten Kontaktanfragen anzeigen',
      noItemsText: 'Noch keine gesendeten Kontaktanfragen',
    },
    openLeads: {
      headline: 'Open conversations',
      description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
      showMoreText: 'View all open conversations',
    },
    lead: {
      statusOnline: 'Online',
      statusOffline: 'Offline',
      stateOnBrand: 'Markenbereich',
      stateOnExpo: 'Auf Expo',
      favorites: 'Messemappe',
      countOfProducts: '{count} Produkt(e)',
      visits: 'Besuch(e)',
      countVisits: '{count} Seite(n)',
      timeVisits: '{time} total',
      details: 'Details',
      contactRequest: 'Kontaktieren',
      requestSend: 'Kontaktiert',
      resendRequest: 'Erneut kontaktieren',
      requestLimitReached: 'Anfragegrenze erreicht',
      goToScheduleCalls: 'Kundengespräche',
    },
    actionBar: {
      downloadAnalytics: 'Download Leads Liste',
    },
    filtersDropDown: {
      title: 'Filter',
      expo: 'Expo „{expoName}“',
      hideAnonymous: 'Anonyme Besuchende ausblenden',
      hideOffline: 'Offline Besuchende ausblenden',
    },
  },

  salesPages: {
    content: {
      potentialLeads: {
        title: 'Potenzielle Leads',
        noData: 'Noch keine potenziellen Leads',
        showAll: 'Alle potenziellen Leads ansehen',
        showMore: 'Mehr anzeigen ({count} verbleibend)',
      },
      warning: {
        header: 'Fehlende Erreichbarkeit',
        button: 'Erreichbarkeit eintragen',
        body: 'Keine Erreichbarkeit eingerichtet. Bitte stellen Sie Ihre Erreichbarkeit ein, um an Gesprächen teilnehmen zu können.',
      },
      openConversations: {
        title: 'Ihre Interaktionen',
        noData: 'Noch keine Interaktionen',
        showAll: 'Alle Interaktionen ansehen',
        showMore: 'Mehr anzeigen ({count} verbleibend)',
      },
      recentActivity: {
        title: 'Neuste Ereignisse',
        noData: 'Noch keine neusten Ereignisse',
        showMore: 'Mehr anzeigen ({count} verbleibend)',
        description: {
          missedCall: 'Verpasstes Gespräch',
          declinedContactRequest: 'Kontaktanfrage abgelehnt',
          newMessage: 'Neue Nachricht',
          newScheduledCall: 'Gespräch vereinbart',
          successfulCall: 'Erfolgreicher Anruf',
          declinedCall: '{0} lehnt das geplante Gespräch ab',
          cancelCall: '{userName} hat das Kundengespräch "{callTitle}" abgesagt',
        },
      },
      closestCall: {
        title: 'Geplante Gespräche',
        noData: 'Noch keine geplanten Gespräche',
        showAll: 'Alle Kundengespräche anzeigen',
      },
      videoCalls: {
        buttonJoinCall: 'Gespräch beitreten',
        buttonAccept: 'Zusagen',
        buttonDecline: 'Absagen',
        youAccepted: 'Sie haben zugesagt',
        youDeclined: 'Sie haben abgelehnt',
        invitedByVisitor: 'Eingeladen von Besucher',
        scheduled: {
          title: 'Geplante Gespräche',
          noData: 'Noch keine geplanten Gespräche',
        },
        past: {
          title: 'Vergangene Gespräche',
          noData: 'Noch keine vergangenen Gespräche',
        },
        statuses: {
          'pending': 'Anstehend',
          'declined': 'Abgelehnt',
          'inProgress': 'Live',
          'cancelled': 'Abgesagt',
          'finished': 'Beendet',
        },
        dropDown: {
          title: 'Geplante Gespräche',
          edit: 'Bearbeiten',
          delete: 'Löschen',
        },
      },
    },
    actionBar: {
      downloadAnalytics: 'Download Leads Liste',
      scheduleCallButton: 'Neues Gespräch planen',
      availability: 'Erreichbarkeit',
      recentActivity: {
        title: 'Neuste Ereignisse',
        noData: 'Noch keine weiteren Aktivitäten',
        description: {
          missedCall: 'Verpasstes Gespräch',
          declinedContactRequest: 'Kontaktanfrage abgelehnt',
          newMessage: 'Neue Nachricht',
          newScheduledCall: 'Gespräch vereinbart',
          successfulCall: 'Erfolgreicher Anruf',
          declinedCall: '{0} lehnt das geplante Gespräch ab',
          cancelCall: 'Gespräch abgesagt',
        },
      },
    },
  },

  uploadPanelVideoModal: {
    title: 'Video hochladen',
    closeButton: 'Abbrechen',
    description: 'Laden Sie hier den aufgezeichneten Livestream hoch, damit Ihre Besuchenden diesen als Video erneut ansehen können.',
    uploadButton: 'Datei auswählen',
    warning: {
      title: 'Wichtiger Hinweis',
      description: 'Der Upload kann, abhängig von der Internetverbindung, längere Zeit in Anspruch nehmen. Bitte stellen Sie sicher, dass Sie dieses Fenster nicht schließen und Ihr Computer nicht in den Ruhezustand geht bis der Upload abgeschlossen ist.',
    },
  },

  contactHistory: {
    empty: 'Sie hatten mit diesem Kunden bisher noch keinen Kontakt.',
    directChat: 'Chat',
    contactRequest: {
      incoming: {
        message: 'Eingehende Kontaktanfrage',
        description: 'Von: {name}',
      },
      outgoing: {
        message: 'Ausgehende Kontaktanfrage',
        description: 'Von: {name}',
      },
    },
    scheduledVideoCall: {
      cancelled: {
        message: 'Nicht erreicht',
        description: 'Keine Antwort',
      },
      finished: {
        message: 'Kontaktiert',
        description: 'Mitarbeitende(r): {name}',
      },
      scheduled: {
        message: 'Termin vereinbart',
        description: 'Geplant am: {datetime}',
      },
      in_progress: {
        message: 'In Bearbeitung',
        description: 'Mitarbeitende(r): {name}',
      },
      pending: {
        message: 'Steht aus',
        description: 'Mitarbeitende(r): {name}',
      },
    },
  },

  contactState: {
    empty: 'Sie hatten mit diesem Kunden bisher noch keinen Kontakt.',
    incoming: {
      message: 'Eingehende Kontaktanfrage',
      description: 'Call angefragt',
    },
    outgoing: {
      message: 'Ausgehende Kontaktanfrage',
      description: 'Warte auf Antwort des Kontakts',
    },
  },

  chat: {
    title: 'Chat',
    removeConfirm: {
      cancel: 'Abbrechen',
      delete: 'Löschen',
    },
  },

  downloadAnalyticsPage: {
    title: 'Insights',
    header: 'Report herunterladen',
    description: 'Um Ihren Report herunterzuladen, müssen Sie eine Expo und den gewünschten Zeitrahmen auswählen.',
    selectExpoLabel: 'Bitte wählen Sie eine Expo aus',
    date: {
      title: 'Bitte wählen Sie einen Zeitrahmen',
      label: {
        to: 'bis',
        start: 'Wählen Sie das Startdatum',
        end: 'Wählen Sie das Enddatum',
      },
    },
    brand: {
      title: 'Markenreport',
      description: 'Laden Sie hier Ihren Report herunter.',
      button: 'Excel herunterladen',
    },
    expo: {
      title: 'Exporeport',
      description: 'Laden Sie hier Ihren Report herunter.',
      button: 'Excel herunterladen',
    },
  },
  downloadLeadsListModal: {
    title: 'Download Leads Liste',
    closeButton: 'Abbrechen',
    description: 'Um Ihren Report herunterzuladen, müssen Sie eine Expo und den gewünschten Zeitrahmen auswählen.',
    selectExpoLabel: 'Bitte wählen Sie eine Expo aus',
    date: {
      title: 'Bitte wählen Sie einen Zeitrahmen',
      label: {
        to: 'bis',
        start: 'Wählen Sie das Startdatum',
        end: 'Wählen Sie das Enddatum',
      },
    },
    downloadButton: 'Excel herunterladen',
  },

  settingsPage: {
    brandSetupCard: {
      headline: 'Markeneinstellungen',
      description: 'Bearbeiten Sie Ihre Markenseite sowie Ihr Logo und beschreiben Sie Ihre Marke.',
      textLink: 'Markeneinstellungen',
    },
    teamManagementCard: {
      headline: 'Teammanagement',
      description: 'Laden Sie Ihre Teammitglieder ein und vergeben Sie Rollen und Rechte.',
      textLink: 'Teammanagement',
    },
    apiIntegrationCard: {
      headline: 'API-Integration',
      description: 'Verbinden Sie Ihre eigenen Systeme mit Virtrex und verwalten Sie die entsprechenden Einstellungen.',
      textLink: 'API-Integration',
    },
    webhooksCard: {
      headline: 'Webhook-Integration',
      description: 'Senden Sie mit Virtrex Informationen an Ihre eigenen Systeme und verwalten Sie die entsprechenden Einstellungen.',
      textLink: 'Webhook-Integration',
    },
  },

  teamManagementPage: {
    delete: 'Teammitglied(er) löschen',
    edit: 'Profil bearbeiten',
    multipleElementsSelected: '{count} Teammitglieder ausgewählt',
    inviteButton: 'Teammitglieder hinzufügen',
    teamTable: {
      title: 'Teammanagement',
      cta: 'Teammitglieder hinzufügen',
      dropdownCTA: 'Rolle anlegen',
      dropdownTitle: 'Rolle bearbeiten',
    },
    roleTable: {
      title: 'Teamrollen',
      cta: 'Rolle anlegen',
      editPermissions: 'Berechtigungen bearbeiten',
      viewPermissions: 'Berechtigungen anzeigen',
      assignedTo: '{count} Mitarbeitende zugewiesen',
    },
    invites: {
      title: 'Ausstehende Einladungen',
      pendingLabel: 'Einladung steht aus',
    },
    createRoleModal: {
      title: 'Rolle anlegen',
      sections: {
        roleName: 'Allgemeine Informationen',
        rolesList: 'Rollenberechtigungen',
      },
      roleNameInput: 'Name der Rolle',
      closeButton: 'Abbrechen',
      button: 'Rolle anlegen',
    },
    editRoleModal: {
      title: 'Rolle „{name}“ bearbeiten',
      message: 'Bitte beachten Sie, dass sich alle Änderungen an der Rolle „{name}“ auf alle Teammitglieder mit dieser Rolle auswirken.',
      inputLabel: 'Name der Rolle',
    },
    viewRoleModal: {
      title: 'Rolle „{name}“ Überblick',
    },
    deleteConfirmation: {
      title: 'Teammitglied(er) löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Teammitglied(er) löschen',
      description: {
        single:
            'Wenn Sie Ihr Teammitglied <b>{name}</b> entfernen möchten, geben Sie bitte „{name}“ ein und klicken Sie auf „Teammitglied(er) löschen“.',
        multiple:
            'Wenn Sie die {count} Teammitglieder entfernen möchten, geben Sie bitte „{text}“ ein und klicken Sie auf „Teammitglied(er) löschen“.',
      },
      validationText: '{count} Teammitglieder löschen',
      hasAssociateError: 'Diese Aktion kann nicht ausgeführt werden. Sie können sich nicht selbst aus dem Team entfernen. Bitte heben Sie Ihre Auswahl auf, um fortfahren zu können.',
    },
    deleteRoleConfirmation: {
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Rolle löschen',
      description: 'Sind Sie sicher, dass Sie die Rolle <b>{name}</b> löschen möchten? Wenn ja, geben Sie bitte „{name}“ in die Eingabe und klicken Sie auf „Rolle löschen“.',
      hasAssociateError: 'Die Rolle {name} kann nicht gelöscht werden, da ein Teammitglied diese Rolle hat.',
    },
  },

  alertModal: {
    closeButton: 'Abbrechen',
    goBackText: 'Zurück',
  },

  roles: {
    admin: 'Admin',
    member: 'Mitarbeitende',
  },

  permissions: {
    expos: {
      category: 'Expo',
      edit: 'Expo bearbeiten',
      delete: 'Expo löschen',
      canModerateChat: 'Chat Moderation',
    },
    sales: {
      category: 'Kundengespräche',
      leadsSalesCalls: 'Kundengespräche und Leads',
      leadsReport: 'Leadreport downloaden',
    },
    products: {
      category: 'Produkte',
      edit: 'Produkte bearbeiten',
      delete: 'Produkte löschen',
    },
    showrooms: {
      category: 'Showrooms',
      edit: 'Showrooms bearbeiten',
      delete: 'Showrooms löschen',
    },
    contacts: {
      category: 'Kontakte',
      all: 'Kontakte bearbeiten',
      personal: 'Eigene Kontakte bearbeiten',
      delete: 'Eigene Kontakte löschen',
    },
    settings: {
      category: 'Einstellungen',
      editBrandSetup: 'Markeneinstellungen bearbeiten',
      inviteTeamMembers: 'Teammitglieder hinzufügen',
      editTeamMembers: 'Teammitglieder bearbeiten',
      deleteTeamMembers: 'Teammitglieder löschen',
      editUserRoles: 'Rollen der Teammitglieder bearbeiten',
      editRolePermissions: 'Rollen bearbeiten',
      viewPayments: 'Überblick Lizenzen',
      managePayments: 'Lizenzen bearbeiten',
      editApiAndWebhooks: 'API und Webhooks bearbeiten',
      installApps: 'Applikationen verwalten',
    },
    insights: {
      category: 'Insights',
      report: 'Report downloaden',
    },
  },

  broadcastProviderSetting: {
    title: 'Stream-Anbieter',
    identifier: {
      youtube: 'Video ID',
      vimeo: 'Event ID',
      twitch: 'Twitch Kanal',
    },
    provider: {
      label: 'Stream-Anbieter',
      placeholder: 'Stream-Anbieter auswählen',
      virtrex: 'Virtrex',
      youtube: 'YouTube',
      twitch: 'Twitch',
      vimeo: 'Vimeo',
    },
    chatType: {
      label:'Chat auswählen',
      placeholder: 'Chat-Anbieter auswählen',
      twitch: 'Twitch chat',
      vimeo: 'Vimeo chat',
      virtrex: 'Virtrex chat (Default)',
    },
  },

  selectProviderModal: {
    title: 'Stream-Anbieter auswählen',
    closeButton: 'Schließen',
  },

  apiIntegration: {
    title: 'API-Integration',
    description: 'Um Ihre externen Systeme und Applikationen mit Virtrex zu verbinden, können Sie in diesem Bereich Ihre API-Schlüssel erstellen und löschen. Weiterführende Informationen entnehmen Sie unserer {documentationLink}.',
    documentationLinkText: 'API-Dokumentation',
    tokens: 'API-Tokens',
    docsLink: 'API-Dokumentation öffnen',
    createToken: 'Neuen API-Schlüssel hinzufügen',
    modal: {
      createToken: {
        label: 'Bezeichnung des API-Schlüssels',
        placeholder: 'Hier Text eingeben',
      },
      copyToken: {
        label: 'API-Schlüssel kopieren',
        description: 'Klicken Sie das Symbol der überlappenden Dateien rechts neben Ihrem API-Schlüssel, um ihn zu kopieren.',
        feedback: 'Kopiert.',
      },
      cta: 'API-Schlüssel erstellen',
    },
    deleteToken: {
      title: 'Token löschen',
      description: 'Sind Sie sicher, dass Sie den Token <b>{name}</b> löschen möchten? Wenn ja, geben Sie bitte „{name}“ ein und klicken Sie auf „Token löschen“.',
      inputLabel: 'Hier Text eingeben',
      button: 'Token löschen',
    },
  },

  appsPage: {
    title: 'Applikationen',
    description: 'Hier erhalten Sie einen Überblick Ihrer installierten Apps und können weitere Installationen vornehmen.',
    adminApps: 'Admin Applikationen',
    visitorApps: 'Besuchenden Applikationen',
    installButton: 'Applikation installieren',
    card: {
      link: 'Applikation anzeigen',
    },
    appModal: {
      install: {
        title: 'Applikation installieren',
        description: 'Bitte geben Sie Ihre JSON Manifest Daten in das Feld ein.',
        button: 'Installieren',
      },
      edit: {
        title: 'Applikation bearbeiten',
        description: 'Bitte ändern Sie Ihre JSON Manifest Daten in das Feld ein.',
        button: 'Angaben speichern',
      },
      closeButton: 'Abbrechen',
      manifest: {
        title: 'Beispiel manifest',
        placeholder: 'JSON Manifest',
      },
      errors: {
        json: 'Bitte geben Sie einen gültigen Konfigurationscode im JSON Format ein.',
        missingRequiredProps: 'In Ihrem Konfigurationscode fehlt eine der folgenden Komponenten: Titel, Beschreibung, Bild oder Modul',
        missingModules: 'Ihr Konfigurationscode muss mindestens ein Admin- oder Besuchermodul enthalten.',
        unsupportedModule: 'Einer der Typen der App-Module in Ihrem Konfigurationscode wird nicht unterstützt.',
      },
    },
    deleteApp: {
      title: 'App löschen',
      description: 'Sind Sie sicher, dass Sie das App <b>{name}</b> löschen möchten? Wenn ja, geben Sie bitte "{name}" in die Eingabe und klicken Sie löschen.',
      inputLabel: 'Bitte hier eingeben',
      button: 'App löschen',
    },
  },

  thread: {
    message: {
      new: 'Neu',
    },
    textField: {
      placeholder: 'Geben Sie Ihre Nachricht ein...',
    },
  },

  recentActivity: {
    description: {
      missedCall: 'Verpasstes Gespräch',
      declinedContactRequest: 'Kontaktanfrage abgelehnt',
      newMessage: 'Neue Nachricht',
      newScheduledCall: 'Kundengespräch vereinbart',
      successfulCall: 'Erfolgreicher Anruf',
    },
  },

  webhookEventOptions: {
    category: {
      visitor: 'Aktionen von Besuchenden',
      brand: 'Marke',
    },
    visitor: {
      vipInviteAccepted: 'Einladung angenommen',
      firstLoggedIn: '(Erster) Besucher-Login',
      salesCallScheduled: 'Gesprächstermin vereinbart',
      productAddedToMessemappe: 'Produkt der Messemappe hinzugefügt',
      leadCreated: 'Neuer Lead-Kontakt',
      salesCallOccurred: 'Gespräch abgeschlossen',
    },
    brand: {
      expoInviteAccepted: 'Markeneinladung angenommen',
    },
  },

  webhooksPage: {
    title: 'Webhook-Integration',
    description: 'Senden Sie mit Virtrex Informationen an Ihre eigenen Systeme und verwalten Sie die entsprechenden Einstellungen.',
    webhooks: 'Webhooks',
    createWebhook: 'Webhook erstellen',
    isActiveField: 'Aktiv',
    multipleSelection: '{count} Webhooks ausgewählt',
    createWebhookModal: {
      title: 'Webhook erstellen',
      closeButton: 'Abbrechen',
      description: 'Wir senden eine POST-Anfrage an die untenstehende URL mit den Details aller abonnierten Events. Sie erhalten alle Daten im JSON-Format.',
      isActiveField: 'Aktiv',
      payloadUrlField: 'Payload_URL',
      secretField: 'Passwort',
      cta: 'Webhook erstellen',
      examplePayload: 'Beispiel payload',
      update: {
        cta: 'Webhook bearbeiten',
        title: 'Webhook bearbeiten',
      },
    },
    deleteWebhookModal: {
      title: 'Webhook löschen',
      inputLabel: 'Hier Text eingeben',
      buttonText: 'Webhook löschen',
      description: {
        single: 'Wenn Sie Ihr Webhook <b>{url}</b> entfernen möchten, geben Sie bitte „{url}“ ein und klicken Sie auf „Webhook löschen“.',
        multiple: 'Wenn Sie die <b>{count} Webhooks</b> entfernen möchten, geben Sie bitte „{text}“ ein und klicken Sie auf „Webhooks löschen“.',
      },
      multipleSelectionValidation: '{count} Webhooks löschen',
    },
  },

  workingHours: {
    title: 'Erreichbarkeit',
    defaultDaysHeader: 'Allgemeine Erreichbarkeit',
    customDaysHeader: 'Spezifische Erreichbarkeit',
    timeSlots: {
      unavailable: 'Nicht verfügbar',
      start: 'Von',
      until: 'bis',
      end: 'Ende',
    },
    editModal: {
      title: 'Erreichbarkeit bearbeiten',
      close: 'Abbrechen',
      description: 'Bitte geben Sie Ihre Erreichbarkeit ein. Besuchende können nur während dieser Zeiten Kundengespräche mit Ihnen vereinbaren. Für außergewöhnliche Events oder dergleichen, können Sie spezifische Erreichbarkeiten für diese Tage eintragen. Spezifische Erreichbarkeiten überschreiben die grundsätzlichen Erreichbarkeiten an dem festgelegten Datum.',
      defaultDaysHeader: 'Allgemeine Erreichbarkeit',
      customDaysHeader: 'Spezifische Erreichbarkeit',
      addCustomDay: 'Spezifisches Datum eintragen',
      submit: 'Änderungen speichern',
    },
  },

  pageErrors: {
    error404: {
      title: 'Die gesuchte Seite kann leider nicht gefunden werden.',
      description: 'Wenn Sie der Meinung sind, dass dies ein Fehler ist, wenden Sie sich bitte an Ihren Administrator.',
    },
    error500: {
      title: 'Leider ist auf dem Server ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
      description: 'Wir arbeiten daran, das Problem so schnell wie möglich zu beheben. Wir werden in Kürze wieder einsatzbereit sein.',
      ctaLink: window.$vcrConfig.references.technicalUpdateDE,
      ctaText: 'Bekannte Probleme anzeigen',
    },
  },

  formValidation: {
    messages: {
      alpha: 'Dieses Feld darf nur alphabetische Zeichen enthalten',
      alpha_dash: 'Dieses Feld darf alphanumerische Zeichen sowie Striche und Unterstriche enthalten',
      alpha_num: 'Dieses Feld darf nur alphanumerische Zeichen enthalten',
      alpha_spaces: 'Dieses Feld darf nur alphanumerische Zeichen und Leerzeichen enthalten',
      between: 'Dieses Feld muss zwischen {min} und {max} liegen',
      confirmed: 'Die Bestätigung stimmt nicht.',
      digits: 'Dieses Feld muss numerisch sein und exakt {length} Ziffern enthalten',
      dimensions: 'Dieses Feld muss {width} x {height} Bildpunkte groß sein',
      email: 'Dieses Feld muss eine gültige E-Mail-Adresse sein',
      excluded: 'Dieses Feld muss ein gültiger Wert sein',
      ext: 'Dieses Feld muss eine gültige Datei sein',
      image: 'Dieses Feld muss eine Grafik sein',
      oneOf: 'Dieses Feld muss ein gültiger Wert sein',
      integer: 'Dieses Feld muss eine ganze Zahl sein',
      length: 'Die Länge dieses Feldes muss {length} sein',
      max: 'Dieses Feld darf nicht länger als {length} Zeichen sein',
      max_value: 'Dieses Feld darf maximal {max} sein',
      mimes: 'Dieses Feld muss einen gültigen Dateityp haben',
      min: 'Dieses Feld muss mindestens {length} Zeichen lang sein',
      min_value: 'Dieses Feld muss mindestens {min} sein',
      numeric: 'Dieses Feld darf nur numerische Zeichen enthalten',
      regex: 'Das Format dieses Feldes ist ungültig',
      required: 'Dieses Feld ist ein Pflichtfeld',
      required_if: 'Dieses Feld ist ein Pflichtfeld',
      size: 'Dieses Feld muss kleiner als {size}KB sein',
      double: 'Dieses Feld muss eine gültige Dezimalzahl sein',
    },
    forms: {
      dataInvalid: 'Einige Ihrer Eingaben sind ungültig. Bitte überprüfen Sie die rot markierten Felder.',
      login: {
        credentialsNotCorrect: 'Benutzername oder Kennwort stimmen nicht. Bitte überprüfen Sie ihre Eingaben und versuchen Sie es erneut.',
      },
    },
  },

  languageSupport: {
    title: 'Sprachen',
    cta: 'Sprachen anpassen',
    supportedLanguages: 'Unterstützte Sprachen:',
    supported: {
      title: 'Systemsprachen',
      description: 'Diese Sprachen werden vollständig von Virtrex unterstützt. Besuchende finden alle Inhalte sowie die Plattformnavigation in diesen Sprachen.',
    },
    unsupported: {
      title: 'Unterstützte Sprachen',
      description: 'Diese Sprachen werden nur für die Inhalte der Marken- und Produktseiten unterstützt. Die Plattformnavigation wird in Englisch angezeigt.',
    },
    modal: {
      warning: {
        title: 'Sprache(n) löschen',
        button: 'Sprache(n) löschen',
      },
    },
    defaultLanguage: {
      label: 'Standardsprache',
      placeholder: 'Wählen Sie eine Standardsprache',
    },
  },

  multiLanguage: {
    validationMessage: 'Bitte geben Sie für jede Sprache Ihrer Expo einen Titel und eine Beschreibung an',
  },

  videoCallPage: {
    title: '{hostName} von {brandName} hat dieses Kundengespräch organisiert',
    buttonEditText: 'Kundengespräch bearbeiten',
    host: 'Organisiert durch',
    participants: 'Teilnehmende',
    salesAssociates: 'Sales Mitarbeitende',
    visitors: 'Besuchende',
    meetingDetails: 'Details',
    dateAndTime: 'Datum und Uhrzeit',
    introduction: 'Einleitung',
    introductionText: '{hostName} von {brandName} möchte Ihnen die Produkte der Marke vorstellen.',
    buttonAndCall: 'Gespräch verlassen',
    live: 'live',
    approvingStates: {
      buttonTextAccept: 'Zusagen',
      buttonTextDecline: 'Ablehnen',
      statusTextAccept: 'Sie haben zugesagt',
      statusTextDecline: 'Sie haben abgelehnt',
      buttonTextChange: 'Status ändern',
    },
    bannerStatusVideoCall: {
      soon: {
        text: 'Das Gespräch beginnt in Kürze',
        joinButtonText: 'Gespräch beitreten',
      },
      inProgress: {
        text: 'Gespräch läuft',
        info: '{numberOfParticipantsInCall} Teilnehmer sind anwesend',
        joinButtonText: 'Gespräch beitreten',
      },
      cancelled: {
        text: 'Dieses Kundengespräch wurde abgesagt',
      },
      finished: {
        text: 'Dieses Kundengespräch wurde beendet',
        info: 'Beendet um {endDate}',
      },
    },
    products: {
      suggestedProductsAndFiles: 'Vorgeschlagene Produkte und Dateien',
      suggestedProducts: 'Vorgeschlagene Produkte',
      addProducts: 'Produkte hinzufügen',
      uploadFiles: 'Datei hochladen',
      remove: 'Löschen',
      arText: '3D / AR',
      sharedFiles: 'Geteilte Dateien',
      description: 'Von Ihnen in diesem Gespräch hochgeladene Produkte oder Dateien, werden den Teilnehmern hier angezeigt. Nachdem ein Gespräch beendet wurde, sind die Inhalte für die Teilnehmer immer noch auf der Gesprächs Informationsseite zugänglich.',
    },
  },

  searchUserFlyout: {
    searchResults: 'Suchergebnisse',
    noResults: 'Keine Besucher gefunden. Versuchen Sie, nach einem anderen Namen, einer anderen Firma oder einer anderen E-Mail-Adresse zu suchen. Um einen Gast einzuladen, geben Sie bitte seine vollständige E-Mail-Adresse ein.',
    addAsGuest: 'Als Gast hinzufügen',
  },

  searchProductFlyout: {
    searchResults: 'Suchergebnisse',
    noResults: 'Keine Produkte gefunden',
  },

  scheduleVideoCallModal: {
    title: 'Neues Gespräch vereinbaren',
    close: 'Schließen',
    description: 'Sie können ein neues Gespräch vereinbaren, indem Sie mindestens einen neuen Teilnehmer oder Teilnehmerin einladen. Bitte wählen Sie davor die gewünschte Expo aus bevor sie fortfahren.',
    expoPickerLabel: 'Expo auswählen',
    submitButton: 'Einladung verschicken',
    fieldTitle: {
      label: 'Titel',
    },
    fieldDescription: {
      label: 'Einladungstext (optional)',
    },
    participants: {
      title: 'Teilnehmende hinzufügen',
      description: 'Insgesamt können Sie bis zu fünf Teilnehmende bestehend aus Besuchenden und/oder Sales Mitarbeitenden zu Ihrem Gespräch hinzufügen.',
      pleaseSelectExpo: 'Bitte wählen Sie zuerst eine Expo aus bevor Sie Teilnehmer einladen',
      associates: {
        label: 'Sales Mitarbeitende',
        placeholder: 'Geben Sie Namen oder Email-Adresse ein',
        add: 'Sales Mitarbeitende hinzufügen',
        noSelected: 'Keine Sales Mitarbeitende hinzugefügt',
        noResultsText: 'Keine Teammitglieder gefunden. Versuchen Sie, nach einem anderen Namen oder einer anderen E-Mail-Adresse zu suchen. Sie können nur Personen aus Ihrem Unternehmen einladen.',
      },
      visitors: {
        label: 'Besuchende',
        placeholder: 'Geben Sie Namen, Email-Adresse oder Firma ein',
        add: 'Besuchende hinzufügen',
        noSelected: 'Keine Besuchenden hinzugefügt',
        noResultsText: 'Keine Besucher gefunden. Versuchen Sie, nach einem anderen Namen, einer anderen Firma oder einer anderen E-Mail-Adresse zu suchen. Um einen Gast einzuladen, geben Sie bitte seine vollständige E-Mail-Adresse ein.',
      },
    },
    dateAndTime: {
      title: 'Datum & Zeitpunkt',
      description: 'Termine können in halbstündigen Zeitintervallen geplant werden. Wenn Sie Hilfe beim Finden eines Zeitfensters brauchen, nutzen Sie bitte den Kalenderbutton auf der rechten Seite.',
      dateLabel: 'Datum',
      timeLabel: 'Zeitpunkt',
      pleaseSelectExpo: 'Bitte wählen Sie zuerst eine Expo aus bevor Sie Datum & Zeit festlegen',
    },
    userAvailability: {
      notAvailable: '{numberOfNotAvailable} Teilnehmer sind zu diesem Zeitpunkt nicht verfügbar.',
    },
  },

  editVideoCallModal: {
    title: 'Kundengespräch bearbeiten',
    close: 'Schließen',
    description: 'Sie können ein neues Gespräch vereinbaren, indem Sie mindestens einen neuen Teilnehmer oder Teilnehmerin einladen. Bitte wählen Sie davor die gewünschte Expo aus bevor sie fortfahren.',
    expoPickerLabel: 'Expo auswählen',
    submitButton: 'Kundengespräch aktualisieren',
    fieldTitle: {
      label: 'Titel',
    },
    fieldDescription: {
      label: 'Einladungstext (optional)',
    },
    participants: {
      title: 'Teilnehmende hinzufügen',
      description: 'Insgesamt können Sie bis zu fünf Teilnehmende bestehend aus Besuchenden und/oder Sales Mitarbeitenden zu Ihrem Gespräch hinzufügen.',
      associates: {
        label: 'Sales Mitarbeitende',
        placeholder: 'Geben Sie Namen oder Email-Adresse ein',
        add: 'Sales Mitarbeitende hinzufügen',
        noSelected: 'Keine Sales Mitarbeitende hinzugefügt',
        noResultsText: 'Keine Teammitglieder gefunden. Versuchen Sie, nach einem anderen Namen oder einer anderen E-Mail-Adresse zu suchen. Sie können nur Personen aus Ihrem Unternehmen einladen.',
      },
      visitors: {
        label: 'Besuchende',
        placeholder: 'Geben Sie Namen, Email-Adresse oder Firma ein',
        add: 'Besuchende hinzufügen',
        noSelected: 'Keine Besuchenden hinzugefügt',
        noResultsText: 'Keine Besucher gefunden. Versuchen Sie, nach einem anderen Namen, einer anderen Firma oder einer anderen E-Mail-Adresse zu suchen. Um einen Gast einzuladen, geben Sie bitte seine vollständige E-Mail-Adresse ein.',
      },
    },
    dateAndTime: {
      title: 'Datum & Zeitpunkt',
      description: 'Termine können in halbstündigen Zeitintervallen geplant werden. Wenn Sie Hilfe beim Finden eines Zeitfensters brauchen, nutzen Sie bitte den Kalenderbutton auf der rechten Seite.',
      dateLabel: 'Datum',
      timeLabel: 'Zeitpunkt',
    },
    userAvailability: {
      notAvailable: '{numberOfNotAvailable} Teilnehmer sind zu diesem Zeitpunkt nicht verfügbar.',
    },
  },

  suggestVideoCallProductModal: {
    title: 'Vorgeschlagene Produkte',
    close: 'Schließen',
    description: 'Von Ihnen in diesem Gespräch hochgeladene Produkte oder Dateien, werden den Teilnehmern hier angezeigt. Nachdem ein Gespräch beendet wurde, sind die Inhalte für die Teilnehmer immer noch auf der Gesprächs Informationsseite zugänglich.',
    noSelectedProducts: 'Keine Vorgeschlagene Produkte',
    buttonSubmit: 'Produkte speichern',
    productList: {
      title: 'Vorgeschlagene Produkte',
      buttonAddProduct: 'Produkte hinzufügen',
    },
  },

  uploadVideoCallFilesModal: {
    title: 'Dateien hochladen',
    close: 'Schließen',
    description: 'Die von Ihnen hier hochgeladenen Dateien werden für die Teilnehmer dieses Gesprächs sowohl zum Anschauen als auch zum Download bereitgestellt.',
    noSelectedProducts: 'Keine Dateien',
    buttonSubmit: 'Dateien speichern',
    productList: {
      title: 'Hinzugefügte Dateien',
      buttonAddProduct: 'Datei hochladen',
    },
  },

  notificationAboutDirectVideoCall: {
    title: 'Neues, eingehendes Gespräch in Virtrex.',
    text: 'Sie werden auf der {expoName} in Virtrex von {associateName} angerufen',
  },

  scheduledSalesCallCard: {
    copyLink: 'Anruflink kopieren',
    openCall: 'Gespräch beitreten',
    unknownCompany: 'Firma unbekannt',
    unknownPosition: 'Position unbekannt',
  },

  videoCallNotification: {
    incomingCall: {
      title: 'Eingehende Gespräche',
      join: 'Beitreten',
      decline: 'Ablehnen',
    },
    inProgressCalls: {
      title: 'Laufende Anrufe',
    },
    scheduledCall: {
      title: 'Geplanter Anruf',
    },
  },
};
