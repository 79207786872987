import * as chatThread from '@front/broadcasting/salescalls/chat-thread';
import * as contactRequest from '@front/broadcasting/salescalls/contact-request';
import * as recentActivity from '@front/broadcasting/salescalls/recent-activity';
import * as videoCall from '@front/broadcasting/salescalls/video-call';

const initializeBroadcastingForAssociate = (associateId: string) => {
  chatThread.joinChannel(associateId);
  contactRequest.joinChannel(associateId);
  recentActivity.joinChannel(associateId);
  videoCall.joinChannel(associateId);

  //TODO: add all `salescalls.${associateId}` broadcastings to this file
};

export default initializeBroadcastingForAssociate;
