import useExpoList from '@front/composition/use-expo-list';
import useUser from '@front/composition/use-user';
import i18n from '@front/lang/main';
import {
  IVideoCall,
} from '@typing/video-call';

const notificationSound = document.createElement('audio');

const audioSourceList = [
  {
    src: require('@sounds/echoed-ding-459.mp3'),
    type: 'audio/mp3',
  },
  {
    src: require('@sounds/echoed-ding-459.m4r'),
    type: 'audio/m4r',
  },
  {
    src: require('@sounds/echoed-ding-459.ogg'),
    type: 'audio/ogg',
  },
];

audioSourceList.forEach((item)=> {
  const sound = document.createElement('source');
  sound.src = item.src;
  sound.type = item.type;

  notificationSound.append(sound);
});

const showNotificationAboutDirectVideoCall = (videoCall: IVideoCall) => {
  const {
    getExpo,
  } = useExpoList();

  const expo = getExpo(videoCall.expoId);
  if (!expo) {
    return;
  }


  const {
    currentUser,
    getFullName,
  } = useUser();

  const myParticipant = videoCall.participants.find(participant => participant.userData.id === currentUser.value!.id);
  if (!myParticipant) {
    return;
  }

  const associateName = getFullName(myParticipant.userData);

  const nBody = String(i18n.t('notificationAboutDirectVideoCall.text', {
    expoName: expo.name,
    associateName,
  }));

  const notification = new Notification(String(i18n.t('notificationAboutDirectVideoCall.title')), {
    body: nBody,
    icon: expo.logoUrl || undefined,
  });

  const redirectUrl = `${window.location.origin}/video-calls/${videoCall.id}`;

  notification.addEventListener('click', (event) => {
    event.preventDefault();
    window.open(redirectUrl, '_blank');
    notification.close();
  });

  notificationSound.play();
};

export default showNotificationAboutDirectVideoCall;
