import IVisitorResource from '@api/resource/visitor';
import IUser from '@typing/user';

export enum EThreadParticipantStatus {
  Active = 'active',
}

export enum EThreadParticipantUserType {
  Visitor = 'App\\Models\\Visitor',
  Associate = 'App\\Models\\Associate',
}

export interface IThreadParticipant<T = IUser | IVisitorResource> {
  id: string
  threadId: string
  isModerator: boolean
  status: EThreadParticipantStatus
  userType: EThreadParticipantUserType
  userData: T
}

export const isVisitorParticipant = (participant: IThreadParticipant): participant is IThreadParticipant<IVisitorResource> => {
  return participant.userType === EThreadParticipantUserType.Visitor;
};

export const isAssociateParticipant = (participant: IThreadParticipant): participant is IThreadParticipant<IUser> => {
  return participant.userType === EThreadParticipantUserType.Associate;
};
