
import {
  defineComponent, computed,
} from '@vue/composition-api';
import {
  VtxIcon, EIcon,
} from '@convidera-team/virtrex-design-system';

export default defineComponent({
  components: {
    VtxIcon,
  },

  props: {
    props: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const icon = computed(() => {
      if (!props.props.sortStatus.sorted) {
        return '';
      }

      return props.props.sortStatus.asc ? EIcon.chevronUp : EIcon.chevronDown;
    });

    return {
      icon,
    };
  },
});
