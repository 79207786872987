export enum EUserTrackingPageType {
  AdminPanel = 'adminpanel',
}

export enum EUserEventName {
  VideoCallCreated = 'VideoCallCreated',
  ParticipantVideoCallJoined = ' ParticipantVideoCallJoined',
  ParticipantVideoCallEnded = ' ParticipantVideoCallEnded',
  ContactEmailSent = 'ContactEmailSent',
  DirectVideoCallExecuted = 'DirectVideoCallExecuted',
}

export interface IUserTrackingPayload {
  activityName: EUserEventName,
  relatedId: string,
  socketId: string,
  payload?: any,
}
