import {
  ESalutation, 
} from '@front/types/salutation';
import {
  EWeekDay,
} from '@typing/date-formats';

export default {
  languages: {
    en: 'English',
    de: 'German',
    fr: 'French',
    es: 'Spanish',
    it: 'Italian',
    nl: 'Dutch',
    pl: 'Polish',
  },
  guest: 'guest',
  hidden: 'Hidden',
  anonymous: 'Anonymous',
  backLinkText: 'Back',
  deletedVisitor: 'Former visitor',

  dateTimeFormats: {
    calendar: {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastDay: '[Yesterday]',
    },
  },

  common: {
    salutation: {
      [ESalutation.MALE]: 'Mr.',
      [ESalutation.FEMALE]: 'Mrs.',
      [ESalutation.DIVERS]: 'without salutation',
    },
  },

  weekDays: {
    [EWeekDay.Monday]: 'Monday',
    [EWeekDay.Tuesday]: 'Tuesday',
    [EWeekDay.Wednesday]: 'Wednesday',
    [EWeekDay.Thursday]: 'Thursday',
    [EWeekDay.Friday]: 'Friday',
    [EWeekDay.Saturday]: 'Saturday',
    [EWeekDay.Sunday]: 'Sunday',
  },

  topbar: {
    title: 'Virtual Fairs',
    greeting: 'Hello {userName}, Welcome to Virtrex!',
  },
  legal: {
    virtrex: 'Virtrex',
    text: 'All rights reserved.',
    imprint: 'Imprint',
    privacyPolicy: 'Privacy Policy',
    imprintUrl: window.$vcrConfig.references.impressumTool,
    privacyPolicyUrl: window.$vcrConfig.references.privacyPolicyEN,
  },
  navMenu: {
    expos: 'Expos',
    products: 'Products',
    showrooms: 'Showrooms',
    associates: 'Associates',
    contacts: 'Contacts',
    brand: 'Brand Setup',
    salesCalls: 'Sales Calls',
    sales: 'Sales',
    potentialLeads: 'Potential leads',
    openConversations: 'Open conversations',
    scheduledCalls: 'Scheduled calls',
    recentActivities: 'Recent activity',
    downloadAnalytics: 'Insights',
    settings: 'Settings',
    teamManagement: 'Team Management',
    apiIntegration: 'API Integration',
    apps: 'Apps',
    webhooks: 'Webhooks',
  },
  general: {
    active: 'Active',
    inactive: 'Inactive',
    close: 'Close',
    saveChanges: 'Save Changes',
    cancel: 'Cancel',
    confirm: 'Confirm',
    delete: 'Delete',
    edit: 'Edit',
  },

  table: {
    allRowsSelected: 'All records selected',
    count: 'Record {from} to {to} of {count}|{count} records|1 record',
    noResults: 'No matching records',
  },

  brandDetailsPage: {
    title: 'Brand Setup:',
    actionBar: {
      actions: {
        title: 'Manage brand',
        activate: 'Stop broadcasting',
        deactivate: 'Start broadcasting',
      },
    },
    name: {
      label: 'Brand Name',
    },
    logo: {
      title: 'Upload Logo',
      description:
        'Upload a logo for your brand.',
      note: 'Please upload the file as .png or .svg.',
    },
    headerImage: {
      title: 'Upload Cover Picture',
      description:
        'Upload a cover picture that represents your brand. This picture will be used on your exhibitor page.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },
    color: {
      title: 'Select Primary Color',
      description:
        'Select the primary color of your brand, this will define the color of components on your brand page.',
      note:
        'For an optimal result, choose a color that customers associate with your brand.',
    },
    language: {
      title: 'Languages',
      description: 'Please select the languages of your brand and save the description of your brand and your products in these languages. You can select the languages of an Expo in the Expo settings.',
      warning: {
        description: 'With “Delete Language(s)” all translated content for that language will be removed (e.g. brand settings, product descriptions, showrooms, etc.). This action is irreversible.',
      },
    },
    otherSettings: {
      title: 'Brand description',
      description: 'In the following sections you can add additional information about your company. Fill the input fields below for a full representation of your brand.',
      label: {
        description: 'Describe your brand',
        contactDetails: 'Contact Information',
      },
    },

    livestream: {
      title: 'Brandpage Livestreams',
      action: 'Change provider',
      provider: {
        title: 'Provider',
      },
      streamKey: {
        title: 'Stream-Key:',
        emptyText: 'An error occurred. A stream key could not be created for you. Please contact your Virtrex support. ',
        clickHere: 'Add',
      },
      serverLink: {
        title: 'Server Link:',
        emptyText: 'An error occurred. A stream key could not be created for you. Please contact your Virtrex support. ',
        clickHere: 'Add',
      },
    },
    legal: {
      title: 'Imprint & Privacy Policy',
      imprint: 'Imprint',
      privacyPolicy: 'Privacy Policy',
    },
    saveChanges: 'Save',
  },

  expoCard: {
    cta: 'Enter Expo',
  },
  attachBrandToPanel: {
    title: 'Create a Panel',
    closeButton: 'Cancel',
    description: 'You create a panel by specifying the date and time. Please also specify the exhibitor who will hold the presentation here.',
    button: 'Create a Panel',
    language: {
      title: 'Language of this panel',
      label: 'Choose a language',
    },
    dateTime: {
      title: 'Select a Time',
      at: 'at',
      till: 'till',
    },
    brand: {
      title: 'Exhibitor',
      label: 'Choose an Exhibitor',
    },
  },
  panelCard: {
    menu: {
      edit: 'Edit',
      delete: 'Remove',
    },
    deleteConfirmation: {
      title: 'Remove Panel',
      inputLabel: 'Panel',
      buttonText: 'Remove',
      description:
        'If you want to remove the panel <b> {name} </b>, please enter "{name}" and click on remove.',
      namePlaceholder: 'Placeholder',
    },
  },
  panelDetailsPage: {
    title: 'Panel Details',
    backButton: 'Back to Channel Overview',
    teaser: 'Teaser of Panel',
    at: 'at',
    till: 'till',
    preview: 'Preview Image:',
    streamKey: {
      title: 'Stream-Key:',
      emptyText: 'An error has occurred. No stream key could be created for you. Please contact your Virtrex support.',
      clickHere: 'Add',
    },
    serverLink: {
      title: 'Server-Link:',
      emptyText: 'An error has occurred. No server link could be created for you. Please contact your Virtrex support.',
      clickHere: 'Add',
    },
    speaker: {
      title: 'Speaker:',
    },
    actionBar: {
      editButton: 'Edit',
      actions: {
        title: 'Panel Video',
        uploadVideo: 'Upload Video',
        deleteVideo: 'Delete Video',
      },
    },
    products: {
      title: 'Products:',
      cta: 'Manage Products',
      defaultText:
        'Drag the products into the desired order in which you want them to be displayed.',
    },
    detachConfirmation: {
      title: 'Remove Product',
      buttonText: 'Remove',
      description: 'Are you sure you want to remove <b>{name}</b>?',
    },
    manageModal: {
      title: 'Manage Products',
      close: 'Cancel',
      description:
        'Drag the products into the desired order in which you want them to be displayed. Additionally, you can select which products from your product catalog you want to show.',
      attached: 'Attached Products:',
      available: 'Other Products:',
      button: 'Save Changes',
    },
  },

  editPanelModal: {
    title: 'Edit Panel',
    closeButton: 'Cancel',
    description: 'No description added yet.',
    button: 'Edit',
    generalInformation: {
      title: 'General Information:',
      label: {
        name: 'Name of Panel',
        description: 'Description of Panel',
        teaser: 'Teaser of Panel',
        at: 'at',
        till: 'till',
      },
    },

    speaker: {
      title: 'Speaker Information:',
      label: {
        name: 'Name of Speaker',
        description: 'Additional Information about the Speaker',
      },
    },

    language: {
      title: 'Language of this panel:',
      label: 'Choose a language',
    },
  },


  createPanelModal: {
    title: 'Create Panel',
    closeButton: 'Cancel',
    description: 'You create a new panel by setting the date and time and specifying the speaker of the talk.',
    button: 'Create Panel',
    generalInformation: {
      title: 'General Information:',
      label: {
        name: 'Name of Panel',
        description: 'Description of Panel',
        at: 'at',
        till: 'till',
      },
    },

    speaker: {
      title: 'Speaker Information:',
      label: {
        name: 'Name of Speaker',
        description: 'Additional Information about the Speaker',
      },
    },

    previewImage: {
      title: 'Upload Preview Image:',
      description: 'Upload a preview image for your product.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },

    language: {
      title: 'Language of this panel:',
      label: 'Choose a language',
    },
  },

  editStageModal: {
    title: 'Channel Settings',
    closeButton: 'Cancel',
    description: 'You can distribute the panels over several channels, so that streams can also take place in parallel.',
    button: 'Add Channel',
    label: {
      name: 'Name of Channel',
    },
    uploadImage: {
      title: 'Channel Image preview',
      description: 'Add a channel image that will be displayed to visitors.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },
  },

  stageDetailsPage: {
    title: 'Channel Settings',
    backButton: 'Back to Event',
    agenda: {
      title: 'Channel Program',
      createPanel: 'Create Panel',
    },
    actionBar: {
      editButton: 'Edit Channel',
      delete: {
        title: 'Remove Channel',
        button: 'Remove Channel',
      },
    },

    deleteConfirmation: {
      title: 'Delete Channel',
      inputLabel: 'Enter here',
      description: 'If you want to delete the channel, please enter "{confirmationText}" and click delete',
      confirmationText: 'Delete channel',
      buttonText: 'Delete',
    },
  },
  createStageModal: {
    title: 'Create Channel',
    closeButton: 'Cancel',
    description: 'You can distribute the panels over several channels, so that panels can also take place in parallel.',
    button: 'Create Channel',
    label: {
      name: 'Name of Channel',
    },
    uploadImage: {
      title: 'Channel Image Preview',
      description: 'Add a channel image that will be displayed to visitors.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },
  },

  eventDetailsPage: {
    title: 'Eventplaner',
    backButton: 'Back to Expo',
    imagePreviewTitle: 'Image Preview:',
    pageCta: {
      title: 'Create Channel',
      text: 'Start Now!',
    },
    actionBar: {
      editButton: 'Edit Event',
      delete: {
        title: 'Delete Event',
        button: 'Delete Event',
      },
    },

    stages: {
      title: 'Channels:',
      description:
        'Channel Overview',
      cta: 'Add Channel',
    },

    deleteConfirmation: {
      title: 'Delete Event',
      inputLabel: 'Please enter here',
      description: 'If you want to delete the event, please enter "{confirmationText}" and click "Delete Event"',
      confirmationText: 'Delete Event',
      buttonText: 'Delete',
    },
  },

  expoDemo: {
    message: {
      noLicence: {
        title: 'No Licence',
        description: 'To publish an Expo, you need to purchase a licence. Please contact our customer management to receive a personalized offer that suits your company best.',
        button: 'Purchase Licence',
      },

      noBrandInvite: {
        title: 'Information',
        description: 'You have invited <b>{inviteCount} out of {inviteTotal}</b> available exhibitors to your Expo. If you would like to expand your quota, please contact our account managers.',
        button: 'Purchase Licence',
      },
    },

    tooltip: {
      brandInviteGuests: 'Guests can not be invited yet. Please contact your organizer.',
      ownerInviteGuests: 'Active licence required to invite guests.',
      publishExpo: 'Active licence required to publish an Expo.',
    },
  },

  expoLicenceModal: {
    button: 'Proceed with Demo Version',

    request: {
      title: 'Licence Information',
      description: 'To publish an exposition and take advantage of all functionalities on Virtrex, you need to purchase a licence. If you do not want to buy a plan immediately, we invite you to continue with our demo version, where you can have a look around and start planning your exhibition.',
      buttonPrimaryText: 'Purchase Licence',
      buttonSecondaryText: 'Contact our account managers to purchase a licence for this expo.',
    },

    success: {
      title: 'Your licence request was received',
      description: 'Thank you for your request. Our customer management will get in contact with you to define all details and make sure you are good to go. If you have further questions please contact our customer management directly any time at support@virtrex.de.',
    },
  },

  exposPage: {
    title: 'All Expos',
    create: 'Create Expo',
    delete: 'Remove Expo',
    multipleExposSelected: '{count} Expos selected',
    deleteConfirmation: {
      title: 'Remove Expo',
      inputLabel: 'Name of the Expo',
      buttonText: 'Remove',
      description: {
        single:
          'If you want to remove the expo <b> {name} </b>, please enter "{name}" and click on remove.',
        multiple:
          'If you want to remove {count} Expos, please enter "{text}" and click on remove.',
      },
      validationText: 'Remove {count} Expos',
    },
  },

  expoSettingsPage: {
    title: 'Settings',
    goBack: 'Back to the Expo',
    visibility: {
      title: 'Expo Accessibility',
      description:
        'Decide whether your Expo should be open to public or whether guests must be invited to participate in the Expo.',
    },
    language: {
      title: 'Languages',
      description: 'Please select the languages you would like to offer your visitors at the Expo.',
      subtext: 'Supported languages:',
      warning: {
        description: 'With "Delete Language(s)" the translations for the Expo title and description will be removed. This action is irreversible.',
      },
    },
    headerImage: {
      title: 'Upload Cover Picture',
      description:
        'Please upload a cover picture, this image will be the cover for your expo landing page',
      note: 'Please upload the file as .png or .svg.',
    },

    logo: {
      title: 'Upload Logo',
      description:
        'Upload your company logo to enhance your brand presence at the Expo.',
      note: 'Please upload the file as .png or .svg.',
    },

    primaryColor: {
      title: 'Select Primary Color',
      description:
        'Select the primary color of your Expo, this will be used in banners and in all important buttons.',
      note:
        'For best results, choose a color that customers associate with your brand.',
    },

    general: {
      title: 'Title and Description',
      expoNameLabel: 'Title of the Expo',
      expoDescriptionLabel: 'Description of the Expo',
    },
  },

  createExpoModal: {
    title: 'Expo Creation',
    closeButton: 'Close',
    type: {
      title: 'Type of expo',
      navigationTitle: 'Type',
      label: 'Select the type of Expo',
      singleBrand: 'Single-Brand Expo',
      multiBrand: 'Multi-Brand Expo',
    },
    languages: {
      navigationTitle: 'Languages',
    },
    titleAndDescription: {
      title: 'Title and Description of the Expo',
      navigationTitle: 'Title and Description of the Expo',
      description: 'Title and description will be used automatically on the Expo, as well as in the invitations.',
      nameLabel: 'Title of the Expo',
      descriptionLabel: 'Expo description',
    },
    defaultLanguage: {
      description: 'This language is automatically used if visitors do not select a language.',
      label: 'Please choose a default language for your Expo',
      title: 'Default Language',
    },
    visibility: {
      title: 'Expo Accessibility',
      description:
        'Decide whether your Expo should be open to public or whether guests must be invited to participate in the Expo.',
    },
    error: {
      noLanguageSelected: 'Please select a language to proceed',
      missingRequiredData: 'Please add a title and a description for your Expo in each language',
    },
    nextButton: 'Continue',
    submitButton: 'Create Expo',
  },

  expoIsPublicOptionList: {
    public: 'Public Expo - access with link',
    private: 'Private Expo - access only with invite',
  },

  associatesPage: {
    title: 'All Associates:', //! need to check
    inviteButton: 'Invite Associate',
    multipleElementsSelected: '{count} elements selected',
    delete: 'Delete Associate',
    invites: {
      title: 'Pending Invitations:', //!need to check
      pendingLabel: 'Invitation is pending', //!need to check
    },
    deleteConfirmation: {
      title: 'Delete Associate',
      inputLabel: 'Please enter here',
      buttonText: 'Delete Associate',
      description: {
        single:
          'Are you sure you want to delete <b>{name}</b>? If so please type "{name}" in input and click on delete.',
        multiple:
          'Are you sure you want to remove {count} elements? If so please type "{text}" and click on delete.',
      },
      validationText: 'Delete {count} elements',
    },
  },

  confirmationModal: {
    closeButton: 'Cancel',
    goBackText: 'Back',
  },

  inviteAssociateModal: {
    title: 'Invite Associate',
    closeButton: 'Close',
    description: 'Invite associates to support your customers within your exhibition. Separate multiple emails using a comma, or the enter key.',
    button: 'Invite Associate',
    generalInformation: {
      title: 'General Information:',
      label: {
        email: 'Associate e-mail address',
      },
    },
    legal: {
      title: 'Imprint & Privacy Policy Settings',
      imprint: 'Imprint',
      privacyPolicy: 'Privacy Policy',
    },
    saveChanges: 'Save changes',
  },

  brandSwitcher: {
    button: 'Enter Dashboard',
    title: 'Welcome to your Virtrex.',
    description:
      'Manage your exhibitor page, product information and contact options here.',
  },

  userProfilePage: {
    title: 'Profile Details',
    logout: 'Logout',
    edit: 'Edit Profile',
  },

  associatePage: {
    title: 'Profile Settings',
    backButton: 'Back to Overview',
    edit: 'Edit Profile',
    permissionManagement: 'Permission Management',
    permissionCallout: {
      text: 'In order to manage the specific permissions of a role, please go to settings.',
      link: 'Edit Permissions',
    },
  },

  editAssociateModal: {
    title: 'Edit Associate',
    closeButton: 'Close',
    description: 'Associates support your customers within your exhibition.',
    generalInformation: {
      title: 'General Information:',
      label: {
        firstName: 'First name',
        lastName: 'Last name',
        position: 'Position',
        email: 'E-mail address',
        preferredLocale: 'Please choose your preferred language.',
      },
    },
    avatar: {
      title: 'Profile Picture:',
      description:
        'Add a profile picture that will be displayed to visitors.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.', //! missed
    },
    button: 'Save Changes',
  },

  userDetailsModal: {
    title: 'Edit Profile',
    closeButton: 'Cancel',
    description:
      'Please complete your profile, the information entered here will be displayed to visitors.',
    generalInformation: {
      title: 'General Information:',
      label: {
        firstName: 'First name',
        lastName: 'Last name',
        position: 'Title',
        email: 'E-mail address',
        preferredLocale: 'Please choose your preferred language.',
      },
    },
    avatar: {
      title: 'Profile picture:',
      description:
        'Add a profile picture which will be shown to the visitors.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },
    password: {
      button: 'Change password',
      label: 'Enter your new password',
    },
    button: 'Save Changes',
  },

  contactsPage: {
    title: 'All Contacts',
    createButton: 'Add Contacts',
    delete: 'Delete Contact',
    multipleContactsSelected: '{count} selected contacts',

    deleteConfirmation: {
      title: 'Delete Contact',
      inputLabel: 'Please enter here',
      buttonText: 'Delete Contact',
      description: {
        single:
          'If you want to delete the contact <b> {name} </b>, please enter "{name}" and click on delete.',
        multiple:
          'If you want to remove the {count} contacts, please enter "{text}" and click on delete.',
      },
      validationText: 'Remove {count} contacts',
    },
  },

  createEventModal: {
    title: 'Create Event',
    closeButton: 'Cancel',
    description:
      'Here you can create an event. The information entered will be made available to your visitors.',
    label: {
      name: 'Name of the Event',
      description: 'Description of the Event',
      startDate: 'Select the start date',
      endDate: 'Select the end date',
    },
    button: 'Create Event',

    uploadImage: {
      title: 'Event Image preview:',
      description:
        'Add an event picture which will be shown to the visitors.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },
  },
  createPackageModal: {
    title: 'Create Package',
    buttonClose: 'Close',
    description: 'Create license packages for your exhibitors at the Expo. If you leave a limit blank, it will automatically be set to unlimited. It is up to you how many packages you create.',
    information: 'General Information',
    placeholderName: 'Name of Package',
    placeholderPrice: 'Price',
    placeholderSelectSize: 'Select the size of the display tile',
    selectTitle: 'Size of display tile',
    S: 'Small',
    M: 'Medium',
    L: 'Large',
    selectSizeNote: 'Determines how large and prominent the logo tile of this exhibitor is displayed',
    limitation: 'Limitation of Features',
    placeholderMaxProducts: 'Maximum number of products',
    placeholderMaxShowrooms: 'Maximum number of showrooms',
    placeholderMaxVip: 'Maximum number of VIP visitor invitations',
    buttonSubmit: 'Create Package',
  },
  editPackageModal: {
    title: 'Edit License Packages',
    buttonClose: 'Close',
    description: 'If you leave a limit blank, it will automatically be set to unlimited. It is up to you how many packages you create.',
    information: 'Overall Information',
    placeholderName: 'Name of Package',
    placeholderPrice: 'Price',
    placeholderSelectSize: 'Select the size of the display tile',
    selectTitle: 'Size of display tile',
    S: 'Small',
    M: 'Medium',
    L: 'Large',
    selectSizeNote: 'Determines how large and prominent the logo tile of this exhibitor is displayed',
    limitation: 'Limitation of Features',
    placeholderMaxProducts: 'Maximum number of products',
    placeholderMaxShowrooms: 'Maximum number of showrooms',
    placeholderMaxVip: 'Maximum number of VIP visitor invitations',
    buttonSubmit: 'Save',
  },
  deletePackageModal: {
    title: 'Delete Package',
    inputLabel: 'Please enter here',
    description: 'If you want to delete the stage, please enter "{confirmationText}" and click Delete',
    buttonText: 'Delete',
  },
  selectBrandPackage: {
    placeholder: 'Select package',
  },
  editTagModal: {
    title: {
      add: 'Add category',
      edit: 'Edit category',
    },
    closeButton: 'Close',
    description: 'As an organizer of an Expo, you can create an unlimited number of theme filters for this Expo. The theme filters are used by the invited exhibitor to categorize both their brand page and their products for this Expo independently. Using the categories, visitors can filter topics.',
    cardTitle: 'Categories of Expo',
    cardDescription: 'Please remember to enter the categories in the desired order, as this cannot be edited afterwards. Once an exhibitor has been assigned, you can no longer remove the category.',
    cardNote: 'The entry is made by separating the individual categories with a comma or by pressing the Enter key.',
    placeholder: 'Category title',
    button: 'Save',
  },
  selectBrandTagModal: {
    title: 'Manage categories',
    closeButton: 'Cancel',
    description: 'Please select the categories under which you want visitors to the Expo to find you. You can assign up to four categories to your brand.',
    cardTitle: 'Brand categories',
    placeholder: 'Choose category',
    button: 'Save',
  },
  selectProductTagModal: {
    placeholder: 'Add tag',
  },
  editEventModal: {
    title: 'Edit Event',
    closeButton: 'Cancel',
    description:
      'Here you can edit an event. The information entered will be made available to your visitors.',
    label: {
      name: 'Name of the Event',
      description: 'Description of the Event',
      startDate: 'Select the start date',
      endDate: 'Select the end date',
    },
    button: 'Edit Event',

    uploadImage: {
      title: 'Event Image preview:',
      description: 'Add an event picture which will be shown to the visitors.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },
  },

  contactCreationModal: {
    modeSelection: {
      title: 'Add Contacts',
      closeButton: 'Cancel',
      legalConfirmation: 'I hereby confirm that all addresses used have been acquired within existing legal requirements.',
      description:
        'Add customers to Virtrex to create a digital database of all potential customers to invite to your Expo. The customer data can also be used to provide you with statistics about your Expo and to improve your sales strategies. We offer you different options for uploading your customer data. ',
      options: {
        import: {
          title: 'Import Excel file',
          description: 'Adding contacts by importing a excel file',
        },
        creation: {
          title: 'Add Contacts Directly',
          description: 'Add contacts manually, one at a time.',
        },
      },
    },
    upload: {
      title: 'Import contacts via Excel',
      closeButton: 'Cancel',
      goBack: 'Back',
      description:
        'Please follow the structure given in the excel file for a successful and correct upload of your contacts. Please use the excel file provided below.',
      csv: {
        title: 'Upload Excel file:',
        description:
  'Please follow the upload instructions and use the supplied excel.',
        note: 'Here you find a excel template for uploading your contacts, please use this template for a successful contact upload.',
        link: 'Download Template',
      },
      button: 'Import Contacts',
    },

    create: {
      title: 'Add Contacts',
      closeButton: 'Close',
      goBack: 'Back',
      description:
        'Edit the contact information to have a complete database of potential customers. Please provide this information as accurately as possible, as the data may be used for analysis purposes.',
      button: 'Add Contacts',
      label: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'E-mail address of contact',
        company: 'Company name of the contact (optional)',
        associate: 'Responsible sales manager (optional)',
        other: 'Others (optional)',
      },
    },
  },

  contactDetailsPage: {
    title: 'Contact Details',
    associateTitle: 'Responsible Sales Manager',
    backButton: 'Back to Overview',
    editButton: 'Edit Contact',
  },

  leadDetailPage: {
    title: 'Profile Details',
    logout: 'Log out',
    statusOnline: 'Online',
    statusOffline: 'Offline',
    contactState: 'Current contact state',
    exhibitionFolder: 'Customer\'s saved items',
    emptyMessemappeList: 'No products found',
    contactHistory: 'Contact history',
    currentlyViewing: 'Currently viewing',
    currentlyVisit: {
      currentlyViewing: 'Currently viewing',
      product: 'Product',
      brand: 'Brand',
      showroom: 'Showroom',
      panel: 'Panel',
      event: 'Event',
      title: '{type} page: {name}',
      openedAt: 'Opened at:',
      duration: 'Duration:',
    },
    visitHistory: {
      title: 'Visit history',
      emptyDataText: 'No visit history found',
      duration: 'Duration',
      filters: 'Filters',
      type: {
        product: 'Product',
        brand: 'Brand',
        showroom: 'Showroom',
        panel: 'Panel',
      },
      filterNames: {
        product: 'Product pages',
        showroom: 'Showrooms',
        brand: 'Brand page',
      },
      viewFullVisitList: 'View full visit history',
      modal: {
        title: 'Visit history',
      },
    },
    leadInfo: {
      expo: 'Expo',
      company: 'Company',
      role: 'Role',
      mail: 'Mail',
      phone: 'Phone',
      mobilePhone: 'Mobile Phone',
    },
    actionBar: {
      createCall: 'Create a new call',
    },
    interestList: {
      title: 'Interests',
      emptyDataText: 'No tags found',
    },
  },

  editContactModal: {
    title: 'Edit Contact',
    closeButton: 'Cancel',
    goBack: 'Back',
    description:
  'Edit the contact information to have a complete database of potential customers. Please provide this information as accurately as possible, as the data may be used for analysis purposes.',
    button: 'Save Changes',
    label: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail address of contact',
      company: 'Company name of the contact (optional)',
      associate: 'Responsible sales manager (optional)',
      other: 'Others (optional)',
    },
  },

  expoDetailsPage: {
    title: 'Expo Settings',
    languages: 'Languages',

    visitors: {
      title: '{count} invited guests',
      text: 'Invite Guests',
      disabled: 'Information',

      modal: {
        manage: {
          title: 'Invite Contacts',
          close: 'Cancel',
          description: 'Select the contacts you would like to invite to your expo and click on "Invite Contacts". Your selected contacts will then receive an invitation by email. In order to invite contacts to your Expo, please add them to your {link} first.',
          descriptionLink: 'contact list',
          attached: 'Invited Guests',
          available: 'Available Contacts',
          buttonInvite: 'Invite Contacts',
          buttonSave: 'Save Changes',
          buttonSchedule: 'Schedule Invite E-mail',
        },
        scheduling: {
          title: 'Schedule Invite E-mail',
          sendNow: 'Send invitation e-mails right away',
          sendLater: 'Schedule a time when invitation e-mails are sent',
          sendWhenScheduled: 'Send invitation e-mails on the currently scheduled date',
        },
        detachConfirmation: {
          title: 'Remove Contact',
          buttonText: 'Remove Contact',
          description: 'Would you like to remove the contact <b> {name} </b>?',
        },
      },
    },

    preview: {
      title: 'Website teaser for your Expo:',
      description: {
        empty: 'Set up a website that can be accessed by interested parties before the start of your Expo. There they will find a countdown and can sign up to be notified when the Expo starts.',
        filled: 'You have activated the notification for the start of your Expo. This means that all registered visitors will be notified at the start of the Expo.',
      },
      startTime: {
        title: 'Start time of the Expo',
        button: {
          empty: 'Add Time',
          filled: 'Change Start Dates',
        },
        description: 'Please add the start date and time of your Expo so that visitors receive a notification in time.',
        at: 'at',
      },
      numberOfVisitors: {
        title: 'Number of visitors who signed up for the notification',
        count: '{count} guest | {count} guests',
      },
      modal: {
        title: 'Start time of the expo',
        close: 'Cancel',
        description: 'To set up the countdown, set the start date and time of your Expo. At this time, an email will be sent to all registered visitors to notify them of the start of the Expo.',
        datepicker: {
          label: 'Select a date',
        },
        at: 'at',
        saveButton: 'Save Details',
        warning: {
          title: 'Note',
          description: 'Are you sure you want to reschedule sending the notifications? You already have {visitorCount} registered for the notification. If you change the start date, the notifications will be moved accordingly.',
          count: '{count} visitor | {count} visitors',
        },
        reschedule: {
          title: 'Reschedule start time',
          button: 'Delete start time',
        },
      },
    },

    brands: {
      title: 'Invite Exhibitors:',
      cta: {
        invite: 'Add Exhibitor',
        manage: 'Manage Exhibitors',
      },
      defaultText: {
        empty: 'You haven\'t added any exhibitors yet.',
        filled:
      'In this section you can add and manage exhibitors who are invited to your expo and the corresponding events. If you have created license packages for your Expo, you can assign them to your exhibitors here.',
      },
      modal: {
        manage: {
          title: 'Exhibitor',
          close: 'Cancel',
          description:
        'Manage the exhibitors at your Expo by clicking on "Invite New Exhibitors". You can also use drag and drop to manage the order in which the exhibitors appear at the expo. Until an exhibitor accepts the invitation, the status "Invitation pending" is displayed. ',
          brandsTitle: 'Invited Exhibitors:',
          button: 'Invite New Exhibitors',
          pendingInvitesTitle: 'Invitation Pending',
        },
        detachConfirmation: {
          title: 'Remove Exhibitor',
          inputLabel: 'Please enter here',
          buttonText: 'Remove Exhibitor',
          description:
        'If you want to remove the exhibitor <b> {name} </b>, please enter "{name}" and click on remove.',
        },
        cancelInviteConfirmation: {
          title: 'Withdraw invitation',
          inputLabel: 'Please insert <b> {name} </b>',
          buttonText: 'Cancel Invitation',
          description:
        'If you want to withdraw <b> {name} </b>\'s invitation, please type in "{name}" and click on cancel.',
        },
        invite: {
          title: 'Invite Exhibitors',
          closeButton: 'Cancel',
          description:
        'If you want to invite a brand, you must enter the company name and a contact person. After you have invited the person, he/she will receive an invitation by e-mail, including an acceptens.',
          generalInformation: {
            title: 'General Information:',
            label: {
              email: 'E-mail address',
              brandName: 'Brand Name',
            },
          },
          button: 'Invite Exhibitors',
        },
      },
    },

    email: {
      title: 'Invite E-mail:',
      description: 'If you want to schedule the sending of the invitations to this Expo to a day, you can specify a date for sending the e-mail under "Choose date". If you do not make any setting, the invitations will be sent directly after the selection of the contacts.',
      expoStarted: 'Your Expo, {name}, has started. You can no longer schedule invite e-mails. To invite more guests, please visit the "Invite Guests" section below.',
      scheduling: {
        title: 'Sending the Invite',
        description: 'Please schedule a date to send out the invitations via e-mail to your guests.',
        confirmation: 'Your invitations are scheduled to be e-mailed on <b>{date}</b>',
        buttonUnscheduled: 'Choose Date',
        buttonScheduled: 'Update Date',
        modal: {
          title: 'Schedule Invite E-mail',
          subtitle: 'Reschedule E-mail',
          description: 'Here you can schedule an invitation e-mail. The invitation e-mail will be sent out to your guests on the date you choose below.',
          invites: '<b>{count} guest(s) will be invited</b>',
          selectDate: 'Select a date',
          cancelButton: 'Cancel Invitation',
          cancelTitle: 'Cancel Invite E-mail',
          cancelText: 'By choosing to cancel, your guests will no longer receive an invitation e-mail for <b>{expo} on {date}</b>.',
          cancelSubmit: 'Confirm cancelation',
        },
      },
      branding: {
        title: 'Branding of the Invite',
        description: 'In your brand settings, you can select a brand color and upload your logo to apply to the invitation.',
        button: 'Brand Settings',
      },
    },

    events: {
      title: 'Events:',
      cta: 'Create Event',
      text: 'In the events section you can create and manage your event. Create an event by clicking the "Create Event" button. You can go into an event by clicking on the event field it. ',
      defaultText: 'Drag the events into the order you want them to be displayed in.',
    },

    packages: {
      title: 'Manage Packages',
      cta: 'Add package',
      defaultText: 'Here you can create the license packages for your Expo. Based on these packages, the exhibitors you invite will receive their accesses to the Expo.',
      settingEdit: 'Edit package',
      settingDelete: 'Delete package',
    },

    categories: {
      select: {
        title: 'Brand Categories',
        cta: 'Choose category',
        text: 'Here you will find all the categories that you have selected to find your brand. If you want to add or remove a category, please click on "Choose category".',
      },
      manage: {
        title: 'Expo Categories',
        cta: 'Add category',
        text: 'Here you can find all created categories of your Expo. If you want to add, edit or remove a category, please click on "Manage category".',
        actions: {
          edit: 'Edit category',
          delete: 'Delete category',
        },
      },
    },

    products: {
      title: 'Products:',
      cta: 'Manage Products',
      defaultText: 'Drag the products in the order you want them to be displayed and add up to four tags to each product.',
      modal: {
        manage: {
          title: 'Manage Products',
          close: 'Cancel',
          description:
        'In this section you add products to your Expo, these products will automatically appear in the events you are attending and on your exhibitor page.',
          attached: 'Active Products:',
          available: 'Inactive Products:',
          button: 'Save Changes',
        },
        detachConfirmation: {
          title: 'Remove Product',
          buttonText: 'Remove',
          description: 'Are you sure you want to remove the product <b> {name} </b>?',
        },
      },
      tagsSelected: 'Tags selected',
      noTagsSelected: 'No tags selected',
    },

    showrooms: {
      title: 'Showrooms:',
      cta: 'Manage Showrooms',
      defaultText: 'Drag the showrooms to the desired order in which you want them to be displayed.',
      modal: {
        manage: {
          title: 'Manage Showrooms',
          close: 'Cancel',
          description:
        'Select the showrooms that you want to add to your Expo.',
          attached: 'Added Showrooms:',
          available: 'Existing Showrooms:',
          button: 'Save Changes',
        },
        detachConfirmation: {
          title: 'Remove Showroom',
          buttonText: 'Remove',
          description: 'Are you sure you want to remove the showroom <b> {name} </b>?',
        },
      },
    },

    associates: {
      title: 'Associates:',
      cta: 'Manage Associates',
      defaultText:
        'In this section, you can attach associates for your Expo. The associates attached here will appear as contact persons for visitors in your expo, if they have the rights to see the "Sales"part.',
      modal: {
        manage: {
          title: 'Manage Associates',
          close: 'Close',
          description:
            'Select the associates who take part in the Expo. The selected associates can be contacted by visitors during your Expo.',
          attached: 'Attached Associates',
          available: 'Available Associates',
          empty: 'You have not attached any associates yet.',
          button: 'Attach Associate',
        },
        detachConfirmation: {
          title: 'Detach Associate',
          buttonText: 'Detach Associate',
          description: 'Are you sure you want to detach <b>{name}</b>?',
        },
      },
    },

    apps: {
      title: 'Apps:',
      cta: 'Manage Apps',
      defaultText: 'Here you can select and attach visitor apps for your Expo.',
      modal: {
        manage: {
          title: 'Manage Apps',
          close: 'Close',
          description: 'Select the visitor apps you would like to make available to your guests at your Expo.',
          attached: 'Added Visitor Apps:',
          available: 'Available Visitor Apps:',
          button: 'Save Changes',
        },
        detachConfirmation: {
          title: 'Detach App',
          description: 'Are you sure you want to detach <b>{title}</b>?',
          buttonText: 'Detach App',
        },
      },
    },

    actionBar: {
      cta: 'Open Expo Preview',
      actions: {
        title: 'Manage Expo',
        brands: 'Manage Exhibitors',
        products: 'Add Products',
        events: 'Create Event',
        showrooms: 'Link Showroom',
        associates: 'Manage Team',
        settings: 'Expo Settings',
        apps: 'Expo Applications',
        activate: 'Expo Published',
        deactivate: 'Expo Not Published',
        licence: 'Purchase Licence',
      },
    },
  },
  auth: {
    login: {
      title: 'Login',
      description: 'Use your credentials to login. If you have forgotten your password, please click on Forgot Password. ',
      email: {
        label: 'Your E-mail address',
        placeholder: 'Your E-mail address',
      },
      password: {
        label: 'Your Password',
        placeholder: 'Your Password',
      },
      resetPassButton: 'Forgot Password',
      submitButton: 'Login now',
    },

    welcome: {
      title: 'Welcome!',
      description:
    'To use Virtrex you need to create an account. Click the button below to register your account ',
      link: 'Let\'s get started!',
    },

    registration: {
      title: 'Account Creation',
      firstName: {
        label: 'Your first name',
        placeholder: 'Your First name',
      },
      lastName: {
        label: 'Your last name',
        placeholder: 'Your Last name',
      },
      description: 'Fill in all of the following fields to register your account',
      email: {
        label: 'Your E-mail address',
        placeholder: 'Your E-mail address',
      },
      position: {
        label: 'Specify position',
        placeholder: 'Specify position',
      },
      preferredLocale:'Please choose your preferred language.',
      password: {
        label: 'Your password',
        placeholder: 'Your password (at least 8 characters)',
      },
      passwordConfirmation:  {
        label: 'Repeat your password',
        placeholder: 'Repeat your password',
      },
      submitButton: 'Create Account',
      accept: {
        text: 'I have read and accept the {0}.',
        linkText: 'Privacy Policy',
      },

      actionBarLink: 'I already have an account',
    },

    forgot: {
      title: 'Request a new password',
      description:
    'If you have forgotten your password, we will be happy to send you a link to reset your current password. Please enter the e-mail address you used to register for Virtrex and click on "Reset password". ',
      email: 'Your E-mail address',
      submitButton: 'Reset Password',

      titleConfirm: 'We have sent you an E-mail!',
      descriptionConfirm:
    'If your e-mail account is registered with us, you will find a link to reset your password in your mailbox. Please also check your spam folder for our e-mail.',
      actionConfirm: 'Back to the start page',
    },

    reset: {
      title: 'Reset Password',
      description:
    'If you have forgotten your password, we will be happy to send you a link to reset your current password. Please enter the e-mail address with which you registered for Virtrex and click on “Reset password”. ',
      password: 'New password (at least 8 characters)',
      passwordConfirmation: 'Repeat password',
      submitBtn: 'Reset Password',

      titleConfirm: 'Your password has been successfully reset',
      descriptionConfirm:
    'Your password has been successfully reset. Please return to the homepage and login',
      actionConfirm: 'Back to Login',

      actionBarLink: 'I found my password',
    },
  },
  showroomsPage: {
    title: 'All Showrooms',
    edit: 'Manage Team',
    createButton: 'Create Showroom',
    delete: 'Delete Showroom',

    multipleShowroomSelected: '{count} selected showrooms',

    deleteConfirmation: {
      title: 'Delete Showroom',
      inputLabel: 'Please enter here',
      buttonText: 'Delete Showroom',
      description: {
        single:
      'If you want to delete the showroom <b> {name} </b>, please enter "{name}" and click on delete. Deleted showrooms cannot be restored.',
        multiple:
      'If you want to delete the {count} showrooms, please enter <b> "{text}" </b> and click on delete. Deleted showrooms cannot be restored.',
      },
      validationText: 'Remove {count} showrooms',
    },

    showroomCreationModal: {
      modeSelection: {
        title: 'Create Showroom',
        closeButton: 'Cancel',
        description:
      'Create your own 3D Showroom and add it to your brand. Choose a template:',
        list: {
          description: 'Select a Template:',
          button: 'Select Template',
        },
      },
      create: {
        title: 'Create Showroom',
        closeButton: 'Cancel',
        goBack: 'Back',
        description:
      'Fill in the following fields to add a 3D Showroom to your brand, you can attach it to your Expo afterwards.',
        button: 'Create Showroom',

        generalInformation: {
          title: 'General Information:',
          name: 'Showroom Name',
          description: 'Showroom Description',
        },

        previewImage: {
          title: 'Upload Preview Image:',
          description:
        'Upload a preview image for your showroom. ',
          note: 'Please upload the file as .jpeg, .HEIC or .png.',
        },

        previewBrandLogotype: {
          title: 'Branding Image (Optional):',
          description:
        'You can upload an image with a 2:1 ratio (e.g. 2000x1000px) that will be placed in the showroom. You can use a transparent background to create different shapes or proportions. ',
          note: 'Please upload the file as .jpeg, .HEIC or .png.',
        },
      },
    },

    editShowroomModal: {
      title: 'Edit Showroom',
      closeButton: 'Cancel',
      goBack: 'Back',
      description:
    'Fill in the fields below to add a 3D showroom. After you\'ve successfully added the showroom, you can attach it to your Expo.',
      button: 'Save Changes',
      generalInformation: {
        title: 'General Information:',
        name: 'Showroom Name',
        description: 'Showroom Description',
      },

      previewImage: {
        title: 'Upload Preview Image:',
        description:
      'Upload a preview image for your showroom.',
        note: 'Please upload the file as .jpeg, .HEIC or .png.',
      },
    },
  },

  showroomDetailsPage: {
    title: 'Edit Showroom',
    backButton: 'Back to Overview',

    preview: 'Preview:',
    edit: 'Edit showroom',
    scene: 'Scene',
    scenePreview: 'Scene {sceneOrder} preview:',
    scenePreviewHint: 'Hint: This is a 360°-image, so it looks distorted.',

    products: {
      addProducts: 'Add products:',
      viewProducts: 'View products:',
      addProductHint: 'Only products with a 3D file (.glb) can be added here',
      label: 'Put product at position {n} (optional)',
      none: 'None',
      settings: {
        title: 'Product Settings (optional):',
        description:
      'You can customize the placement of your 3D product.',

        variances: 'Variances',

        orientation: 'Orientation (in ˚)',
        orientationNote:
      'If the front view of your product is shifted, you can reposition it with this value.',

        farScale: 'Product size',
        farScaleNote:
      'If the sizing of your product is not displayed correct, you can enlarge or reduce it with this value.',

        zoomedScale: 'Product size at zoom',
        zoomedScaleNote:
      'Enlarge or reduce the size of the zoom, when the product is selected by a visitor.',

        button: 'Save Settings',
      },
    },
  },

  productsPage: {
    title: 'Products',
    createButton: 'Add Product',
    edit: 'Edit Product',
    delete: 'Delete Product',
    multipleProductsSelected: '{count} products selected',

    deleteConfirmation: {
      title: 'Delete Product',
      inputLabel: 'Please enter here',
      buttonText: 'Delete Product',
      description: {
        single:
      'If you want to remove the product <b> {name} </b>, please enter "{name}" and click on delete.',
        multiple:
      'If you want to delete the {count} products, please enter <b> "{text}" </b> and click on delete.',
      },
      validationText: 'Remove {count} products',
    },
  },

  productCreationModal: {
    title: 'Create a Product',
    close: 'Close',
    button: 'Create Product',
    description: 'Fill in the following input fields to add a product to your brand. When it is successfully uploaded, you can assign it to an Expo.',
    generalInformation: {
      title: 'General Information:',
      name: 'Product name',
      description: 'Product description',
      teaser: 'Teaser description',
      associate: 'Select Product Experts',
      associateLabel: 'Product Experts',
      tags: 'Add tags (optional)',
      note: 'Separate tags with a comma, or the Enter key.',
      linkAR: ' AR link',
    },
    previewImage: {
      name: 'Preview Image',
      title: 'Upload Preview Image:',
      description: 'Upload a preview image for your product.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },
    slider: {
      title: 'Upload Product Pictures:',
      description: 'Upload pictures of your product.',
    },

  },

  productVariantCreationModal: {
    title: 'Create a Product Variant for {productName}',
    close: 'Close',
    button: 'Create Product Variant',
    generalInformation: {
      title: 'General Information:',
      name: 'Variation name',
    },
    object3d: {
      title: 'Upload GLB file:',
      description: 'Upload the 3D model of your product.',
      note: 'Please upload the file as *.GLB.',
    },
    usdz: {
      title: 'Upload USDZ file (optional):',
      description: 'You will need a separate USDZ-file to enable Augmented Reality on iOS devices.',
      note: 'For best results, please upload the file as a *.USDZ.',
    },
    variantColor: {
      title: 'Choose variant color (optional):',
      description: 'Please choose a color for this product variant.',
      note: 'For best results please choose a color that represents this product variant.',
    },
  },

  uploadAttachmentCard: {
    empty: {
      title: 'Upload file',
    },
    uploaded: {
      placehoder: 'File name',
    },
  },

  productEdit: {
    button: 'Save Changes',
    description: 'Fill in the following input fields to add a product to your brand. When it is successfully uploaded, you can assign it to your Expo.',
    generalInformation: {
      title: 'General Information:',
      name: 'Product name',
      description: 'Product description',
      teaser: 'Teaser description',
      associate: 'Select Product Experts',
      tags: 'Add tags (optional)',
      note: 'Separate tags with a comma or the Enter key.',
      linkAR: ' AR link',
    },
    previewImage: {
      title: 'Upload Preview Image:',
      description: 'Upload a preview image for your product.',
      note: 'Please upload the file as .jpeg, .HEIC or .png.',
    },
    slider: {
      title: 'Upload Product Pictures:',
      description: 'Upload pictures of your product.',
    },
  },


  productVariantEditModal: {
    title: 'Product {productName} Variant {variantName} settings',
    close: 'Close',
    button: 'Save changes',
    generalInformation: {
      title: 'General Information:',
      name: 'Variation name',
    },
    object3d: {
      title: 'Upload GLB file:',
      description: 'Upload the 3D model of your product.',
      note: 'Please upload the file as *.GLB.',
    },
    usdz: {
      title: 'Upload USDZ file (optional):',
      description: 'You will need a separate USDZ-file to enable Augmented Reality on iOS devices.',
      note: 'For best results, please upload the file as a *.USDZ.',
    },
    variantColor: {
      title: 'Choose variant color:',
      description: 'Please choose a color for this product variant.',
      note: 'For best results please choose a color that represents this product variant.',
    },
  },

  productDetailsPage: {
    title: 'Product Details',
    backButton: 'Back to Overview',
    about: {
      name: 'Preview Image',
      preview: 'Preview Image:',
      variants: 'Product Variants',
      addVariant: 'Add next product variation',
    },

    panelCard: {
      file: 'file',
      uploadTime: 'Uploaded on {time}',
    },

    deleteConfirmation: {
      title: 'Delete Product Variant',
      inputLabel: 'Enter here',
      buttonText: 'Delete Product Variant',
      description: 'If you want to remove the product variant <b>{name}</b>, please enter "{name}" and click on delete.',
      validationText: 'Remove {name} variant',
    },

    documents: {
      title: 'Files:',
      description: 'Upload files to give users more information on your products.',
    },
  },

  wysiwyg: {
    embed: 'Would you like to include media?',
    embedWebsite: 'Would you like to include your website?',
    confirmation: 'Yes',

    messageYoutube: 'Do not worry, we will show your Youtube video, after you finish your editing',
    messageVimeo: 'Do not worry, we will show your Vimeo video, after you finish your editing',
    messageIframe: 'Do not worry, we will show your website, after you finish your editing',

    toolbar: {
      bold: 'Bold',
      bullets: 'List',
      byte: 'Byte',
      bytes: 'Bytes',
      captionPlaceholder: 'Add caption ...',
      code: 'Code',
      heading1: 'Title',
      indent: 'Enlarge Indentation',
      italic: 'Italic',
      link: 'Link',
      numbers: 'Enumeration',
      outdent: 'Reduce Indentation',
      quote: 'Quote',
      redo: 'Restore',
      remove: 'Remove',
      strike: 'Strikethrough',
      undo: 'Undo',
      unlink: 'Remove Link',
      url: 'Url',
      urlPlaceholder: 'Enter URL ...',
      GB: 'GB',
      KB: 'KB',
      MB: 'MB',
      PB: 'PB',
      TB: 'TB',
    },
  },

  brandInvitePage: {
    header: 'Welcome to the digital Virtrex Platform!',
    description: '{invitingBrandName} has invited you and your brand {invitedBrandName} to the Expo {expoName}. To get started, please choose one of the following options. <br> <br> <b> Log in and link a brand </b>: Choose this option if you and your brand already have an account. After logging in you will need to select your brand <br> <br> <b> Create a new account </b>: Choose this option if you and your brand do not already have a Virtrex account. ',
    options: {
      logIn: {
        title: 'Login and link brand',
        description: 'Register and add your brand if it already exists',
      },
      createNew: {
        title: 'Create new Account',
        description: 'Click here if you and your brand do not have a Virtrex account yet.',
      },
    },
  },

  layout: {
    manage: {
      backToHomePage: 'Back to Homepage',
    },
  },

  attachBrandToExpoPage: {
    header: 'Link Brand',
    description: 'Select your brand to associate with the Expo {expoName}. {invitingBrandName} has invited {invitedBrandName}. ',
    label: {
      selectBrand: 'Select Brand',
    },
    button: {
      help: 'Do you need help?',
      submit: 'Connect your brand with the Expo',
    },
  },

  salesLandingPage: {
    supportCard: {
      headline: 'Sales Calls',
      description: 'Schedule your Sales Calls or see who wants to contact you.',
      textLink: 'To Sales Calls',
    },
    leadsCard: {
      headline: 'Leads',
      description: 'See which visitors are on your pages and start a conversation.',
      textLink: 'To My Leads',
    },
  },

  // TODO: remove 'leadsPage'
  leadsPage: {
    goToLeadsPage: 'Back to leads',
    showMoreText: 'Show more ({count} remaining)',
    newLeads: {
      headline: 'Possible leads',
      description: 'Visitors that are currently viewing one of your pages, or have visited them at least once before, with whom you have not made contact yet will be displayed here as possible leads. You can address your potential customers by sending them a contact request.',
      showMoreText: 'View all leads',
      noItemsText: 'No leads yet',
    },
    incomingLeads: {
      headline: 'Incoming contact requests',
      description: 'Here you will find all visitors who have asked to be contacted.',
      showMoreText: 'View all incoming requests',
      noItemsText: 'No incoming contact requests yet',
    },
    outgoingLeads: {
      headline: 'Outgoing contact requests',
      description: 'Here you will find all visitors to whom you have already sent a contact request.',
      showMoreText: 'View all outgoing requests',
      noItemsText: 'No outgoing contact requests yet',
    },
    openLeads: {
      headline: 'Open conversations',
      description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
      showMoreText: 'View all open conversations',
    },
    lead: {
      statusOnline: 'Online',
      statusOffline: 'Offline',
      stateOnBrand: 'Brand Area',
      stateOnExpo: 'Expo',
      favorites: 'Saved Items',
      countOfProducts: '{count} Products',
      visits: 'Visits',
      countVisits: '{count} pages',
      timeVisits: '{time} total',
      details: 'Details',
      contactRequest: 'Contact request',
      requestSend: 'Request send',
      resendRequest: 'Resend request',
      requestLimitReached: 'Request limit reached',
      goToScheduleCalls: 'Sales Calls',
    },
    actionBar: {
      downloadAnalytics: 'Download Leads List',
    },
    filtersDropDown: {
      title: 'Filters',
      expo: 'Expo "{expoName}"',
      hideAnonymous: 'Hide anonymous users',
      hideOffline: 'Hide offline users',
    },
  },

  salesPages: {
    content: {
      potentialLeads: {
        title: 'Potential leads',
        noData: 'no potential leads',
        showAll: 'View all potential leads',
        showMore: 'Show more ({count} remaining)',
      },
      warning: {
        header: 'Missing availability',
        button: 'Set availability',
        body: 'No availability set. Please set availability in order to receive and make calls.',
      },
      openConversations: {
        title: 'Open conversations',
        noData: 'no open conversations',
        showAll: 'View all open conversations',
        showMore: 'Show more ({count} remaining)',
      },
      recentActivity: {
        title: 'Recent activity',
        noData: 'no recent activities',
        showMore: 'Show more ({count} remaining)',
        description: {
          missedCall: 'Missed call',
          declinedContactRequest: 'Request denied',
          newMessage: 'New message',
          newScheduledCall: 'Call scheduled',
          successfulCall: 'Successful Call',
          declinedCall: '{0} declined scheduled call',
          cancelCall: '{userName} cancelled the sales call "{callTitle}"',
        },
      },
      closestCall: {
        title: 'Scheduled calls',
        description: 'Scheduled calls',
        noData: 'No Scheduled calls',
        showAll: 'View all scheduled calls',
      },
      videoCalls: {
        buttonJoinCall: 'Join call',
        buttonAccept: 'Accept',
        buttonDecline: 'Decline',
        youAccepted: 'You accepted',
        youDeclined: 'You declined',
        invitedByVisitor: 'Invited by visitor',
        scheduled: {
          title: 'Scheduled calls',
          noData: 'No Scheduled calls',
        },
        past: {
          title: 'Past calls',
          noData: 'No Past calls',
        },
        statuses: {
          'pending': 'Pending',
          'declined': 'Declined',
          'inProgress': 'In progress',
          'cancelled': 'Cancelled',
          'finished': 'Finished',
        },
        dropDown: {
          title: 'Scheduled calls',
          edit: 'Edit',
          delete: 'Delete',
        },
      },
    },
    actionBar: {
      downloadAnalytics: 'Download Leads List',
      scheduleCallButton: 'Schedule new call',
      availability: 'Availability',
      recentActivity: {
        title: 'Recent activity',
        noData: 'no recent activities',
        description: {
          missedCall: 'Missed call',
          declinedContactRequest: 'Request denied',
          newMessage: 'New message',
          newScheduledCall: 'Call scheduled',
          successfulCall: 'Successful Call',
          declinedCall: '{0} declined scheduled call',
          cancelCall: 'Call cancelled',
        },
      },
    },
  },

  uploadPanelVideoModal: {
    title: 'Upload Video',
    closeButton: 'Cancel',
    description: 'Upload a video file so your visitors can watch the live stream again.',
    uploadButton: 'Select file',
    warning: {
      title: 'Important Notice',
      description: 'The upload can take some time if the internet connection is poor. Please make sure that you do not close this window until the upload has been completed and your computer does not go into stand-by in the meantime. ',
    },
  },

  contactHistory: {
    empty: 'No contact yet',
    directChat: 'Direct chat',
    contactRequest: {
      incoming: {
        message: 'Incoming request',
        description: 'By: {name}',
      },
      outgoing: {
        message: 'Outgoing request',
        description: 'By: {name}',
      },
    },
    scheduledVideoCall: {
      cancelled: {
        message: 'Call failed',
        description: 'No response',
      },
      finished: {
        message: 'Contacted',
        description: 'Employee: {name}',
      },
      scheduled: {
        message: 'Call scheduled',
        description: 'Scheduled at {datetime}',
      },
      in_progress: {
        message: 'In progress',
        description: 'Employee: {name}',
      },
      pending: {
        message: 'Pending',
        description: 'Employee: {name}',
      },
    },
  },

  contactState: {
    empty: 'No contact yet',
    incoming: {
      message: 'Incoming request',
      description: 'Wants call',
    },
    outgoing: {
      message: 'Outgoing request',
      description: 'Awaiting response from customer',
    },
  },

  chat: {
    title: 'Chat',
    removeConfirm: {
      cancel: 'Cancel',
      delete: 'Delete',
    },
  },

  downloadAnalyticsPage: {
    title: 'Insights',
    header: 'Download Analytics',
    description: 'To download your analytics you will need to select an expo and the time-frame',
    selectExpoLabel: 'Please select an expo',
    date: {
      title: 'Please Select a Time-frame',
      label: {
        to: 'to',
        start: 'Select the start date',
        end: 'Select the end date',
      },
    },
    brand: {
      title: 'Brand Report',
      description: 'Download your brand analytics ',
      button: 'Download Excel',
    },
    expo: {
      title: 'Expo Report',
      description: 'Download your expo analytics.',
      button: 'Download Excel',
    },
  },
  downloadLeadsListModal: {
    title: 'Download Leads List',
    closeButton: 'Cancel',
    description: 'To download your analytics you will need to select an expo and the time-frame',
    selectExpoLabel: 'Please select an expo',
    date: {
      title: 'Please Select a Time-frame',
      label: {
        to: 'to',
        start: 'Select the start date',
        end: 'Select the end date',
      },
    },
    downloadButton: 'Download Excel',
  },

  settingsPage: {
    brandSetupCard: {
      headline: 'Brand Setup',
      description: 'Edit your brand page as well as your logo and describe your brand.',
      textLink: 'Brand Setup',
    },
    teamManagementCard: {
      headline: 'Team Management',
      description: 'Invite your team members and assign roles and rights.',
      textLink: 'Team Management',
    },
    apiIntegrationCard: {
      headline: 'API Integration',
      description: 'Connect your own systems to Virtrex and manage the settings.',
      textLink: 'API Integration',
    },
    webhooksCard: {
      headline: 'Webhook Integration',
      description: 'Send information to your own systems with Virtrex and manage the settings.',
      textLink: 'Webhook Integration',
    },
  },

  teamManagementPage: {
    delete: 'Delete',
    edit: 'Edit',
    multipleElementsSelected: '{count} elements selected',
    inviteButton: 'Invite Associate',
    teamTable: {
      title: 'Team Management',
      cta: 'Invite Member',
      dropdownCTA: 'Create New Role',
      dropdownTitle: 'Edit Role',
    },
    roleTable: {
      title: 'Role Management',
      cta: 'Create New Role',
      editPermissions: 'Edit Permissions',
      viewPermissions: 'View Permissions',
      assignedTo: 'Assigned to {count} People',
    },
    invites: {
      title: 'Pending Invitations:',
      pendingLabel: 'Invitation is pending',
    },
    createRoleModal: {
      title: 'Create New Role',
      sections: {
        roleName: 'General Information',
        rolesList: 'Role Permissions',
      },
      roleNameInput: 'Name of Role',
      closeButton: 'Cancel',
      button: 'Create New Role',
    },
    editRoleModal: {
      title: 'Edit {name} Role',
      message: 'Please be aware that any changes made to the {name} role will affect the permissions all team members assigned to the role have',
      inputLabel: 'Name of Role',
    },
    viewRoleModal: {
      title: 'View {name} Role',
    },
    deleteConfirmation: {
      title: 'Delete Associate',
      inputLabel: 'Please enter here',
      buttonText: 'Delete Associate',
      description: {
        single:
          'Are you sure you want to delete <b>{name}</b>? If so please type "{name}" in input and click on delete.',
        multiple:
          'Are you sure you want to remove {count} elements? If so please type "{text}" and click on delete.',
      },
      validationText: 'I want to delete {count} elements',
      hasAssociateError: 'This action cannot be performed. Please remove yourself from the selection in order to proceed.',
    },
    deleteRoleConfirmation: {
      inputLabel: 'Please enter here',
      buttonText: 'Delete Role',
      description: 'Are you sure you want to delete <b>{name}</b>? If so please type "{name}" in input and click on delete.',
      hasAssociateError: '{name} cannot be deleted because it is associated with a team member.',
    },
  },

  alertModal: {
    closeButton: 'Cancel',
    goBackText: 'Back',
  },

  roles: {
    admin: 'Admin',
    member: 'Member',
  },

  permissions: {
    expos: {
      category: 'Expos',
      edit: 'Edit Expos',
      delete: 'Delete Expos',
      canModerateChat: 'Chat Moderation',
    },
    sales: {
      category: 'Sales',
      leads: 'Leads Overview',
      leadsSalesCalls: 'Manage Leads & Sales Calls',
      leadsReport: 'Download Leads Report',
    },
    products: {
      category: 'Products',
      edit: 'Create & Edit Products',
      delete: 'Delete Products',
    },
    showrooms: {
      category: 'Showrooms',
      edit: 'Edit Showrooms',
      delete: 'Delete Showrooms',
    },
    contacts: {
      category: 'Contacts',
      all: 'Create & Edit All Contacts',
      personal: 'Create & Edit Personal Contacts',
      delete: 'Delete Contacts',
    },
    settings: {
      category: 'Settings',
      editBrandSetup: 'Edit Brand Setup',
      inviteTeamMembers: 'Invite Team Members',
      editTeamMembers: 'Edit Team Members',
      deleteTeamMembers: 'Delete Team Members',
      editUserRoles: 'Edit User Roles',
      editRolePermissions: 'Edit Role Permissions',
      viewPayments: 'View Payment Settings',
      managePayments: 'Edit Payment Settings',
      editApiAndWebhooks: 'Edit API and Webhooks Settings',
      installApps: 'Manage Apps',
    },
    insights: {
      category: 'Insights',
      report: 'Download Report',
    },
  },

  broadcastProviderSetting: {
    title: 'Stream provider',

    provider: {
      label: 'Provider',
      placeholder: 'Select provider',
      virtrex: 'Virtrex',
      youtube: 'YouTube',
      twitch: 'Twitch',
      vimeo: 'Vimeo',
    },
    identifier: {
      youtube: 'Video ID',
      vimeo: 'Event ID',
      twitch: 'Channel Name',
    },
    chatType: {
      label:'Chat Option',
      placeholder: 'Select provider',
      twitch: 'Twitch chat',
      vimeo: 'Vimeo chat',
      virtrex: 'Virtrex chat (Default)',
    },
  },

  selectProviderModal: {
    title: 'Edit Stream Provider',
    closeButton: 'Close',
  },

  apiIntegration: {
    title: 'API Integration',
    description: 'To connect your external systems and applications to Virtrex, you can create and delete API tokens in this section. For more information, see our {documentationLink}.',
    documentationLinkText: 'API documentation',
    tokens: 'API Tokens',
    docsLink: 'Open API Documentation',
    createToken: 'Create New API Token',
    modal: {
      createToken: {
        label: 'Name of the API Token',
        placeholder: 'Enter text here',
      },
      copyToken: {
        label: 'Copy Token',
        description: 'Please click the icon of the overlapping files to the right of your API token to copy it.',
        feedback: 'Copied!',
      },
      cta: 'Create API Token',
    },
    deleteToken: {
      title: 'Delete Token',
      description: 'Are you sure you want to delete <b>{name}</b>? If so, please type {name} in the input and click on delete.',
      inputLabel: 'Please enter here',
      button: 'Delete Token',
    },
  },

  appsPage: {
    title: 'Apps',
    description: 'Here you see an overview of your installed Apps and you can install further Apps for your brand.',
    adminApps: 'Admin Apps',
    visitorApps: 'Visitor Apps',
    installButton: 'Install an App',
    card: {
      link: 'View App',
    },
    appModal: {
      install: {
        title: 'Install an app',
        description: 'Paste your JSON manifest in the field below.',
        button: 'Install',
      },
      edit: {
        title: 'Edit app',
        description: 'Update your JSON manifest in the field below.',
        button: 'Save Changes',
      },
      closeButton: 'Close',
      manifest: {
        title: 'example manifest',
        placeholder: 'JSON Manifest',
      },
      errors: {
        json: 'Please enter a valid configuration in JSON format.',
        missingRequiredProps: 'Your configuration is missing one of the following properties: title, icon, description or modules',
        missingModules: 'Your configuration must include at least one admin or visitor module.',
        unsupportedModule: 'Your configuration includes an unsupported app module type.',
      },
    },
    deleteApp: {
      title: 'Delete App',
      description: 'Are you sure you want to delete <b>{name}</b>? If so, please type {name} in the input and click on delete.',
      inputLabel: 'Please enter here',
      button: 'Delete App',
    },
  },

  thread: {
    message: {
      new: 'New',
    },
    textField: {
      placeholder: 'Type something...',
    },
  },

  recentActivity: {
    description: {
      missedCall: 'Missed call',
      declinedContactRequest: 'Request denied',
      newMessage: 'New message',
      newScheduledCall: 'Call scheduled',
      successfulCall: 'Successful Call',
    },
  },

  webhookEventOptions: {
    category: {
      visitor: 'Visitor Actions',
      brand: 'Brand',
    },
    visitor: {
      vipInviteAccepted: 'Visitor accepted invitation',
      firstLoggedIn: 'Visitor (first) Logged In',
      salesCallScheduled: 'Visitor Scheduled Call',
      productAddedToMessemappe: 'Product Added to Messemappe',
      leadCreated: 'Lead Created',
      salesCallOccurred: 'Sales Call Occurred',
    },
    brand: {
      expoInviteAccepted: 'Brand Accepted Invite to Expo',
    },
  },

  webhooksPage: {
    title: 'Webhook Integration',
    description: 'Send information to your own systems with Virtrex and manage the settings.',
    webhooks: 'Webhooks',
    createWebhook: 'Create New Webhook',
    isActiveField: 'Active',
    multipleSelection: '{count} elements selected',
    createWebhookModal: {
      title: 'Create Webhook',
      closeButton: 'Cancel',
      description: 'We\'ll send a POST request to the URL below with the details of any subscribed events. You will receive all data in JSON format.',
      isActiveField: 'Active',
      payloadUrlField: 'Payload URL',
      secretField: 'Secret',
      cta: 'Create Webhook',
      examplePayload: 'Example payload',
      update: {
        cta: 'Edit Webhook',
        title: 'Edit Webhook',
      },
    },
    deleteWebhookModal: {
      title: 'Delete Webhook',
      inputLabel: 'Please enter here',
      buttonText: 'Delete Webhook',
      description: {
        single: 'Are you sure you want to delete <b>{url}</b>? If so please type "{url}" in the input and click on delete.',
        multiple: 'Are you sure you want to remove <b>{count} Webhooks</b>? If so please type "{text}" and click on delete.',
      },
      multipleSelectionValidation: 'delete {count} Webhooks',
    },
  },

  workingHours: {
    title: 'Availability',
    defaultDaysHeader: 'General availability',
    customDaysHeader: 'Specific availability',
    timeSlots: {
      unavailable: 'Unavailable',
      start: 'Start',
      until: 'until',
      end: 'End',
    },
    editModal: {
      title: 'Edit availability',
      close: 'Cancel',
      description: 'Please enter your availability. Visitors can only schedule a meeting for the timeslots you enter here. For special events or the like, you can enter specific availabilities for special dates. Specific availability overwrite the default working availability for that date.',
      defaultDaysHeader: 'General availability',
      customDaysHeader: 'Specific availability',
      addCustomDay: 'Add specific date',
      submit: 'Save changes',
    },
  },

  pageErrors: {
    error404: {
      title: 'Sorry, the page you’re looking for can’t be found.',
      description: 'If you think this is mistake, please contact your administrator.',
    },
    error500: {
      title: 'Sorry, the server encountered an error. Please try again later.',
      description: 'We’re working hard to fix the problem. We’ll be up and running shortly.',
      ctaLink: window.$vcrConfig.references.technicalUpdateEN,
      ctaText: 'View known issues',
    },
  },

  formValidation: {
    messages: {
      alpha: 'This field may only contain alphabetic characters',
      alpha_num: 'This field may only contain alpha-numeric characters',
      alpha_dash: 'This field may contain alpha-numeric characters as well as dashes and underscores',
      alpha_spaces: 'This field may only contain alphabetic characters as well as spaces',
      between: 'This field must be between {min} and {max}',
      confirmed: 'This field confirmation does not match',
      digits: 'This field must be numeric and exactly contain {length} digits',
      dimensions: 'This field must be {width} pixels by {height} pixels',
      email: 'This field must be a valid email',
      excluded: 'This field is not a valid value',
      ext: 'This field is not a valid file',
      image: 'This field must be an image',
      integer: 'This field must be an integer',
      length: 'This field must be {length} long',
      max_value: 'This field must be {max} or less',
      max: 'This field may not be greater than {length} characters',
      mimes: 'This field must have a valid file type',
      min_value: 'This field must be {min} or more',
      min: 'This field must be at least {length} characters',
      numeric: 'This field may only contain numeric characters',
      oneOf: 'This field is not a valid value',
      regex: 'This field format is invalid',
      required_if: 'This field is required',
      required: 'This field is required',
      size: 'This field size must be less than {size}KB',
      double: 'This field must be a valid decimal',
    },
    forms: {
      dataInvalid: 'Some of your entries are not valid. Please check the fields marked in red.',
      login: {
        credentialsNotCorrect: 'Your login credentials are not correct. Please check your inputs and try again.',
      },
    },
  },

  languageSupport: {
    title: 'Languages',
    cta: 'Change languages',
    supportedLanguages: 'Supported languages:',
    supported: {
      title: 'System Languages',
      description: 'These languages are fully supported by Virtrex. Visitors will find all content, as well as platform navigation in these languages.',
    },
    unsupported: {
      title: 'Supported Languages',
      description: 'These languages are only supported for the brand and product page content. The platform navigation is displayed in English.',
    },
    modal: {
      warning: {
        title: 'Delete Language(s)',
        button: 'Delete Language(s)',
      },
    },
    defaultLanguage: {
      label: 'Default Language',
      placeholder: 'Choose a default language',
    },
  },

  multiLanguage: {
    validationMessage: 'Please add a title and a description for your Expo in each language',
  },

  videoCallPage: {
    title: '{hostName} of {brandName} scheduled a call',
    buttonEditText: 'Edit meeting',
    host: 'Host',
    participants: 'Participants',
    salesAssociates: 'Sales Associates',
    visitors: 'Visitors',
    meetingDetails: 'Meeting details',
    dateAndTime: 'Date & Time',
    introduction: 'Introduction',
    introductionText: '{hostName} of {brandName} would like to present their products to you.',
    buttonAndCall: 'Leave call',
    live: 'live',
    approvingStates: {
      buttonTextAccept: 'Accept',
      buttonTextDecline: 'Decline',
      statusTextAccept: 'You accepted',
      statusTextDecline: 'You declined',
      buttonTextChange: 'Change',
    },
    bannerStatusVideoCall: {
      soon: {
        text: 'Call will start in a few minutes',
        joinButtonText: 'Join call',
      },
      inProgress: {
        text: 'Call is in progress',
        info: '{numberOfParticipantsInCall} participants have joined',
        joinButtonText: 'Join call',
      },
      cancelled: {
        text: 'This sales call has been cancelled',
      },
      finished: {
        text: 'This sales call has been finished',
        info: 'Ended at {endDate}',
      },
    },
    products: {
      suggestedProductsAndFiles: 'Suggested products & files',
      suggestedProducts: 'Suggested Products',
      addProducts: 'Add products',
      uploadFiles: 'Upload files',
      remove: 'Remove',
      arText: '3D / AR',
      sharedFiles: 'Shared files',
      description: 'Products or files that you upload will be shown to all participants of this call. After a call has ended, participants can always access them on their respective call information pages.',
    },
  },

  searchUserFlyout: {
    searchResults: 'Search Results',
    noResults: 'no results',
    addAsGuest: 'Add as guest',
  },

  searchProductFlyout: {
    searchResults: 'Search Results',
    noResults: 'no results',
  },

  scheduleVideoCallModal: {
    title: 'Schedule new meeting',
    close: 'Close',
    description: 'You can schedule a call by inviting at least one other participant. Please make sure to choose the desired expo before continuing.',
    expoPickerLabel: 'Choose an expo',
    submitButton: 'Send invite',
    fieldTitle: {
      label: 'Title',
    },
    fieldDescription: {
      label: 'Invite text (optional)',
    },
    participants: {
      title: 'Add participants',
      description: 'A combined total of five visitors and/or sales associates may be added to your scheduled call.',
      pleaseSelectExpo: 'Please select an expo before inviting participants',
      associates: {
        label: 'Sales associates',
        placeholder: 'Enter name, or email address',
        add: 'Add sales associates',
        noSelected: 'No sales associates added',
        noResultsText: 'No team members found. Try searching for a different name, or email address. You can only invite people of your company.',
      },
      visitors: {
        label: 'Visitors',
        placeholder: 'Enter name, company, or email address',
        add: 'Add visitors',
        noSelected: 'No visitors added',
        noResultsText: 'No visitors found. Try searching for a different name, company, or email address. To invite a guest, please enter their full email address.',
      },
    },
    dateAndTime: {
      title: 'Date & time',
      description: 'Meetings can be scheduled in half an hour time slots. If you need help finding a slot, please use the calendar button on the right.',
      dateLabel: 'Date',
      timeLabel: 'Time',
      pleaseSelectExpo: 'Please select an expo before choosing a date & time',
    },
    userAvailability: {
      notAvailable: '{numberOfNotAvailable} participants are unavailable at this time.',
    },
  },


  editVideoCallModal: {
    title: 'Edit meeting',
    close: 'Close',
    description: 'You can schedule a call by inviting at least one other participant. Please make sure to choose the desired expo before continuing.',
    expoPickerLabel: 'Choose an expo',
    submitButton: 'Update meeting',
    fieldTitle: {
      label: 'Title',
    },
    fieldDescription: {
      label: 'Invite text (optional)',
    },
    participants: {
      title: 'Add participants',
      description: 'A combined total of five visitors and/or sales associates may be added to your scheduled call.',
      associates: {
        label: 'Sales associates',
        placeholder: 'Enter name, or email address',
        add: 'Add sales associates',
        noSelected: 'No sales associates added',
        noResultsText: 'No team members found. Try searching for a different name, or email address. You can only invite people of your company.',
      },
      visitors: {
        label: 'Visitors',
        placeholder: 'Enter name, company, or email address',
        add: 'Add visitors',
        noSelected: 'No visitors added',
        noResultsText: 'No visitors found. Try searching for a different name, company, or email address. To invite a guest, please enter their full email address.',
      },
    },
    dateAndTime: {
      title: 'Date & time',
      description: 'Meetings can be scheduled in half an hour time slots. If you need help finding a slot, please use the calendar button on the right.',
      dateLabel: 'Date',
      timeLabel: 'Time',
    },
    userAvailability: {
      notAvailable: '{numberOfNotAvailable} participants are unavailable at this time.',
    },
  },

  suggestVideoCallProductModal: {
    title: 'Suggest products',
    close: 'Close',
    description: 'Products that you add as suggestions will be shown on the call pages for all participants of this call.',
    noSelectedProducts: 'No Selected Products',
    buttonSubmit: 'Save selected products',
    productList: {
      title: 'Suggested products',
      buttonAddProduct: 'Suggest new product',
    },
  },

  uploadVideoCallFilesModal: {
    title: 'Upload files',
    close: 'Close',
    description: 'Any files that you upload here will be available for participants of this call to view and download.',
    noSelectedProducts: 'no files',
    buttonSubmit: 'Save added files',
    productList: {
      title: 'Added files',
      buttonAddProduct: 'Upload file',
    },
  },

  notificationAboutDirectVideoCall: {
    title: 'New upcoming Call in Virtrex.',
    text: '{associateName} is calling you at {expoName} in Virtrex',
  },

  scheduledSalesCallCard: {
    copyLink: 'Copy link to call',
    openCall: 'Join conversation',
    unknownCompany: 'Company unknown',
    unknownPosition: 'Position unknown',
  },

  videoCallNotification: {
    incomingCall: {
      title: 'Incoming Call',
      join: 'Join call',
      decline: 'Decline',
    },
    scheduledCall: {
      title: 'Scheduled call',
    },
    inProgressCalls: {
      title: 'Calls in progress',
    },
  },
};
