import api from '@api/api';
import IHttpResponse from '@api/http-response';
import IPanelResource from '@api/resource/panel';
import ICreatePanel from '@api/payload/create-panel';
import IEditPanel from '@api/payload/edit-panel';
import IAttachedProduct from '@typing/attached-product';
import IAttachableProduct from '@typing/attachable-product';
import IReorderPanelProduct from '@api/payload/reorder-panel-product';
import IUploadBroadcastVideo from '@api/payload/upload-broadcast-video';

export const loadAll = (stageId: string): Promise<IHttpResponse<IPanelResource[]>> => {
  return api.get(`/api/_brand/stages/${stageId}/panels`);
};

export const create = (
  stageId: string,
  payload: ICreatePanel,
): Promise<IHttpResponse<IPanelResource>> => {
  const formData = new FormData();

  if(typeof payload.name === 'string'){
    formData.append('name', payload.name);
  }

  if(typeof payload.description === 'string'){
    formData.append('description', payload.description);
  }

  if(typeof payload.locale === 'string'){
    formData.append('locale', payload.locale);
  }

  if(typeof payload.speaker === 'string'){
    formData.append('speaker', payload.speaker);
  }

  if(typeof payload.speakerDescription === 'string'){
    formData.append('speakerDescription', payload.speakerDescription);
  }

  if(typeof payload.startDatetime === 'string'){
    formData.append('startDatetime', payload.startDatetime);
  }

  if(typeof payload.endDatetime === 'string'){
    formData.append('endDatetime', payload.endDatetime);
  }

  if(typeof payload.brand === 'string'){
    formData.append('brand', payload.brand);
  }

  if(payload.titleImage) {
    formData.append('titleImage', payload.titleImage);
  }

  formData.append('broadcast', JSON.stringify(payload.broadcast));

  return api.post(`/api/_brand/stages/${stageId}/panels`, formData);
};

export const edit = (
  panelId: string,
  payload: IEditPanel,
): Promise<IHttpResponse<IPanelResource>> => {
  const formData = new FormData();

  if(typeof payload.name === 'string'){
    formData.append('name', payload.name);
  }

  if(typeof payload.description === 'string'){
    formData.append('description', payload.description);
  }

  if(typeof payload.locale === 'string'){
    formData.append('locale', payload.locale);
  }

  if(typeof payload.teaserDescription === 'string'){
    formData.append('teaserDescription', payload.teaserDescription);
  }

  if(typeof payload.speaker === 'string'){
    formData.append('speaker', payload.speaker);
  }

  if(typeof payload.speakerDescription === 'string'){
    formData.append('speakerDescription', payload.speakerDescription);
  }

  if(typeof payload.startDatetime === 'string'){
    formData.append('startDatetime', payload.startDatetime);
  }

  if(typeof payload.endDatetime === 'string'){
    formData.append('endDatetime', payload.endDatetime);
  }

  if(payload.titleImage){
    formData.append('titleImage', payload.titleImage);
  }

  formData.append('broadcast', JSON.stringify(payload.broadcast));

  return api.uploadFile(`/api/_brand/panels/${panelId}`, formData);
};

export const setDate = (panelId: string, startDatetime: string, endDatetime: string): Promise<IHttpResponse<IPanelResource>> => {
  return api.put(`/api/_brand/panels/${panelId}/set-date`, {
    startDatetime,
    endDatetime,
  });
};

export const remove = (panelId: string): Promise<IHttpResponse<any>> => {
  return api.delete(`/api/_brand/panels/${panelId}`);
};

export const load = (panelId: string): Promise<IHttpResponse<IPanelResource>> => {
  return api.get(`/api/_brand/panels/${panelId}`);
};

export const loadAttachedProducts = (
  panelId: string,
): Promise<IHttpResponse<IAttachedProduct[]>> => {
  return api.get(`/api/_brand/panels/${panelId}/products`, {
    params: {
      attached: 1,
    },
  });
};

export const loadAvailableProducts = (
  panelId: string,
): Promise<IHttpResponse<IAttachableProduct[]>> => {
  return api.get(`/api/_brand/panels/${panelId}/products`, {
    params: {
      attached: 0,
    },
  });
};

export const detachProducts = (
  panelId: string,
  products: string[],
): Promise<IHttpResponse<IAttachedProduct[]>> => {
  return api.delete(`/api/_brand/panels/${panelId}/products`, {
    params: {
      products,
    },
  });
};

export const attachProducts = (
  panelId: string,
  products: string[],
): Promise<IHttpResponse<IAttachedProduct[]>> => {
  return api.post(`/api/_brand/panels/${panelId}/products`, {
    products,
  });
};

export const reorderProduct = ({
  panelId,
  productId,
  order,
}: IReorderPanelProduct): Promise<IHttpResponse<IAttachedProduct[]>> => {
  return api.post(`/api/_brand/panels/${panelId}/products/${productId}`, {
    order,
  });
};

export const uploadBroadcastVideo = ({
  panelId,
  broadcastVideo,
  axiosConfig,
}: IUploadBroadcastVideo): Promise<IHttpResponse<IPanelResource>> => {
  const formData = new FormData();
  formData.append('broadcastVideo', broadcastVideo);
  return api.post(`/api/_brand/panels/${panelId}/broadcast-video`, formData, axiosConfig);
};

export const deleteBroadcastVideo = (panelId: string): Promise<IHttpResponse<IPanelResource>> => {
  return api.delete(`/api/_brand/panels/${panelId}/broadcast-video`);
};
