
import {
  defineComponent,
  watch,
} from '@vue/composition-api';
import VcrLegal from '@components/legal/legal.vue';
import VcrLogo from '@components/logo/logo.vue';
import {
  RouterName,
} from '@front/router';
import useAuth from '@front/composition/use-auth';
import {
  RawLocation,
} from 'vue-router';

export default defineComponent({
  components: {
    VcrLogo,
    VcrLegal,
  },
  setup(_, context) {
    const {
      checkIfUserLoggedIn,
    } = useAuth();

    function isLoggedIn() {
      let redirect = null as null | RawLocation;
      if (
        context.root.$route.name === RouterName.brandInvite &&
        context.root.$route.params.inviteId
      ) {
        redirect = {
          name: RouterName.attachBrandToExpo,
          params: {
            inviteId: context.root.$route.params.inviteId,
          },
        };
      }
      checkIfUserLoggedIn(context.root.$router, redirect);
    }

    isLoggedIn();

    watch(
      () => context.root.$route.name,
      () => isLoggedIn(),
    );

    return {};
  },
});
