
import {
  EContactPopupView,
} from '@typing/contact-popup';
import {
  computed,
  defineComponent,
} from '@vue/composition-api';
import {
  VtxIcon,
  EIcon,
} from '@convidera-team/virtrex-design-system';
import useContactPopup from '@front/composition/use-contact-popup';

export default defineComponent({
  components: {
    VtxIcon,
  },

  setup() {
    const {
      isPopupOpened,
      closePopup,
      currentView,
    } = useContactPopup();

    const view = computed(() => {
      switch (currentView.value) {
        case EContactPopupView.Thread:
          return () => import('@components/contact-popup/views/partials/thread/thread.vue');
        case EContactPopupView.AllThreads:
          return () => import('@components/contact-popup/views/partials/all-threads/all-threads.vue');
      }
      return null;
    });

    return {
      EIcon,
      isPopupOpened,
      closePopup,
      view,
    };
  },
});
