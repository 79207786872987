import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import de from '@front/lang/de';
import en from '@front/lang/en';
import getBrowserLanguage from '@utils/browser-language';

export default new VueI18n({
  locale: getBrowserLanguage(),
  fallbackLocale: 'de',
  silentFallbackWarn: true,
  messages: {
    de,
    en,
  },
});
