
import {
  EIcon, VtxIcon,
} from '@convidera-team/virtrex-design-system';
import useExpoList from '@front/composition/use-expo-list';
import useAppList from '@front/composition/use-app-list';
import useUser from '@front/composition/use-user';
import {
  RouterName,
} from '@front/router';
import IExpo from '@typing/expo';
import {
  IApp,
} from '@typing/app';
import {
  isAxiosError,
} from '@utils/errors';
import {
  defineComponent, ref, watch,
} from '@vue/composition-api';
import {
  EPermission,
} from '@front/composition/use-permissions-list';
import useError from '@front/composition/use-error';

interface ISubNavItem {
  id: string;
}

export default defineComponent({
  components: {
    VtxIcon,
  },
  setup(_, context) {
    const exposExpanded = ref(false);
    const appsExpanded = ref(false);
    const settingExpanded = ref(false);

    function checkExposExpanded() {
      exposExpanded.value = [
        RouterName.home,
        RouterName.expo,
      ].indexOf(context.root.$route.name as RouterName) !== -1;
    }

    function checkSettingsExpanded() {
      settingExpanded.value = [
        RouterName.settingsLanding,
        RouterName.brand,
        RouterName.teamManagement,
        RouterName.associate,
        RouterName.apiIntegration,
        RouterName.webhooks,
      ].indexOf(context.root.$route.name as RouterName) !== -1;
    }

    function checkAppsExpanded() {
      appsExpanded.value = [
        RouterName.apps,
        RouterName.app,
      ].indexOf(context.root.$route.name as RouterName) !== -1;
    }

    checkExposExpanded();
    checkSettingsExpanded();
    checkAppsExpanded();

    watch(
      () => context.root.$route.name,
      () => {
        checkExposExpanded();
        checkSettingsExpanded();
        checkAppsExpanded();
      },
    );

    function toggleExpoList() {
      exposExpanded.value = !exposExpanded.value;
    }

    function toggleAppList() {
      appsExpanded.value = !appsExpanded.value;
    }

    function toggleSettingList() {
      settingExpanded.value = !settingExpanded.value;
    }

    const {
      expoList, loadExpoList,
    } = useExpoList();

    const {
      setError,
    } = useError();

    async function loadExpos() {
      try {
        await loadExpoList();
      } catch(error) {
        if (isAxiosError(error)) {
          setError({
            code: error.response?.status,
            message: error.message,
          });
        }
      }
    }

    loadExpos();

    const {
      adminAppList, loadAppList,
    } = useAppList();
    loadAppList();

    function subNavClassList(
      routeItemId: string,
      subNavItemList: ISubNavItem[],
      subNavItem: ISubNavItem,
    ) {
      let previousItem = null;
      const previousIndex = subNavItemList.findIndex(subNavItem => subNavItem.id === routeItemId);
      if (previousIndex > 0) {
        previousItem = subNavItemList[previousIndex - 1];
      }
      return {
        'vcr-sub-nav-list__item_previous': previousItem && previousItem.id === subNavItem.id,
        'vcr-sub-nav-list__item_active': subNavItem.id === routeItemId,
      };
    }

    function expoSubNavClassList(expo: IExpo) {
      if (context.root.$route.name !== RouterName.expo) {
        return null;
      }

      return subNavClassList(
        context.root.$route.params.expoId,
        expoList.value,
        expo,
      );
    }

    function appSubNavClassList(app: IApp) {
      if (context.root.$route.name !== RouterName.app) {
        return null;
      }

      return subNavClassList(
        context.root.$route.params.appId,
        adminAppList.value,
        app,
      );
    }

    const {
      hasPermission,
      canAccessContacts,
      canAccessSales,
      canAccessApiIntegration,
      canAccessWebhooks,
      canAccessApps,
    } = useUser();

    return {
      toggleExpoList,
      toggleAppList,
      toggleSettingList,
      expoSubNavClassList,
      appSubNavClassList,
      exposExpanded,
      appsExpanded,
      settingExpanded,
      expoList,
      adminAppList,
      EIcon,
      RouterName,
      hasPermission,
      EPermission,
      canAccessContacts,
      canAccessSales,
      canAccessApiIntegration,
      canAccessWebhooks,
      canAccessApps,
    };
  },
});
