import {
  isColorLight,
  injectStyle,
} from '@convidera-team/virtrex-design-system';

// @ts-ignore
import brandColorMap from '@convidera-team/virtrex-design-system/src/styles/constant/colour-semantic.scss';

// @ts-ignore
import colorList from '@convidera-team/virtrex-design-system/src/styles/settings/colors.scss';

export function defineBrandColors(primaryColor: string) {
  const isLight = isColorLight(primaryColor);
  const textColor = isLight
    ? brandColorMap.nwtBrandContrastDark
    : brandColorMap.nwtBrandContrastLight;

  return {
    primaryColor,
    textColor,
  };
}

export function injectBrandColors(color?: string | null) {
  color = color ?? String(colorList.vtxColour1);

  const isLight = isColorLight(color);
  const brandContrastColor = isLight
    ? brandColorMap.nwtBrandContrastDark
    : brandColorMap.nwtBrandContrastLight;

  const brandColors = defineBrandColors(color);
  injectStyle(`
      body {
        --nwt-palette-primary-brand: ${color};
        --nwt-palette-primary-brand-contrast: ${brandContrastColor};
        --vcr-brand-color: ${brandColors.primaryColor};
        --vcr-brand-text-color: ${brandColors.textColor};
      }
  `);
}
