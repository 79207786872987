var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vcr-nav-menu"},[_c('ul',{staticClass:"vcr-nav-list"},[_c('li',{staticClass:"vcr-nav-list__item",class:{
        'vcr-nav-list__item_active': _vm.$route.name === _vm.RouterName.home,
      }},[_c('router-link',{staticClass:"vcr-nav-list__item-action vcr-nav-list__item-action_collapsable vtx-typo-body-regular",attrs:{"to":{
          name: _vm.RouterName.home,
        }}},[_c('vtx-icon',{attrs:{"name":_vm.EIcon.diamond}}),_vm._v(" "),_c('span',{staticClass:"vcr-nav-list__item-text"},[_vm._v(_vm._s(_vm.$t('navMenu.expos')))]),_vm._v(" "),_c('div',{staticClass:"vcr-nav-list__toggle-icon",attrs:{"tabindex":"0"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.toggleExpoList.apply(null, arguments)},"click":function($event){$event.preventDefault();return _vm.toggleExpoList.apply(null, arguments)}}},[_c('vtx-icon',{attrs:{"name":_vm.exposExpanded ? _vm.EIcon.chevronDown : _vm.EIcon.chevronRight}})],1)],1),_vm._v(" "),(_vm.exposExpanded)?_c('ul',{staticClass:"vcr-sub-nav-list"},_vm._l((_vm.expoList),function(expo){return _c('li',{key:expo.id,staticClass:"vcr-sub-nav-list__item",class:_vm.expoSubNavClassList(expo)},[_c('div',{staticClass:"vcr-sub-nav-list__item-divider"}),_vm._v(" "),_c('router-link',{staticClass:"vcr-sub-nav-list__item-action vtx-typo-body-small",attrs:{"to":{
              name: _vm.RouterName.expo,
              params: {
                expoId: expo.id,
              },
            }}},[_vm._v("\n            "+_vm._s(expo.name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"vcr-sub-nav-list__item-divider"})],1)}),0):_vm._e()],1),_vm._v(" "),(_vm.canAccessSales)?_c('li',{staticClass:"vcr-nav-list__item",class:{
        'vcr-nav-list__item_active': _vm.$route.name === _vm.RouterName.sales,
      }},[_c('router-link',{staticClass:"vcr-nav-list__item-action vcr-nav-list__item-action_collapsable vtx-typo-body-regular",attrs:{"to":{
          name: _vm.RouterName.sales,
        }}},[_c('vtx-icon',{attrs:{"name":_vm.EIcon.callTalking}}),_vm._v(" "),_c('span',{staticClass:"vcr-nav-list__item-text"},[_vm._v("\n          "+_vm._s(_vm.$t('navMenu.sales'))+"\n        ")])],1)],1):_vm._e(),_vm._v(" "),_c('li',{staticClass:"vcr-nav-list__item ",class:{
        'vcr-nav-list__item_active':
          _vm.$route.name === _vm.RouterName.products || _vm.$route.name === _vm.RouterName.product,
      }},[_c('router-link',{staticClass:"vcr-nav-list__item-action vtx-typo-body-regular",attrs:{"data-qa":"nav-list__products-navigation","to":{ name: _vm.RouterName.products }}},[_c('vtx-icon',{attrs:{"name":_vm.EIcon.cube}}),_vm._v(" "),_c('span',{staticClass:"vcr-nav-list__item-text"},[_vm._v(_vm._s(_vm.$t('navMenu.products')))])],1)],1),_vm._v(" "),_c('li',{staticClass:"vcr-nav-list__item",class:{
        'vcr-nav-list__item_active':
          _vm.$route.name === _vm.RouterName.showrooms || _vm.$route.name === _vm.RouterName.showroom,
      }},[_c('router-link',{staticClass:"vcr-nav-list__item-action vtx-typo-body-regular",attrs:{"to":{ name: _vm.RouterName.showrooms }}},[_c('vtx-icon',{attrs:{"name":_vm.EIcon.rotate}}),_vm._v(" "),_c('span',{staticClass:"vcr-nav-list__item-text"},[_vm._v(_vm._s(_vm.$t('navMenu.showrooms')))])],1)],1),_vm._v(" "),(_vm.canAccessContacts)?_c('li',{staticClass:"vcr-nav-list__item",class:{
        'vcr-nav-list__item_active':
          _vm.$route.name === _vm.RouterName.contacts || _vm.$route.name === _vm.RouterName.contact,
      }},[_c('router-link',{staticClass:"vcr-nav-list__item-action vtx-typo-body-regular",attrs:{"data-qa":"nav-list__contacts-navigation","to":{
          name: _vm.RouterName.contacts,
        }}},[_c('vtx-icon',{attrs:{"name":_vm.EIcon.contactBook}}),_vm._v(" "),_c('span',{staticClass:"vcr-nav-list__item-text"},[_vm._v(_vm._s(_vm.$t('navMenu.contacts')))])],1)],1):_vm._e(),_vm._v(" "),(_vm.hasPermission(_vm.EPermission.downloadInsightsReport))?_c('li',{staticClass:"vcr-nav-list__item",class:{
        'vcr-nav-list__item_active': _vm.$route.name === _vm.RouterName.downloadAnalytics,
      }},[_c('router-link',{staticClass:"vcr-nav-list__item-action vtx-typo-body-regular",attrs:{"to":{
          name: _vm.RouterName.downloadAnalytics,
        }}},[_c('vtx-icon',{attrs:{"name":_vm.EIcon.lightOn}}),_vm._v(" "),_c('span',{staticClass:"vcr-nav-list__item-text"},[_vm._v(_vm._s(_vm.$t('navMenu.downloadAnalytics')))])],1)],1):_vm._e(),_vm._v(" "),(_vm.canAccessApps)?_c('li',{staticClass:"vcr-nav-list__item",class:{
        'vcr-nav-list__item_active': _vm.$route.name === _vm.RouterName.apps,
      }},[_c('router-link',{staticClass:"vcr-nav-list__item-action vcr-nav-list__item-action_collapsable vtx-typo-body-regular",attrs:{"to":{
          name: _vm.RouterName.apps,
        }}},[_c('vtx-icon',{attrs:{"name":_vm.EIcon.puzzle}}),_vm._v(" "),_c('span',{staticClass:"vcr-nav-list__item-text"},[_vm._v("\n          "+_vm._s(_vm.$t('navMenu.apps'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"vcr-nav-list__toggle-icon",attrs:{"tabindex":"0"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.toggleAppList.apply(null, arguments)},"click":function($event){$event.preventDefault();return _vm.toggleAppList.apply(null, arguments)}}},[_c('vtx-icon',{attrs:{"name":_vm.appsExpanded ? _vm.EIcon.chevronDown : _vm.EIcon.chevronRight}})],1)],1),_vm._v(" "),(_vm.appsExpanded)?_c('ul',{staticClass:"vcr-sub-nav-list"},_vm._l((_vm.adminAppList),function(app){return _c('li',{key:app.id,staticClass:"vcr-sub-nav-list__item",class:_vm.appSubNavClassList(app)},[_c('div',{staticClass:"vcr-sub-nav-list__item-divider"}),_vm._v(" "),_c('router-link',{staticClass:"vcr-sub-nav-list__item-action vtx-typo-body-small",attrs:{"to":{
              name: _vm.RouterName.app,
              params: {
                appId: app.id,
              },
            }}},[_vm._v("\n            "+_vm._s(app.title)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"vcr-sub-nav-list__item-divider"})],1)}),0):_vm._e()],1):_vm._e(),_vm._v(" "),_c('li',{staticClass:"vcr-nav-list__item",class:{
        'vcr-nav-list__item_active': _vm.$route.name === _vm.RouterName.settingsLanding,
      }},[_c('router-link',{staticClass:"vcr-nav-list__item-action vcr-nav-list__item-action_collapsable vtx-typo-body-regular",attrs:{"to":{
          name: _vm.RouterName.settingsLanding,
        }}},[_c('vtx-icon',{attrs:{"name":_vm.EIcon.settings}}),_vm._v(" "),_c('span',{staticClass:"vcr-nav-list__item-text"},[_vm._v(_vm._s(_vm.$t('navMenu.settings')))]),_vm._v(" "),_c('div',{staticClass:"vcr-nav-list__toggle-icon",attrs:{"data-qa":"nav-list__settings-toggle","tabindex":"0"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.toggleSettingList.apply(null, arguments)},"click":function($event){$event.preventDefault();return _vm.toggleSettingList.apply(null, arguments)}}},[_c('vtx-icon',{attrs:{"name":_vm.settingExpanded ? _vm.EIcon.chevronDown : _vm.EIcon.chevronRight}})],1)],1),_vm._v(" "),(_vm.settingExpanded)?_c('ul',{staticClass:"vcr-sub-nav-list"},[_c('li',{staticClass:"vcr-sub-nav-list__item",class:{
            'vcr-sub-nav-list__item_active': _vm.$route.name === _vm.RouterName.brand,
          }},[_c('div',{staticClass:"vcr-sub-nav-list__item-divider"}),_vm._v(" "),_c('router-link',{staticClass:"vcr-sub-nav-list__item-action vtx-typo-body-small",attrs:{"to":{
              name: _vm.RouterName.brand,
            }}},[_vm._v("\n            "+_vm._s(_vm.$t('navMenu.brand'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"vcr-sub-nav-list__item-divider"})],1),_vm._v(" "),_c('li',{staticClass:"vcr-sub-nav-list__item",class:{
            'vcr-sub-nav-list__item_active': _vm.$route.name === _vm.RouterName.teamManagement || _vm.$route.name === _vm.RouterName.associate,
          }},[_c('div',{staticClass:"vcr-sub-nav-list__item-divider"}),_vm._v(" "),_c('router-link',{staticClass:"vcr-sub-nav-list__item-action vtx-typo-body-small",attrs:{"to":{
              name: _vm.RouterName.teamManagement,
            }}},[_vm._v("\n            "+_vm._s(_vm.$t('navMenu.teamManagement'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"vcr-sub-nav-list__item-divider"})],1),_vm._v(" "),(_vm.canAccessWebhooks)?_c('li',{staticClass:"vcr-sub-nav-list__item",class:{
            'vcr-sub-nav-list__item_active': _vm.$route.name === _vm.RouterName.webhooks,
          }},[_c('div',{staticClass:"vcr-sub-nav-list__item-divider"}),_vm._v(" "),_c('router-link',{staticClass:"vcr-sub-nav-list__item-action vtx-typo-body-small",attrs:{"to":{
              name: _vm.RouterName.webhooks,
            }}},[_vm._v("\n            "+_vm._s(_vm.$t('navMenu.webhooks'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"vcr-sub-nav-list__item-divider"})],1):_vm._e(),_vm._v(" "),(_vm.canAccessApiIntegration)?_c('li',{staticClass:"vcr-sub-nav-list__item",class:{
            'vcr-sub-nav-list__item_active': _vm.$route.name === _vm.RouterName.apiIntegration,
          }},[_c('div',{staticClass:"vcr-sub-nav-list__item-divider"}),_vm._v(" "),_c('router-link',{staticClass:"vcr-sub-nav-list__item-action vtx-typo-body-small",attrs:{"to":{
              name: _vm.RouterName.apiIntegration,
            }}},[_vm._v("\n            "+_vm._s(_vm.$t('navMenu.apiIntegration'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"vcr-sub-nav-list__item-divider"})],1):_vm._e()]):_vm._e()],1)]),_vm._v(" "),_c('router-link',{staticStyle:{"color":"#FFFFFF"},attrs:{"data-qa":"nav-list__brand-navigation","to":{
      name: _vm.RouterName.brand,
    }}},[_vm._v("\n    .\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }