export default function () {
  return {
    framework: 'virtrex',
    table: 'vcr-datatable',
    row: '',
    column: '',
    label: 'vtx-typo-inputfield-label',
    input: '',
    select: '',
    field: '',
    inline: '',
    right: 'vcr-text-right',
    left: '',
    center: 'vcr-text-center',
    contentCenter: 'vcr-align-items-center',
    nomargin: '',
    groupTr: '',
    small: 'vtx-typo-body-small',
    button: 'vtx-button vtx-button_primary',
    dropdown: {
      container: 'vtx-dropdown',
      trigger: '',
      menu: '',
      content: 'vtx-dropdown__flyout',
      item: '',
      caret: '',
    },
    pagination: {
      nav: '',
      count: '',
      wrapper: '',
      list: 'vcr-display-flex vcr-align-items-center vcr-justify-content-center vcr-margin-1',
      item: '',
      link: 'vcr-padding-1',
      next: '',
      prev: '',
      active: 'vcr-datatable__link-active',
      disabled: 'vcr-text-colour-6',
    },
  };
}
