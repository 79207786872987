import useUserTracking from '@front/composition/use-user-tracking';
import useError from '@front/composition/use-error';
import useVideoCalls from '@front/composition/use-video-calls';
import {
  injectBrandColors,
} from '@utils/inject-styles';
import {
  isAxiosError,
} from '@utils/errors';
import VueRouter, {
  RouteConfig,
} from 'vue-router';
import useUser from '@front/composition/use-user';
import VcrDefaultLayout from '@front/layout/default.vue';
import VcrAuthLayout from '@front/layout/auth.vue';
import VcrBrandManageLayout from '@front/layout/manage.vue';
import VcrVideoCallLayout from '@front/layout/video-call/video-call.vue';
import useAppList from '@front/composition/use-app-list';
import useExpoList from '@front/composition/use-expo-list';
import useEventList from '@front/composition/use-event-list';
import useStageList from '@front/composition/use-stage-list';
import usePanelList from '@front/composition/use-panel-list';
import useBrandList from '@front/composition/use-brand-list';
import useLang from '@front/composition/use-lang';
import useChat from '@front/composition/use-chat';
import useBrand from '@front/composition/use-brand';

const {
  loadAppList,
} = useAppList();

const {
  loadBrand,
} = useBrand();

const {
  getStage, loadStage,
} = useStageList();

const {
  getExpo, loadExpo,
} = useExpoList();

const {
  loadEvent, getEvent,
} = useEventList();

const {
  loadPanelList, loadPanel, getPanel,
} = usePanelList();

const {
  setError,
  clearError,
} = useError();

const {
  canAccessContacts,
  canAccessSales,
  canAccessApiIntegration,
  canAccessWebhooks,
  canAccessApps,
} = useUser();

const  {
  initImAliveRequest,
} = useUserTracking();

const {
  fetchVideoCall,
} = useVideoCalls();

export enum RouterName {
  home = 'home',
  login = 'login',
  expoSettings = 'expoSettings',
  brandSwitcher = 'brand-switcher',
  associate = 'associate',
  welcome = 'welcome',
  registration = 'registration',
  passwordReset = 'password-reset',
  passwordForgot = 'password-forgot',
  userProfile = 'user-profile',
  contacts = 'contacts',
  contact = 'contact',
  expo = 'expo',
  showrooms = 'showrooms',
  showroom = 'showroom',
  event = 'event',
  products = 'products',
  product = 'product',
  stage = 'stage',
  panel = 'panel',
  brand = 'brand',
  sales = 'sales',
  apps = 'apps',
  app = 'app',
  potentialLeads = 'potential-leads',
  openConversations = 'open-conversations',
  scheduledCalls = 'scheduled-calls',
  recentActivities = 'recent-activities',
  lead = 'lead',
  brandInvite = 'brand-invite',
  brandRegistration = 'brand-registration',
  attachBrandToExpo = 'attach-brand-to-expo',
  downloadAnalytics = 'download-analytics',
  settingsLanding = 'settings-landing',
  teamManagement = 'team-management',
  apiIntegration = 'api-integration',
  videoCall = 'video-call',
  videoCallMeeting = 'video-call-meeting',
  webhooks = 'webhooks',
  error = 'error',
}

export const unauthorizedRoutes = [
  String(RouterName.login),
  String(RouterName.welcome),
  String(RouterName.passwordForgot),
  String(RouterName.passwordReset),
  String(RouterName.registration),
  String(RouterName.brandInvite),
  String(RouterName.brandRegistration),
];

const routes: Array<RouteConfig> = [
  {
    path: '/expos',
    component: VcrDefaultLayout,
    children: [
      {
        path: '',
        name: RouterName.home,
        components: {
          content: () => import('@pages/expos/content.vue'),
          actionBar: () => import('@pages/expos/action-bar.vue'),
        },
      },
      {
        path: ':expoId',
        name: RouterName.expo,
        components: {
          content: () => import('@pages/expos/_expoId/content.vue'),
          actionBar: () => import('@pages/expos/_expoId/action-bar.vue'),
        },
      },
      {
        path: ':expoId/settings',
        name: RouterName.expoSettings,
        components: {
          content: () => import('@pages/expos/_expoId/settings/content.vue'),
        },
      },
      {
        path: ':expoId/events/:eventId',
        name: RouterName.event,
        beforeEnter(to, _, next) {
          if (!getExpo(to.params.expoId)) {
            loadExpo(to.params.expoId);
          }
          if (!getEvent(to.params.eventId)) {
            loadEvent(to.params.eventId);
          }
          next();
        },
        components: {
          content: () => import('@pages/events/_eventId/content.vue'),
          actionBar: () => import('@pages/events/_eventId/action-bar.vue'),
        },
      },
      {
        path: ':expoId/events/:eventId/stages/:stageId',
        name: RouterName.stage,
        beforeEnter(to, _, next) {
          if (!getExpo(to.params.expoId)) {
            loadExpo(to.params.expoId);
          }
          if (!getEvent(to.params.eventId)) {
            loadEvent(to.params.eventId);
          }

          if (!getStage(to.params.stageId)) {
            loadStage(to.params.stageId);
          }

          loadPanelList(to.params.stageId);

          next();
        },
        components: {
          content: () => import('@pages/events/_eventId/stages/_stageId/content.vue'),
          actionBar: () => import('@pages/events/_eventId/stages/_stageId/action-bar.vue'),
        },
      },
      {
        path: ':expoId/events/:eventId/stages/:stageId/panels/:panelId',
        name: RouterName.panel,
        async beforeEnter(to, _, next) {
          if (!getExpo(to.params.expoId)) {
            loadExpo(to.params.expoId);
          }
          if (!getEvent(to.params.eventId)) {
            loadEvent(to.params.eventId);
          }

          if (!getPanel(to.params.panelId)) {
            await loadPanel(to.params.panelId);
          }
          const panel = getPanel(to.params.panelId);

          const {
            ableToModerate, loadMessageList,
          } = useChat();
          if (ableToModerate.value) {
            loadMessageList(to.params.expoId, to.params.panelId);
          }

          if (panel && !panel.isOwned) {
            next({
              name: RouterName.stage,
              params: {
                expoId: to.params.expoId,
                eventId: to.params.eventId,
                stageId: to.params.stageId,
              },
            });
          } else {
            next();
          }
        },
        components: {
          content: () =>
            import('@pages/events/_eventId/stages/_stageId/panels/_panelId/content.vue'),
          actionBar: () =>
            import('@pages/events/_eventId/stages/_stageId/panels/_panelId/action-bar.vue'),
        },
      },
    ],
  },
  {
    component: VcrAuthLayout,
    path: '/login',
    children: [
      {
        path: '',
        name: RouterName.login,
        components: {
          content: () => import('@pages/login/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrBrandManageLayout,
    path: '/brand-switcher',
    children: [
      {
        path: '',
        name: RouterName.brandSwitcher,
        components: {
          content: () => import('@pages/brand-switcher/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrDefaultLayout,
    path: '/user-profile',
    children: [
      {
        path: '',
        name: RouterName.userProfile,
        components: {
          content: () => import('@pages/user-profile/content.vue'),
          actionBar: () => import('@pages/user-profile/action-bar.vue'),
        },
      },
    ],
  },
  {
    component: VcrAuthLayout,
    path: '/welcome/:email/:invite',
    children: [
      {
        path: '',
        name: RouterName.welcome,
        components: {
          content: () => import('@pages/welcome/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrAuthLayout,
    path: '/registration/:email/:invite',
    children: [
      {
        path: '',
        name: RouterName.registration,
        components: {
          content: () => import('@pages/registration/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrAuthLayout,
    path: '/password-reset',
    children: [
      {
        path: '',
        name: RouterName.passwordForgot,
        components: {
          content: () => import('@pages/password-forgot/content.vue'),
        },
      },
      {
        path: ':email/:token',
        name: RouterName.passwordReset,
        components: {
          content: () => import('@pages/password-reset/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrDefaultLayout,
    path: '/contacts',
    children: [
      {
        path: '',
        name: RouterName.contacts,
        components: {
          content: () => import('@pages/contacts/content.vue'),
          actionBar: () => import('@pages/contacts/action-bar.vue'),
        },
        beforeEnter(_to, _from, next) {
          if (! canAccessContacts.value) {
            return next({
              name: RouterName.home,
            });
          }
          next();
        },
      },
      {
        path: ':contactId',
        name: RouterName.contact,
        components: {
          content: () => import('@pages/contacts/_contactId/content.vue'),
          actionBar: () => import('@pages/contacts/_contactId/action-bar.vue'),
        },
        beforeEnter(_to, _from, next) {
          if (! canAccessContacts.value) {
            return next({
              name: RouterName.home,
            });
          }
          next();
        },
      },
    ],
  },
  {
    component: VcrDefaultLayout,
    path: '/showrooms',
    children: [
      {
        path: '',
        name: RouterName.showrooms,
        components: {
          content: () => import('@pages/showrooms/content.vue'),
          actionBar: () => import('@pages/showrooms/action-bar.vue'),
        },
      },
      {
        path: ':showroomId',
        name: RouterName.showroom,
        components: {
          content: () => import('@pages/showrooms/_showroomId/content.vue'),
          actionBar: () => import('@pages/showrooms/_showroomId/action-bar.vue'),
        },
      },
    ],
  },
  {
    component: VcrDefaultLayout,
    path: '/products',
    children: [
      {
        path: '',
        name: RouterName.products,
        components: {
          content: () => import('@pages/products/content.vue'),
          actionBar: () => import('@pages/products/action-bar.vue'),
        },
      },
      {
        path: ':productId',
        name: RouterName.product,
        components: {
          content: () => import('@pages/products/_productId/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrDefaultLayout,
    path: '/brand',
    children: [
      {
        path: '',
        name: RouterName.brand,
        components: {
          content: () => import('@pages/brand/content.vue'),
          actionBar: ()=> import('@pages/brand/action-bar.vue'),
        },
        beforeEnter(_to, _, next) {
          loadBrand();
          next();
        },
      },
    ],
  },
  {
    component: VcrDefaultLayout,
    props: {
      useWideActionBar: true,
    },
    path: '/sales',
    children: [
      {
        path: '',
        name: RouterName.sales,
        components: {
          content: () => import('@pages/sales/content.vue'),
          actionBar: () => import('@pages/sales/action-bar.vue'),
          preContent: () => import('@pages/sales/pre-content.vue'),
        },
      },
      {
        path: 'potential-leads',
        name: RouterName.potentialLeads,
        components: {
          content: () => import('@pages/sales/potential-leads/content.vue'),
          actionBar: () => import('@pages/sales/action-bar.vue'),
          preContent: () => import('@pages/sales/pre-content.vue'),
        },
      },
      {
        path: 'open-conversations',
        name: RouterName.openConversations,
        components: {
          content: () => import('@pages/sales/open-conversations/content.vue'),
          actionBar: () => import('@pages/sales/action-bar.vue'),
          preContent: () => import('@pages/sales/pre-content.vue'),
        },
      },
      {
        path: 'scheduled-calls',
        name: RouterName.scheduledCalls,
        components: {
          content: () => import('@pages/sales/scheduled-calls/content.vue'),
          actionBar: () => import('@pages/sales/scheduled-calls/action-bar.vue'),
          preContent: () => import('@pages/sales/pre-content.vue'),
        },
      },
      {
        path: 'recent-activities',
        name: RouterName.recentActivities,
        components: {
          content: () => import('@pages/sales/recent-activities/content.vue'),
          preContent: () => import('@pages/sales/pre-content.vue'),
        },
      },
    ],
    beforeEnter(_to, _from, next) {
      if (! canAccessSales.value) {
        return next({
          name: RouterName.home,
        });
      }

      next();
    },
  },
  {
    component: VcrDefaultLayout,
    props: {
      useWideActionBar: true,
    },
    path: '/apps',
    children: [
      {
        path: '',
        name: RouterName.apps,
        components: {
          content: () => import('@pages/apps/content.vue'),
          actionBar: () => import('@pages/apps/action-bar.vue'),
        },
        beforeEnter(_to, _from, next) {
          if (! canAccessApps.value) {
            return next({
              name: RouterName.home,
            });
          }

          loadAppList();
          next();
        },
      },
      {
        path: ':appId/:moduleType?',
        name: RouterName.app,
        components: {
          content: () => import('@pages/apps/_appId/content.vue'),
        },
        beforeEnter(_to, _from, next) {
          if (! canAccessApps.value) {
            return next({
              name: RouterName.home,
            });
          }

          next();
        },
      },
    ],
  },
  {
    component: VcrDefaultLayout,
    path: '/lead/:expoId/:visitorId',
    children: [
      {
        path: '',
        name: RouterName.lead,
        components: {
          content: () => import('@pages/lead/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrAuthLayout,
    path: '/brand-invite',
    children: [
      {
        path: ':inviteId',
        name: RouterName.brandInvite,
        components: {
          content: () => import('@pages/brand-invite/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrAuthLayout,
    path: '/brand-registration',
    children: [
      {
        path: ':inviteId',
        name: RouterName.brandRegistration,
        components: {
          content: () => import('@pages/brand-registration/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrBrandManageLayout,
    path: '/attach-brand-to-expo',
    children: [
      {
        path: ':inviteId',
        name: RouterName.attachBrandToExpo,
        components: {
          content: () => import('@pages/attach-brand-to-expo/content.vue'),
        },
      },
    ],
  },
  {
    component: VcrDefaultLayout,
    path: '/download-analytics',
    children: [
      {
        path: '',
        name: RouterName.downloadAnalytics,
        components: {
          content: () => import('@pages/download-analytics/content.vue'),
        },
      },
    ],
  },
  {
    path: '/',
    redirect: {
      name: RouterName.home,
    },
  },
  {
    component: VcrDefaultLayout,
    path: '/settings',
    children: [
      {
        path: '',
        name: RouterName.settingsLanding,
        components: {
          content: () => import('@pages/settings/content.vue'),
        },
      },
      {
        path: 'brand',
        name: RouterName.brand,
        components: {
          content: () => import('@pages/settings/brand/content.vue'),
        },
      },
      {
        path: 'team-management',
        name: RouterName.teamManagement,
        components: {
          content: () => import('@pages/settings/team-management/content.vue'),
          actionBar: () => import('@pages/settings/team-management/action-bar.vue'),
        },
      },
      {
        path: 'associates/:associateId',
        name: RouterName.associate,
        beforeEnter(to, from, next) {
          const {
            currentUser,
          } = useUser();
          if(currentUser.value?.id === to.params.associateId) {
            next({
              name: RouterName.userProfile,
            });
          } else {
            next();
          }
        },
        components: {
          content: () => import('@pages/associates/_associateId/content.vue'),
          actionBar: () => import('@pages/associates/_associateId/action-bar.vue'),
        },
      },
      {
        path: 'api-integration',
        name: RouterName.apiIntegration,
        components: {
          content: () => import('@pages/settings/api-integration/content.vue'),
          actionBar: () => import('@pages/settings/api-integration/action-bar.vue'),
        },
        beforeEnter(_to, _, next) {
          if (! canAccessApiIntegration.value) {
            return next({
              name: RouterName.home,
            });
          }

          next();
        },
      },
      {
        path: 'webhooks',
        name: RouterName.webhooks,
        components: {
          content: () => import('@pages/settings/webhooks/content.vue'),
          actionBar: () => import('@pages/settings/webhooks/action-bar.vue'),
        },
        beforeEnter(_to, _, next) {
          if (! canAccessWebhooks.value) {
            return next({
              name: RouterName.home,
            });
          }

          next();
        },
      },
      {
        path: 'webhooks',
        name: RouterName.webhooks,
        components: {
          content: () => import('@pages/settings/webhooks/content.vue'),
          actionBar: () => import('@pages/settings/webhooks/action-bar.vue'),
        },
        beforeEnter(_to, _, next) {
          if (! canAccessWebhooks.value) {
            return next({
              name: RouterName.home,
            });
          }

          next();
        },
      },
    ],
  },
  {
    path: '/video-calls',
    component: VcrVideoCallLayout,
    children: [
      {
        path: ':videoCallId',
        name: RouterName.videoCall,
        components: {
          content: () => import('@pages/video-calls/_videoCallId/content.vue'),
        },
        async beforeEnter(to, _, next) {
          try {
            const videoCall = await fetchVideoCall(to.params.videoCallId);
            if (videoCall) {
              await loadExpo(videoCall.expoId);
            }
          } catch (error) {
            if (isAxiosError(error)) {
              setError({
                code: error.response?.status,
                message: error.message,
              });
            }
          }
          next();
        },
      },
      {
        path: ':videoCallId/meeting',
        name: RouterName.videoCallMeeting,
        components: {
          content: () => import('@pages/video-calls/_videoCallId/meeting/content.vue'),
        },
        async beforeEnter(to, _, next) {
          try {
            const videoCall = await fetchVideoCall(to.params.videoCallId);
            if (videoCall) {
              await loadExpo(videoCall.expoId);
            }
          } catch(error) {
            if (isAxiosError(error)) {
              setError({
                code: error.response?.status,
                message: error.message,
              });
            }
          }

          next();
        },
      },
    ],
  },
  {
    path: '*',
    component: VcrDefaultLayout,
    children: [
      {
        path: '',
        name: RouterName.error,
        beforeEnter(_to, _from, next) {
          setError({
            code: 404,
          });
          next();
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, _, next) => {
  clearError();

  const {
    loadCurrentUser, currentUser,
  } = useUser();

  const {
    loadSystemLanguages, systemLanguages,
  } = useLang();

  const {
    loadCurrentBrand, currentBrand,
  } = useBrandList();

  if (typeof to.name === 'string' && !unauthorizedRoutes.includes(to.name)) {
    if (currentUser.value && currentBrand.value) {
      next();
    } else {
      try {
        if (!currentUser.value) {
          await loadCurrentUser();
        }
        if (!systemLanguages.value.length) {
          await loadSystemLanguages();
        }
        if (!currentBrand.value) {
          const brand = await loadCurrentBrand();
          injectBrandColors(brand.color);
        }
        initImAliveRequest();
      } finally {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
