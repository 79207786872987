import {
  reactive, computed,
} from '@vue/composition-api';
import IPermissionCategory from '@typing/permission-category';
import * as api from '@api/permission';

const state = reactive({
  permissionCategoryList: [] as IPermissionCategory[],
  loading: false,
});

export enum EPermission {
  createAndEditAllContacts = 'contacts.all',
  deleteContacts = 'contacts.delete',
  createAndEditPersonalContacts = 'contacts.personal',
  deleteExpos = 'expos.delete',
  editExpos = 'expos.edit',
  canModerateChat = 'expos.canModerateChat',
  deleteProducts = 'products.delete',
  editProducts = 'products.edit',
  downloadInsightsReport = 'insights.report',
  downloadLeadsReport = 'sales.leadsReport',
  manageLeadsAndSalesCalls = 'sales.leadsSalesCalls',
  deleteTeamMembers = 'settings.deleteTeamMembers',
  editBrandSetup = 'settings.editBrandSetup',
  editRolePermissions = 'settings.editRolePermissions',
  editTeamMembers = 'settings.editTeamMembers',
  editUserRoles = 'settings.editUserRoles',
  editApiAndWebhooks = 'settings.editApiAndWebhooks',
  inviteTeamMembers = 'settings.inviteTeamMembers',
  deleteShowrooms = 'showrooms.delete',
  editShowrooms = 'showrooms.edit',
  installApps = 'settings.installApps',
}

export default function usePermissionList() {
  function loadPermissionCategoryList() {
    if (state.loading) {
      return false;
    }
    state.loading = true;

    api
      .getAllByCategory()
      .then(result => {
        state.permissionCategoryList = result.data.data;
      })
      .finally(() => {
        state.loading = false;
      });
  }

  return {
    permissionCategoryList: computed(() => state.permissionCategoryList),
    loading: computed(() => state.loading),
    loadPermissionCategoryList,
  };
}
