import IContactRequest from '@typing/contact-request';
import useEventTaskManager from '@front/composition/use-event-task-manager';

const {
  addTask: onContactRequestStatusChanged,
  removeTask: offContactRequestStatusChanged,
  runTasks: runContactRequestStatusChanged,
} = useEventTaskManager();

const {
  addTask: onContactRequestCreated,
  removeTask: offContactRequestCreated,
  runTasks: runContactRequestCreated,
} = useEventTaskManager();

const joinChannel = (associateId: string) => {
  if (!window.Echo) {
    return;
  }

  window.Echo.private(`salescalls.${associateId}`)
    .listen('.App\\Events\\Broadcast\\ContactRequest\\ContactRequestStatusChanged', (event: {
        contactRequest: IContactRequest,
      }) => {
      runContactRequestStatusChanged(event.contactRequest);
    })
    .listen('.App\\Events\\Broadcast\\ContactRequest\\IncomingContactRequestCreated', (event: {
        contactRequest: IContactRequest,
      }) => {
      runContactRequestCreated(event.contactRequest);
    })
    .listen('.App\\Events\\Broadcast\\ContactRequest\\OutgoingContactRequestCreated', (event: {
        contactRequest: IContactRequest,
      }) => {
      runContactRequestCreated(event.contactRequest);
    });
};

export {
  joinChannel,
  onContactRequestStatusChanged,
  offContactRequestStatusChanged,
  onContactRequestCreated,
  offContactRequestCreated,
};
