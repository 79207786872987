import {
  computed,
  del,
  reactive,
} from '@vue/composition-api';
import * as api from '@api/lang';
import ILanguage from '@front/types/lang';
import ITranslations from '@front/types/translations';

const state = reactive({
  loadingLanguages: false,
  systemLanguages: [] as ILanguage[],
});

export default function useLang() {
  async function loadSystemLanguages() {
    if(state.loadingLanguages) {
      return;
    }

    state.loadingLanguages = true;

    await api.loadAll().then(result => {
      state.systemLanguages = result.data.data;
    }).finally(() => state.loadingLanguages = false);
  }

  function initializeTranslatable(
    languages: ILanguage[],
    translations: ITranslations = {},
    initialValue: string = '',
  ): ITranslations {
    return Object.assign(
      Object.fromEntries(
        languages.map(language => [
          language.locale,
          initialValue,
        ]),
      ),
      translations,
    );
  }

  // This can probably be removed once vuelidate is added?
  function validateTranslatable(languages: ILanguage[], translatables: ITranslations[] = []) {
    const languageLocales = languages.map(language => language.locale);

    const translationsNotEmpty = translatables.map(translatable => {
      // check if the translatable for a translation in each language
      if(
        (languageLocales.sort().join(',') !== Object.keys(translatable).sort().join(',')) ||
        !Object.values(translatable).length
      ) {
        return false;
      }

      return Object.values(translatable).every(value => value !== null && value !== '');
    });

    return !translationsNotEmpty.includes(false);
  }

  // check if a key exists in name and description that's no longer in the provided languages
  function validateTranslationsAgainstLanguages(translations: ITranslations, languages: ILanguage[]) {
    Object.keys(translations).map((locale) => {
      const localeStillInLanguages = languages.some(language => language.locale === locale);

      if(!localeStillInLanguages) {
        del(translations, locale);
      }
    });
  }

  function getLanguagesFromLocales(locales: string[]) {
    return state.systemLanguages.filter(lang => locales.includes(lang.locale));
  }

  function getLocalesFromLanguages(languages: ILanguage[]) {
    return languages.map(lang => lang.locale);
  }

  return {
    loadSystemLanguages,
    loadingLanguages: computed(() => state.loadingLanguages),
    systemLanguages: computed(() => state.systemLanguages),
    supportedLanguages: computed(() => state.systemLanguages.filter(language => !!language.supported)),
    unsupportedLanguages: computed(() => state.systemLanguages.filter(language => !language.supported)),
    initializeTranslatable,
    validateTranslatable,
    validateTranslationsAgainstLanguages,
    getLanguagesFromLocales,
    getLocalesFromLanguages,
  };
}
