export interface IBroadcastDetails {
  broadcast: IBroadcastData
}
export interface IBroadcastData {
  provider: string,
  fields: IBroadcastField,
}
interface IBroadcastField {
  identifier?: string,
  chatType?: string,
  broadcastUrl?: string
  broadcastToken?: string,
}

export interface IBroadcastProviderSettingData {
  source: string,
  value: IBroadcastDetails|string,
}

export enum EBroadcastProviderField  {
  SelectProvider ='provider',
  SelectChatType = 'chatType',
  Identifier = 'identifier',
}

export enum EBroadcastChatTypeList {
  Default = 'virtrex',
  Custom = 'custom',
}



