import {
  IThreadMessageResource, IThreadParticipantResource, IThreadResource,
} from '@api/resource/threads';
import * as api from '@api/threads';
import * as chatThreadBroadcasting from '@front/broadcasting/chat-thread';
import updateOrCreate from '@front/composition/helpers/update-or-create';
import {
  IThread,
} from '@typing/thread';
import {
  EThreadMessageStatus,
} from '@typing/thread-message';
import {
  EThreadMessageType, IThreadMessage,
} from '@typing/thread-message';
import {
  EThreadParticipantUserType,
} from '@typing/thread-participant';
import {
  IThreadParticipant,
} from '@typing/thread-participant';
import {
  dayjs,
} from '@utils/date-format';
import {
  reactive, toRefs,
} from '@vue/composition-api';

const state = reactive({
  threads: [] as IThread[],
  threadMessages: [] as IThreadMessage[],
  threadParticipants: [] as IThreadParticipant[],
});

export default function useThread() {
  function updateOrCreateThread(threadResource: IThreadResource) {
    const {
      participants,
      messages,
      ...thread
    } = threadResource;

    updateOrCreate(state.threads, thread);

    chatThreadBroadcasting.joinChannel(thread.id);

    chatThreadBroadcasting.onThreadMessageCreated((threadMessage) => {
      updateOrCreateThreadMessage(threadMessage);
    });

    chatThreadBroadcasting.onThreadMessageUpdated((threadMessage) => {
      updateOrCreateThreadMessage(threadMessage);
    });

    participants.forEach(updateOrCreateThreadParticipant);

    messages.forEach(updateOrCreateThreadMessage);
  }

  function updateOrCreateThreadMessage(threadMessageResource: IThreadMessageResource) {
    updateOrCreate(state.threadMessages, threadMessageResource);
  }

  function updateOrCreateThreadParticipant(threadParticipantResource: IThreadParticipantResource) {
    updateOrCreate(state.threadParticipants, threadParticipantResource);
  }

  async function loadDirectConversations(expoId: string) {
    try {
      const result = await api.getDirectConversations(expoId);
      result.data.data.forEach(updateOrCreateThread);
    } catch (e) {
      // ...
    }
  }

  async function loadDirectConversationsForCurrentBrand() {
    try {
      const result = await api.getDirectConversationsForCurrentBrand();
      result.data.data.forEach(updateOrCreateThread);
    } catch (e) {
      // ...
    }
  }

  async function createThreadMessageText(threadParticipant: IThreadParticipant, text: string) {
    return api.createThreadMessage(
      {
        threadParticipantId: threadParticipant.id,
        type: EThreadMessageType.Text,
        text,
      })
      .then((result) => {
        updateOrCreateThreadMessage(result.data.data);
      });
  }

  async function createThreadMessageFile(threadParticipant: IThreadParticipant, file: File) {
    return api.createThreadMessage({
      threadParticipantId: threadParticipant.id,
      type: EThreadMessageType.File,
      attachment: file,
    })
      .then((result) => {
        updateOrCreateThreadMessage(result.data.data);
      });
  }

  async function markThreadMessageAsRead(thread: IThread, threadMessages: IThreadMessage[]) {
    return api.markThreadMessageAsRead(
      thread.id,
      threadMessages.map(threadMessage => threadMessage.id),
    )
      .then((result) => {
        result.data.data.forEach((threadMessageResource) => {
          updateOrCreateThreadMessage(threadMessageResource);
        });
      });
  }

  function sortThreads(threadId: string) {
    const threadIndex = state.threads.findIndex(thread => thread.id === threadId);
    if (!threadIndex) {
      return;
    }
    state.threads.unshift(state.threads.splice(threadIndex, 1)[0]);
  }

  function getThread(threadId: string) {
    return state.threads.find(thread => thread.id === threadId);
  }

  function getMessages(threadId: string) {
    return state.threadMessages
      .filter(message => message.threadId === threadId);
  }

  function getSortedMessages(threadId: string, sortAsc: boolean = true) {
    return getMessages(threadId)
      .sort((a, b) => {
        return sortAsc
          ? dayjs(a.createdAt).diff(dayjs(b.createdAt))
          : dayjs(b.createdAt).diff(dayjs(a.createdAt));
      });
  }

  /**
   * get list of `threads` sorted by date of last message from Visitor
   */
  function getSortedThreads(sortAsc: boolean = true) {
    const sortedThreads = state.threads.map(thread => {
      let lastMessageFromVisitor: undefined | IThreadMessage;
      const participantVisitor = state.threadParticipants.find(participant => {
        return participant.userType === EThreadParticipantUserType.Visitor &&
            participant.threadId === thread.id;
      });
      if (participantVisitor) {
        lastMessageFromVisitor = getSortedMessages(thread.id, false)
          .filter(message => message.participantId === participantVisitor.id)[0];
      }

      return {
        thread,
        createdAt: lastMessageFromVisitor?.createdAt || thread.createdAt,
      };
    });

    return sortedThreads
      .sort((object1, object2) => {
        return sortAsc
          ? dayjs(object1.createdAt).diff(dayjs(object2.createdAt))
          : dayjs(object2.createdAt).diff(dayjs(object1.createdAt));
      })
      .map(({
        thread,
      }) => thread);
  }

  function getNewMessages(threadId: string) {
    const associateParticipant = getParticipants(threadId).find(participant => {
      return participant.userType === EThreadParticipantUserType.Associate;
    });
    if (!associateParticipant) {
      return [];
    }

    return getMessages(threadId).filter(message => {
      return message.participantId !== associateParticipant.id && message.status === EThreadMessageStatus.New;
    });
  }

  function getParticipants(threadId: string) {
    return state.threadParticipants
      .filter(threadParticipant => threadParticipant.threadId === threadId);
  }

  return {
    ...toRefs(state),
    loadDirectConversations,
    updateOrCreateThreadMessage,
    updateOrCreateThread,
    getThread,
    getMessages,
    getSortedMessages,
    getNewMessages,
    getParticipants,
    createThreadMessageText,
    markThreadMessageAsRead,
    createThreadMessageFile,
    sortThreads,
    getSortedThreads,
    loadDirectConversationsForCurrentBrand,
  };
}
