import api from '@api/api';
import IHttpResponse from '@api/http-response';
import IVisitorResource from '@api/resource/visitor';
import IFile from '@typing/file';
import IUser from '@typing/user';
import {
  IEditVideoCallPayload,
  IScheduleVideoCallTimeFrame,
  IVideoCallParticipant,
  EVideoCallParticipantStatus,
  EVideoCallParticipantStateInCall,
  IGetVideoCallsFilters,
  IVideoCall,
  IScheduleVideoCallPayload,
  TVideoCallSuggestedProduct,
  IScheduleVideoCallGetAvailabilityPayload,
  TScheduleVideoCallTimeFrameAvailability,
} from '@typing/video-call';
import {
  dayjs,
} from '@utils/date-format';
import PREVENT_ERROR_INTERCEPTOR from '@utils/prevent-error-interceptor';

export const getVideoCalls = (
  filters: IGetVideoCallsFilters,
): Promise<IHttpResponse<IVideoCall[]>> => {
  let direct, groupByDate;

  if (typeof filters.direct === 'boolean') {
    direct = filters.direct ? 1 : 0;
  }
  if (typeof filters.groupByDate === 'boolean') {
    groupByDate = filters.groupByDate ? 1 : 0;
  }

  return api.get('/api/_associate-video-calls', {
    params: {
      ...filters,
      direct,
      groupByDate,
    },
  });
};

export const getVideoCall = (
  videoCallId: string,
): Promise<IHttpResponse<IVideoCall>> => {
  return api.get(`/api/_associate-video-call/${videoCallId}`);
};

export const scheduleVideoCall = (
  expoId: string,
  payload: IScheduleVideoCallPayload,
): Promise<IHttpResponse<IVideoCall>> => {
  return api.post(`/api/_associate-schedule-video-call/${expoId}`, payload, {
    headers: {
      [PREVENT_ERROR_INTERCEPTOR]: true,
    },
  });
};

export const editVideoCall = (
  videoCallId: string,
  payload: IEditVideoCallPayload,
): Promise<IHttpResponse<IVideoCall>> => {
  return api.post(`/api/_associate-edit-video-call/${videoCallId}`, payload, {
    headers: {
      [PREVENT_ERROR_INTERCEPTOR]: true,
    },
  });
};

export const setParticipantStatus = (
  participantId: string,
  status: EVideoCallParticipantStatus,
): Promise<IHttpResponse<IVideoCallParticipant>> => {
  return api.put(`/api/_associate-set-video-call-participant-status/${participantId}`, {
    status,
  });
};

export const setParticipantStateInCall = (
  participantId: string,
  state: EVideoCallParticipantStateInCall,
): Promise<IHttpResponse<IVideoCallParticipant>> => {
  return api.put(`/api/_associate-set-participant-state-in-call/${participantId}`, {
    state,
  });
};

export const refreshStateInCall = (
  participantId: string,
): Promise<IHttpResponse<IVideoCallParticipant>> => {
  return api.post(`/api/_associate-refresh-state-in-call/${participantId}`);
};

export const searchAssociates = (
  search: string,
): Promise<IHttpResponse<Array<IUser>>> => {
  return api.get(
    '/api/_associates-list',
    {
      params: {
        search: search || undefined,
      },
    },
  );
};

export const searchVisitors = async (
  search: string,
  expoId: string,
): Promise<IHttpResponse<Array<IVisitorResource>>> => {
  return api.get(
    `/api/visitors-list/${expoId}`,
    {
      params: {
        search: search || undefined,
      },
    },
  );
};

export const getTimeFrames = (callDate: Date): Promise<IHttpResponse<Array<IScheduleVideoCallTimeFrame>>> => {
  return api.get('/api/_associate-video-call-time-frames', {
    params: {
      callDate: dayjs(callDate).format('YYYY-MM-DD'),
    },
  });
};

export const getParticipantsAvailability = (params: IScheduleVideoCallGetAvailabilityPayload): Promise<IHttpResponse<TScheduleVideoCallTimeFrameAvailability>> => {
  return api.get('/api/_associate-video-call-participants-availability', {
    params,
  });
};

export const cancelVideoCall = (videoCallId: string): Promise<IHttpResponse<IVideoCall>> => {
  return api.post(`/api/_associate-cancel-video-call/${videoCallId}`);
};

export const getSuggestedProducts = (videoCallId: string): Promise<IHttpResponse<TVideoCallSuggestedProduct[]>> => {
  return api.get(`/api/_associate-video-call-suggested-products/${videoCallId}`);
};

export const getVideoCallProducts = (videoCallId: string): Promise<IHttpResponse<TVideoCallSuggestedProduct[]>> => {
  return api.get(`/api/_associate-video-call-products/${videoCallId}`);
};

export const editSuggestedProducts = (videoCallId: string, productIds: Array<string>): Promise<IHttpResponse<TVideoCallSuggestedProduct[]>> => {
  return api.post(`/api/_associate-video-call-manage-products/${videoCallId}`, {
    products: productIds,
  });
};

export const getAttachments = (videoCallId: string): Promise<IHttpResponse<IFile[]>> => {
  return api.get(`/api/_associate-video-call-attachments/${videoCallId}`);
};

export const uploadAttachments = (videoCallId: string, files: File[]): Promise<IHttpResponse<IFile[]>> => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append('attachments[]', file);
  });

  return api.post(`/api/_associate-video-call-upload-attachments/${videoCallId}`, formData);
};

export const deleteAttachment = (videoCallId: string, fileId: string): Promise<IHttpResponse<void>> => {
  return api.delete(`/api/_associate-video-call-delete-attachment/${videoCallId}/${fileId}`);
};
