import {
  AxiosError,
} from 'axios';

export const isAxiosError = (error: any): error is AxiosError => {
  return error && error.isAxiosError;
};

export const is404Error = (error: any) => {
  return isAxiosError(error) && error.response?.status === 404;
};

export const is5XXError = (error: any) => {
  return isAxiosError(error) &&  error.response && (error.response.status >= 500 && error.response.status < 600);
};
