import useThread from '@front/composition/use-thread';
import {
  EContactPopupNotificationRelatedType,
} from '@typing/contact-popup';
import {
  IContactPopupNotification,
} from '@typing/contact-popup';
import {
  EContactPopupView,
} from '@typing/contact-popup';
import {
  toRefs,
  reactive,
  computed,
} from '@vue/composition-api';

const state = reactive({
  isPopupOpened: false,
  currentView: null as null | EContactPopupView,
  activeThreadId: null as null | string,
});

export default function useContactPopup() {
  const {
    threads,
    getNewMessages,
  } = useThread();

  function openPopup() {
    state.isPopupOpened = true;
  }

  function closePopup() {
    state.activeThreadId = null;
    state.isPopupOpened = false;
    state.currentView = null;
  }

  function openAllThreads() {
    state.currentView = EContactPopupView.AllThreads;
    openPopup();
  }

  function openThread(threadId: string) {
    state.activeThreadId = threadId;
    state.currentView = EContactPopupView.Thread;
    openPopup();
  }

  const notifications = computed(() => {
    let notificationsNewMessage: IContactPopupNotification[] = [];
    threads.value.forEach(thread => {
      notificationsNewMessage = [
        ...notificationsNewMessage,
        ...getNewMessages(thread.id).map(message => ({
          relatedId: message.id,
          relatedType: EContactPopupNotificationRelatedType.NewMessage,
        })),
      ];
    });
    return notificationsNewMessage;
  });

  return {
    ...toRefs(state),
    openPopup,
    closePopup,
    openThread,
    openAllThreads,
    notifications,
  };
}
