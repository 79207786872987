import {
  IThreadMessageResource,
} from '@api/resource/threads';
import useEventTaskManager from '@front/composition/use-event-task-manager';

const {
  addTask: onThreadMessageCreated,
  removeTask: offThreadMessageCreated,
  runTasks: runThreadMessageCreated,
} = useEventTaskManager();

const {
  addTask: onThreadMessageUpdated,
  removeTask: offThreadMessageUpdated,
  runTasks: runThreadMessageUpdated,
} = useEventTaskManager();

const threadList = new Set<string>();

const joinChannel = (threadId: string) => {
  if (!window.Echo || threadList.has(threadId)) {
    return;
  }

  threadList.add(threadId);
  window.Echo.private(`chat-thread.${threadId}`)
    .listen('.App\\Events\\Broadcast\\ChatThread\\ThreadMessageCreated', (threadMessage: IThreadMessageResource) => {
      runThreadMessageCreated(threadMessage);
    })
    .listen('.App\\Events\\Broadcast\\ChatThread\\ThreadMessageUpdated', (threadMessage: IThreadMessageResource) => {
      runThreadMessageUpdated(threadMessage);
    });
};

export {
  joinChannel,
  onThreadMessageCreated,
  offThreadMessageCreated,
  onThreadMessageUpdated,
  offThreadMessageUpdated,
};
