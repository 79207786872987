import {
  EDateTimeFormat, 
} from '@typing/date-formats';
import getBrowserLanguage from '@utils/browser-language';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(calendar);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const browserLanguage = getBrowserLanguage();
dayjs.locale(browserLanguage === 'en' ? browserLanguage : 'de');

export const removeTimezoneOffset = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
};

export const getTimezoneString = (date: Date) => {
  return dayjs(date).format();
};

export const getDateForDatepicker = (date: string | dayjs.Dayjs) => {
  if (typeof date === 'string') {
    date = dayjs(date);
  }

  return new Date(date.get('year'), date.get('month'), date.get('date'));
};

// Assuming that date is 'yyyy-mm-dd'
export const parseDateString = (date: string) => {
  return dayjs(date).toDate();
};

export const parseTimeFromTimezone = (time: string | null) => {
  const date = dayjs(dayjs().format('YYYY-MM-DDT') + (time !== null ? time : ''));

  return date.format('HH:mm');
};

export const parseTime = (time: string) => {
  const parts = time.split(':');
  return {
    hour: parts[0],
    minute: parts[1],
  };
};

export const getTimeFromDateTime = (dateTime: string) => {
  return {
    hour: dayjs(dateTime).format('HH'),
    minute: dayjs(dateTime).format('mm'),
  };
};

export const splitDateTimeString = (dateTime: string) => {
  const parts = dateTime.split('T');
  return {
    date: parts[0],
    time: parts[1],
  };
};

export const setTimeForDate = (time: string, date: string | Date, parseDateFormat?: string) => {
  const {
    hour, minute,
  } = parseTime(time);

  if (parseDateFormat) {
    return dayjs(date, parseDateFormat).hour(parseInt(hour)).minute(parseInt(minute)).format();
  }
  return dayjs(date).hour(parseInt(hour)).minute(parseInt(minute)).format();
};

export const areDateStringsEqual = (date1: string, date2: string) => {
  return dayjs(date1).startOf('day').isSame(dayjs(date2).startOf('day'));
};

export const formatEventDateForList = (date: string) => {
  return dayjs(date).format('DD/MM/YYYY');
};

export const formatDateForDatepicker = (date: Date | string) => {
  date = typeof date === 'string' ? getDateForDatepicker(date) : date;
  return dayjs(removeTimezoneOffset(date)).format('DD/MM/YYYY');
};

export const getTimeStringFromDateTime = (dateTime: string) => {
  const time = getTimeFromDateTime(dateTime);
  return `${time.hour}:${time.minute}`;
};

export const getDurationFromSeconds = (seconds: number, format: string = 'HH:mm:ss') => {
  return dayjs.duration(seconds, 'seconds').format(format);
};

export const getHumanizeDurationFromSeconds = (seconds: number) => {
  return dayjs.duration(seconds, 'seconds').humanize();
};

export const getFormattedDateTime = (datetime: string, format: string = 'DD.MM.YYYY, HH:mm') => {
  return dayjs(datetime).format(format);
};

export const getCalendarDate = (datetime: string, formats?: Record<string, string>) => {
  const defaultFormats = {
    sameDay: EDateTimeFormat.FullDate,
    nextDay: EDateTimeFormat.FullDate,
    nextWeek: EDateTimeFormat.FullDate,
    lastDay: EDateTimeFormat.FullDate,
    lastWeek: EDateTimeFormat.FullDate,
    sameElse: EDateTimeFormat.FullDate,
  };
  return dayjs(datetime).calendar(undefined, {
    ...defaultFormats,
    ...formats,
  });
};

export const dateIsTodayOrPast = (datetime: string) => {
  return dayjs(datetime).isSameOrBefore(dayjs());
};

export {
  dayjs,
};
