import '@front/install-composition-api';
import Vue from 'vue';
import VueRouter from 'vue-router';
import useDatatables from '@components/datatable/use-datatables';
import router from '@front/router';
import VcrApp from '@front/app.vue';
import i18n from '@front/lang/main';
import '@front/broadcasting/setup';
import '@front/plugins/vee-validate';

Vue.use(VueRouter);

useDatatables();

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(VcrApp),
}).$mount('#vcr-vue-app');
