import {
  reactive, computed,
} from '@vue/composition-api';
import useUser from '@front/composition/use-user';
import IChatMessage from '@typing/chat-message';
import * as chatMessageApi from '@api/chat-message';

const state = reactive({
  messageList: [] as IChatMessage[],
  loading: false,
});

export default function useChat() {
  const {
    currentUser,
  } = useUser();

  async function loadMessageList(expoId:string, panelId:string) {
    if (state.loading) {
      return;
    }

    state.loading = true;
    try {
      // API returns list as paginated list. But we don't need pagination here. Just get X fresh messages
      const chatMessageList = (await chatMessageApi.loadAll(expoId, panelId, {
        page: 1,
        perPage: 10000,
      })).data.data;

      state.messageList = chatMessageList?.length
        ? chatMessageList.reverse()
        : [];
    } finally {
      state.loading = false;
    }
  }

  function removeChatMessage(expoId:string, panelId:string, chatMessage:IChatMessage) {
    return chatMessageApi.remove(expoId, panelId, chatMessage.id);
  }

  function onChatMessageCreated(chatMessage:IChatMessage) {
    state.messageList.push(chatMessage);
  }

  function onChatMessageDeleted(chatMessage:IChatMessage) {
    state.messageList = state.messageList.filter((message) => message.id !== chatMessage.id);
  }

  return {
    ableToModerate: computed(() => !!currentUser?.value?.currentBrand.isChatModerator),
    messageList: computed(() => state.messageList),
    loading: computed(() => state.loading),
    loadMessageList,
    removeChatMessage,
    onChatMessageCreated,
    onChatMessageDeleted,
  };
}
