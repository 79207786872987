
import {
  defineComponent,computed,
} from '@vue/composition-api';
import {
  RouterName,
} from '@front/router';
import useBrandList from '@front/composition/use-brand-list';

export default defineComponent({
  setup() {
    const {
      currentBrand,
    } = useBrandList();
    const logoUrl = computed(() => {
      return currentBrand?.value?.logoUrl || require('@vtx-img/virtrex-logo.svg');
    });
    const altName = computed(() => {
      return currentBrand?.value?.name || 'virtrex';
    });
    return {
      RouterName,
      logoUrl,
      currentBrand,
      altName,
    };
  },
});
