
import {
  defineComponent,
} from '@vue/composition-api';

export default defineComponent({
  setup() {
    const year = new Date().getFullYear();
    return {
      year,
    };
  },
});
