
import {
  defineComponent,
} from '@vue/composition-api';
import VcrNavMenu from '@components/nav-menu/nav-menu.vue';
import VcrLogo from '@components/logo/logo.vue';
import {
  RouterName,
} from '@front/router';

export default defineComponent({
  components: {
    VcrNavMenu,
    VcrLogo,
  },

  setup() {
    return {
      RouterName,
    };
  },
});
