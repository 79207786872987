import Echo from 'laravel-echo';
import $config from '@utils/runtime-config';

window.Pusher = require('pusher-js');

const broadcaster = $config.pusher.key ? 'pusher' : 'null';

window.Echo = new Echo({
  broadcaster,
  // default: null
  key: $config.pusher.key,
  // default: null
  wsPort: $config.pusher.wsPort,
  // default: null
  wssPort: $config.pusher.wssPort,
  // default: null if cluster, wsHost or current domain otherwise
  wsHost: $config.pusher.cluster ? null : ($config.pusher.wsHost || window.location.hostname),
  // default: false
  forceTLS: $config.pusher.forceTLS,
  // default: true
  disableStats: $config.pusher.disableStats,
  // default: null
  cluster: $config.pusher.cluster,
});
