import useEventTaskManager from '@front/composition/use-event-task-manager';
import useVideoCalls from '@front/composition/use-video-calls';
import {
  EVideoCallStatus,
} from '@typing/video-call';
import showNotificationAboutDirectVideoCall from '@utils/show-notification-about-direct-video-call';

const {
  addTask: addTaskCreated,
  removeTask: removeTaskCreated,
  runTasks: runTasksCreated,
} = useEventTaskManager();

const {
  addTask: addTaskStatusChanged,
  removeTask: removeTaskStatusChanged,
  runTasks: runTasksStatusChanged,
} = useEventTaskManager();

const {
  addTask: addTaskUpdated,
  removeTask: removeTaskUpdated,
  runTasks: runTasksUpdated,
} = useEventTaskManager();

const {
  addTask: addTaskProductsUpdated,
  removeTask: removeTaskProductsUpdated,
  runTasks: runTasksProductsUpdated,
} = useEventTaskManager();

const {
  addTask: addTaskAttachmentsUpdated,
  removeTask: removeTaskAttachmentsUpdated,
  runTasks: runTasksAttachmentsUpdated,
} = useEventTaskManager();

const {
  fetchVideoCall,
} = useVideoCalls();

const joinChannel = (associateId: string) => {
  if (!window.Echo) {
    return;
  }

  window.Echo.private(`salescalls.${associateId}`)
    .listen( '.App\\Events\\Broadcast\\VideoCalls\\VideoCallCreated', (data: {
      id: string,
    }) => {
      fetchVideoCall(data.id)
        .then(videoCall => {
          if (videoCall.isDirect) {
            showNotificationAboutDirectVideoCall(videoCall);
          }
        })
        .catch(() => {})
        .finally(() => {
          runTasksCreated(data);
        });
    })
    .listen( '.App\\Events\\Broadcast\\VideoCalls\\VideoCallUpdated', (data: {
      id: string,
    }) => {
      fetchVideoCall(data.id)
        .catch(() => {})
        .finally(() => {
          runTasksUpdated(data);
        });
    })
    .listen( '.App\\Events\\Broadcast\\VideoCalls\\VideoCallStatusChanged', (data: {
      id: string,
      status: EVideoCallStatus,
    }) => {
      fetchVideoCall(data.id)
        .catch(() => {})
        .finally(() => {
          runTasksStatusChanged(data);
        });
    })
    .listen( '.App\\Events\\Broadcast\\VideoCalls\\VideoCallProductsUpdated', (data: {
      id: string,
    }) => {
      runTasksProductsUpdated(data);
    })
    .listen( '.App\\Events\\Broadcast\\VideoCalls\\VideoCallAttachmentsUpdated', (data: {
      id: string,
    }) => {
      runTasksAttachmentsUpdated(data);
    });
};

export {
  joinChannel,

  addTaskCreated,
  removeTaskCreated,

  addTaskUpdated,
  removeTaskUpdated,

  addTaskStatusChanged,
  removeTaskStatusChanged,

  addTaskProductsUpdated,
  removeTaskProductsUpdated,

  addTaskAttachmentsUpdated,
  removeTaskAttachmentsUpdated,
};
