import {
  reactive, computed,
} from '@vue/composition-api';

const state = reactive({
  code: null as null | number,
  message: null as null | String,
});

export default function useError() {
  function setError({
    code,
    message,
  }: {
    code?: number,
    message?: string,
  }) {
    if (hasError.value) {
      return;
    }

    state.code = code ?? null;
    state.message = message ?? null;
  }

  function clearError() {
    state.code = null;
    state.message = null;
  }

  const isError404 = computed(() => {
    return state.code === 404;
  });

  const hasError = computed(() => {
    return !!state.code || !!state.message;
  });

  const error = computed(() => ({
    code: state.code,
    message: state.message,
  }));

  return {
    setError,
    clearError,
    error,
    isError404,
    hasError,
  };
}
