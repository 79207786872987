export enum EThreadType {
  Direct = 'direct',
}

export interface IThread {
  id: string
  type: EThreadType
  status: null
  expoId: string
  createdAt: string
}
