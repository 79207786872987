import api from '@api/api';
import IHttpResponse from '@api/http-response';
import {
  IThreadMessageResource,
  IThreadResource,
} from '@api/resource/threads';
import {
  EThreadMessageType,
} from '@typing/thread-message';

export const getDirectConversations = (
  expoId: string,
): Promise<IHttpResponse<IThreadResource[]>> => {
  return api.get(`/api/_associate-conversations/${expoId}`);
};

export const getDirectConversationsForCurrentBrand = (): Promise<IHttpResponse<IThreadResource[]>> => {
  return api.get('/api/_associate-brand-direct-conversations');
};

export const createThreadMessage = ({
  threadParticipantId,
  type,
  text,
  attachment,
}: {
  threadParticipantId: string,
    type: EThreadMessageType,
    text?: string,
    attachment?: File,
},
): Promise<IHttpResponse<IThreadMessageResource>> => {
  const formData = new FormData();
  formData.append('type', type as EThreadMessageType);

  if (type === EThreadMessageType.Text) {
    formData.append('text', text as string);
  } else if (type === EThreadMessageType.File) {
    formData.append('attachment', attachment as File);
  }

  return api.post(`/api/_create-associate-conversation-message/${threadParticipantId}`, formData);
};

export const markThreadMessageAsRead = (
  chatThreadId: string,
  messages: string[],
): Promise<IHttpResponse<IThreadMessageResource[]>> => {
  return api.put(`/api/_mark-associate-conversation-messages-read/${chatThreadId}`,{
    messages,
  });
};
