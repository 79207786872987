import useEventTaskManager from '@front/composition/use-event-task-manager';
import IContactRequest from '@typing/contact-request';

const {
  addTask: onProjectedLeadDeleted,
  removeTask: offProjectedLeadDeleted,
  runTasks: runProjectedLeadDeleted,
} = useEventTaskManager();

const {
  addTask: onProjectedLeadCreated,
  removeTask: offProjectedLeadCreated,
  runTasks: runProjectedLeadCreated,
} = useEventTaskManager();

const joinChannel = (brandId: string) => {
  if (!window.Echo) {
    return;
  }

  window.Echo.private(`brand.${brandId}`)
    .listen('.App\\Events\\Broadcast\\ProjectedLead\\ProjectedLeadDeleted', (event: IContactRequest) => {
      runProjectedLeadDeleted(event);
    })
    .listen('.App\\Events\\Broadcast\\ProjectedLead\\ProjectedLeadCreated', (event: IContactRequest) => {
      runProjectedLeadCreated(event);
    });
};

export {
  joinChannel,
  onProjectedLeadDeleted,
  offProjectedLeadDeleted,
  onProjectedLeadCreated,
  offProjectedLeadCreated,
};
