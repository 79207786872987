import {
  reactive, computed,
} from '@vue/composition-api';
import {
  IApp, IEditableApp,
} from '@typing/app';
import {
  EAppModuleTypes,
} from '@typing/app-module';
import * as api from '@api/app';

const state = reactive({
  appList: [] as IApp[],
  loadingApp: [] as string[],
  loading: false,
});

export default function useAppList() {
  function loadAppList() {
    if (state.loading) {
      return false;
    }
    state.loading = true;

    api
      .loadAll()
      .then(result => {
        state.appList = result.data.data;
      })
      .finally(() => {
        state.loading = false;
      });
  }

  function updateOrCreate(appData: IApp) {
    const app = state.appList.find(app => app.id === appData.id);
    if (app) {
      Object.assign(app, appData);
    } else {
      state.appList.push(appData);
    }
  }

  function isLoadingApp(appId: string) {
    return state.loadingApp.includes(appId);
  }

  async function loadApp(appId: string) {
    if (isLoadingApp(appId)) {
      return null;
    }

    state.loadingApp.push(appId);

    try {
      const result = await api.get(appId);
      updateOrCreate(result.data.data);
      return result;
    } finally {
      state.loadingApp = state.loadingApp.filter(id => id !== appId);
    }
  }

  function getApp(appId: string) {
    return state.appList.find(app => app.id === appId);
  }

  async function deleteApp(appId: string) {
    await api.deleteApp(appId);
    state.appList = state.appList.filter(app => {
      return app.id !== appId;
    });
  }

  async function editApp(appId: string): Promise<IEditableApp | null> {
    if (isLoadingApp(appId)) {
      return null;
    }

    state.loadingApp.push(appId);

    try {
      const response = await api.edit(appId);

      return response.data.data;
    } finally {
      state.loadingApp = state.loadingApp.filter(id => id !== appId);
    }
  }

  const adminAppList = computed(() => state.appList.filter(app => {
    return app.modules.some(module => module.type === EAppModuleTypes.adminEntry);
  }));

  const visitorAppList = computed(() => state.appList.filter(app => {
    return app.modules.some(module => module.type === EAppModuleTypes.visitorEntry);
  }));

  return {
    appList: computed(() => state.appList),
    adminAppList,
    visitorAppList,
    loading: computed(() => state.loading),
    loadAppList,
    loadApp,
    getApp,
    deleteApp,
    editApp,
  };
}
