import {
  IAppModule, ICreateAppModule, IEditableAppModule,
} from '@typing/app-module';

export enum EAppProps {
  title = 'title',
  description = 'description',
  icon = 'icon',
  modules = 'modules',
}

export interface IApp {
  id: string,
  title: string,
  description: string,
  icon: string,
  modules: IAppModule[],
}

export interface ICreateApp {
  manifest: {
    title: string,
    description: string,
    icon: string,
    modules: ICreateAppModule[],
  }
}

export interface IEditableApp {
  title: string,
  description: string,
  icon: string,
  modules: IEditableAppModule[],
}

export interface IUpdateApp {
  manifest: {
    id: string,
    title: string,
    description: string,
    icon: string,
    modules: ICreateAppModule[],
  }
}
