export default function transformErrorToMessage(error: any, hideErrorMessage: boolean = false) {
  let text = '';
  if (typeof error !== 'string') {
    if (error.response.data) {
      const response = error.response.data;

      if (!hideErrorMessage) {
        text += response.message + ' ';
      }

      if (response.errors && Object.keys(response.errors).length > 0) {
        const errors = Object.entries(response.errors);
        errors.forEach(([
          , messages,
        ]) => {
          (messages as string[]).forEach(message => {
            text += message + ' ';
          });
        });
      }
    }
  } else {
    text = error;
  }

  return text;
}
