function updateOrCreate<Type extends { id: string }>(
  state: Type[],
  resource: Type,
) {
  const dataInStore = state.find(data => data.id === resource.id);
  if (dataInStore) {
    Object.assign(dataInStore, resource);
  } else {
    state.push(resource);
  }
}

export default updateOrCreate;
