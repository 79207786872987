import api from '@api/api';
import IHttpResponse from '@api/http-response';
import {
  IBrand,
  IBrandBroadcastStatus,
} from '@front/types/brand';
import IUpdateBrand from '@api/payload/update-brand';
import IVisitorResource from '@api/resource/visitor';
import IBrandInvite from '@api/resource/brand-invite';
import {
  IBroadcastDetails,
} from '@typing/broadcast-provider';

export const getAll = (): Promise<IHttpResponse<IBrand[]>> => {
  return api.get('/api/me/brands');
};

export const update = (brandId: string): Promise<IHttpResponse<any>> => {
  return api.post(`/api/me/brands/${brandId}`);
};

export const load = (): Promise<IHttpResponse<IBrand>> => {
  return api.get('/api/_brand');
};

export const editBrand = (payload: IUpdateBrand): Promise<IHttpResponse<IBrand>> => {
  const formData = new FormData();

  if (typeof payload.name === 'string') {
    formData.append('name', payload.name);
  }

  if (typeof payload.color === 'string') {
    formData.append('color', payload.color);
  }

  if (typeof payload.description === 'string') {
    formData.append('description', payload.description);
  }

  if (typeof payload.contactDetails === 'string') {
    formData.append('contactDetails', payload.contactDetails);
  }

  if (payload.logo) {
    formData.append('logo', payload.logo);
  }

  if (payload.headerImage) {
    formData.append('headerImage', payload.headerImage);
  }

  if (typeof payload.imprint === 'string') {
    formData.append('imprint', payload.imprint);
  }

  if (typeof payload.privacyPolicy === 'string') {
    formData.append('privacyPolicy', payload.privacyPolicy);
  }

  if(typeof payload.supportedLocales === 'string') {
    formData.append('supportedLocales', payload.supportedLocales);
  }

  return api.uploadFile('/api/_brand', formData);
};

export const editBrandBroadcastStatus = (isBroadcasting: number): Promise<IHttpResponse<IBrandBroadcastStatus>> => {
  return api.put('/api/_brand/settings', {
    isBroadcasting,
  });
};

export const loadBrandBroadcastStatus = (): Promise<IHttpResponse<IBrandBroadcastStatus>> => {
  return api.get('/api/_brand/settings');
};

export const loadAllVisitors = (expoId: string): Promise<IHttpResponse<IVisitorResource[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/visitors`);
};

export const loadBrandInvite = (inviteId: string): Promise<IHttpResponse<IBrandInvite>> => {
  return api.get(`/api/_brand-invite/${inviteId}`);
};

export const attachBrandToExpoWithInvite = (
  inviteId: string,
  brandId: string,
): Promise<IHttpResponse<any>> => {
  return api.post(`/api/_invites/${inviteId}/brands/${brandId}`);
};

export const loadBrandBroadcastingDetails = (): Promise<IHttpResponse<
IBroadcastDetails
>> => {
  return api.get('/api/_brand/broadcasting-panel');
};
