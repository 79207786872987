import api from '@api/api';
import IHttpResponse from '@api/http-response';
import IChatMessageListQuery from '@api/payload/chat-message';
import IChatMessage from '@typing/chat-message';

export const loadAll = (expoId:string, panelId:string, params:IChatMessageListQuery): Promise<IHttpResponse<IChatMessage[]>> => {
  return api.get(`/api/brand/expos/${expoId}/panels/${panelId}/chat`, {
    params,
  });
};

export const remove = (expoId:string, panelId:string, chatMessageId:string): Promise<IHttpResponse<any>> => {
  return api.delete(`/api/brand/expos/${expoId}/panels/${panelId}/chat/${chatMessageId}`);
};
