import {
  reactive, computed,
} from '@vue/composition-api';
import * as api from '@api/event';
import IEvent from '@typing/event';
import IEditEvent from '@api/payload/edit-event';

const state = reactive({
  eventList: [] as IEvent[],
  loadingEvent: [] as string[],
});

export default function useEventList() {
  function updateOrCreate(eventData: IEvent) {
    const event = state.eventList.find(event => event.id === eventData.id);
    if (event) {
      Object.assign(event, eventData);
    } else {
      state.eventList.push(eventData);
    }
  }

  function isLoadingEvent(eventId: string) {
    return state.loadingEvent.includes(eventId);
  }

  async function loadEvent(eventId: string) {
    if (isLoadingEvent(eventId)) {
      return null;
    }

    state.loadingEvent.push(eventId);
    try {
      const result = await api.load(eventId);
      updateOrCreate(result.data.data);
      return result;
    } finally {
      state.loadingEvent = state.loadingEvent.filter(id => id !== eventId);
    }
  }

  async function editEvent(eventId: string, payload: IEditEvent) {
    const result = await api.edit(eventId, payload);
    updateOrCreate(result.data.data);
    return result;
  }

  function getEvent(eventId: string) {
    return state.eventList.find(event => event.id === eventId);
  }

  async function removeEvent(eventId: string) {

    if (isLoadingEvent(eventId)) {
      return null;
    }

    state.loadingEvent.push(eventId);

    try{
      await api.remove(eventId);
      state.eventList = state.eventList.filter(item=> item.id !== eventId);
    } finally {
      state.loadingEvent = state.loadingEvent.filter(id => id !== eventId);
    }
  }

  return {
    eventList: computed(() => state.eventList),
    getEvent,
    loadEvent,
    isLoadingEvent,
    editEvent,
    removeEvent,
  };
}
