
import {
  defineComponent,
} from '@vue/composition-api';
import useUser from '@front/composition/use-user';

export default defineComponent({
  setup() {
    const {
      currentUser,
      getFullName,
    } = useUser();

    return {
      currentUser,
      getFullName,
    };
  },
});
