
import {
  defineComponent,
} from '@vue/composition-api';
import {
  VtxUserProfile,
} from '@convidera-team/virtrex-design-system';
import useUser from '@front/composition/use-user';
import {
  RouterName,
} from '@front/router';
let placeholder = require('@img/account-circle.svg');

export default defineComponent({
  components: {
    VtxUserProfile,
  },

  setup() {
    const {
      currentUser,
      getFullName,
    } = useUser();

    return {
      getFullName,
      currentUser,
      RouterName,
      placeholder,
    };
  },
});
