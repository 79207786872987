import {
  computed, reactive,
} from '@vue/composition-api';

export enum EModal {
  createExpo = 'create-expo',
  inviteAssociate = 'invite-associate',
  confirmation = 'confirmation',
  editAssociate = 'edit-associate',
  userDetails = 'user-details',
  createContact = 'create-contact',
  editContact = 'edit-contact',
  createShowroom = 'create-showroom',
  editShowroom = 'edit-showroom',
  manageExpoBrands = 'manage-expo-brands',
  manageExpoVisitors = 'manage-expo-visitors',
  manageExpoAssociates = 'manage-expo-associates',
  manageExpoApps = 'manage-expo-apps',
  manageExpoProducts = 'manage-expo-products',
  manageExpoShowrooms = 'manage-expo-showrooms',
  manageExpoTeaser = 'manage-expo-teaser',
  createEvent = 'create-event',
  editProduct = 'edit-product',
  editProductVariant = 'edit-product-variant',
  editEvent = 'edit-event',
  createStage = 'create-stage',
  editStage = 'edit-stage',
  createPanel = 'create-panel',
  editPanel = 'edit-panel',
  managePanelProducts = 'manage-panel-products',
  createProduct = 'create-product',
  createProductVariant = 'create-product-variant',
  uploadPanelVideo = 'upload-panel-video',
  editTag = 'edit-tag',
  downloadLeadsList = 'download-leads-list',
  selectBrandTag = 'select-brand-tag',
  createPackage = 'create-package',
  editPackage = 'edit-package',
  leadVisitHistoryList = 'lead-visit-history-list',
  selectProvider = 'select-provider',
  alert = 'alert',
  createRole = 'create-role',
  editRole = 'edit-role',
  viewRole = 'view-role',
  apiToken = 'api-token',
  purchaseLicence = 'purchase-licence',
  scheduleExpoEmail = 'schedule-expo-email',
  editWebhooks = 'edit-webhooks',
  editWorkingHours = 'edit-working-hours',
  languageSupport = 'language-support',
  installableApp = 'installable-app',
}

type TName = null | EModal;
type TPayload = null | Object;
type TOnClose = null | Function;

const state = reactive({
  name: null as TName,
  payload: null as TPayload,
  onClose: null as TOnClose,
});

export default function useModal() {
  function show({
    name, payload, onClose,
  }: { name: TName; payload?: TPayload; onClose?:TOnClose }) {
    state.name = name;
    if (payload) {
      state.payload = payload;
    }

    if (onClose) {
      state.onClose = onClose;
    }
  }

  function close(data: Object|null = null) {
    state.name = null;
    state.payload = null;
    state.onClose && state.onClose(data);
  }

  return {
    name: computed(() => state.name),
    payload: computed(() => state.payload),
    onClose: computed(() => state.onClose),
    show,
    close,
  };
}
