
import {
  defineComponent,
} from '@vue/composition-api';
import VcrNavBar from '@components/nav-bar/nav-bar.vue';
import VcrUserMenu from '@components/user-menu/user-menu.vue';
import VcrPageTopbar from '@components/page-top-bar/page-top-bar.vue';
import VcrModal from '@components/modal/modal.vue';
import VcrLegal from '@components/legal/legal.vue';
import VcrContactPopup from '@components/contact-popup/contact-popup.vue';
import VcrPageError from '@components/page-error/page-error.vue';
import useError from '@front/composition/use-error';

export default defineComponent({
  components: {
    VcrNavBar,
    VcrUserMenu,
    VcrPageTopbar,
    VcrModal,
    VcrLegal,
    VcrContactPopup,
    VcrPageError,
  },

  props: {
    useWideActionBar: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const {
      hasError,
    } = useError();

    return {
      hasError,
    };
  },
});
