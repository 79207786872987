import api from '@api/api';
import IHttpResponse from '@api/http-response';
import {
  IApp, ICreateApp, IEditableApp, IUpdateApp,
} from '@typing/app';

export const loadAll = (): Promise<IHttpResponse<IApp[]>> => {
  return api.get('/api/_brand/apps');
};

export const get = (appId: string): Promise<IHttpResponse<IApp>> => {
  return api.get(`/api/_brand/apps/${appId}`);
};

export const create = (payload: ICreateApp): Promise<IHttpResponse<IApp>> => {
  return api.post('/api/_brand/apps', payload);
};

export const edit = (appId: string): Promise<IHttpResponse<IEditableApp>> => {
  return api.get(`/api/_brand/apps/${appId}/edit`);
};

export const update = (appId: string, payload: IUpdateApp): Promise<IHttpResponse<IApp>> => {
  return api.put(`/api/_brand/apps/${appId}`, payload);
};

export const deleteApp = (appId: string): Promise<void> => {
  return api.delete(`/api/_brand/apps/${appId}`);
};
