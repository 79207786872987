
import {
  defineComponent,
} from '@vue/composition-api';
import VcrUserMenu from '@components/user-menu/user-menu.vue';
import VcrLegal from '@components/legal/legal.vue';
import VcrLogo from '@components/logo/logo.vue';
import {
  RouterName,
} from '@front/router';
import useError from '@front/composition/use-error';
import VcrPageError from '@components/page-error/page-error.vue';

export default defineComponent({
  components: {
    VcrUserMenu,
    VcrLegal,
    VcrLogo,
    VcrPageError,
  },

  setup() {
    const {
      hasError,
    } = useError();

    return {
      RouterName,
      hasError,
    };
  },
});
