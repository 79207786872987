
import {
  defineComponent,
} from '@vue/composition-api';
import useError from '@front/composition/use-error';
import VcrPageError from '@components/page-error/page-error.vue';

export default defineComponent({
  components: {
    VcrPageError,
  },

  setup() {
    const {
      hasError,
    } = useError();

    return {
      hasError,
    };
  },
});
