
import {
  defineComponent,
} from '@vue/composition-api';
import {
  VtxIcon,
  EIcon,
} from '@convidera-team/virtrex-design-system';
import useContactPopup from '@front/composition/use-contact-popup';

export default defineComponent({
  components: {
    VtxIcon,
  },

  setup() {
    const {
      isPopupOpened,
      openAllThreads,
      closePopup,
      notifications,
    } = useContactPopup();

    function togglePopup() {
      if (isPopupOpened.value) {
        closePopup();
      } else {
        openAllThreads();
      }
    }

    return {
      isPopupOpened,
      togglePopup,
      EIcon,
      notifications,
    };
  },

});
