
import {
  defineComponent, ref,
} from '@vue/composition-api';
import VcrFilterBar from '@components/filter-bar/filter-bar.vue';
import {
  VtxCheckbox, VtxIcon, EIcon, VtxSearchBar,
} from '@convidera-team/virtrex-design-system';
import {
  Event,
} from 'vue-tables-2';

export default defineComponent({
  components: {
    VtxCheckbox,
    VcrFilterBar,
    VtxIcon,
    VtxSearchBar,
  },

  props: {
    props: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const searching = ref(!!props.props.query);
    const selectAll = ref(false);

    const search = (query: string) => {
      props.props.search(props.props.opts.debounce)({
        target: {
          value: query,
        },
      });

      // Reset select all when performing a new search
      selectAll.value && setSelectAll(false);
    };

    const setSelectAll = (value: boolean) => {
      selectAll.value = value;

      const namespace = props.props.opts.name && ('.' + props.props.opts.name);
      Event.$emit(`vue-tables${namespace}.selectAll`, selectAll.value);
    };

    return {
      EIcon,
      search,
      searching,
      selectAll,
      setSelectAll,
    };
  },
});
