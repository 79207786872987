import {
  joinChannel,
} from '@front/broadcasting/brand';
import {
  computed,
  reactive,
} from '@vue/composition-api';
import * as api from '@api/brand';
import {
  IBrand,
} from '@front/types/brand';
import useLang from './use-lang';


const state = reactive({
  loading: false,
  brandList: [] as IBrand[],
  currentBrand: null as null | IBrand,
});

const {
  getLocalesFromLanguages,
  systemLanguages,
} = useLang();

export default function useBrandList() {
  function updateOrCreate(brandData: IBrand) {
    const brand = state.brandList.find(brand => brand.id === brandData.id);
    if (brand) {
      Object.assign(brand, brandData);
    } else {
      state.brandList.push(brandData);
    }
  }

  function loadBrandList() {
    if(state.loading){
      return false;
    }

    state.loading = true;

    api.getAll().then(result => {
      state.brandList = result.data.data;
    }).
      finally(()=> {
        state.loading = false;
      });
  }


  function updateCurrentBrand(brand: IBrand) {
    return api.update(brand.id);
  }
  async function loadCurrentBrand () {
    const result = await api.load();
    setCurrentBrand(result.data.data);
    return result.data.data;
  }

  function setCurrentBrand(brand: IBrand) {
    state.currentBrand = brand;

    if(!state.currentBrand.supportedLocales.length) {
      state.currentBrand.supportedLocales = getLocalesFromLanguages(systemLanguages.value);
    }

    joinChannel(brand.id);
  }

  function getBrand(brandId: string) {
    return state.brandList.find(brand => brand.id === brandId);
  }

  return {
    brandList: computed(()=> state.brandList),
    loading: computed(() => state.loading),
    currentBrand: computed(() => state.currentBrand),
    loadBrandList,
    setCurrentBrand,
    updateCurrentBrand,
    updateOrCreate,
    getBrand,
    loadCurrentBrand,
  };
}
