import EOrderDirection from '@api/order-direction';
import IBaseUser from '@typing/base-user';
import IProduct from '@typing/product';

export enum EVideoCallStatus {
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  CANCELLED = 'cancelled',
  FINISHED = 'finished',
}

export enum EVideoCallParticipantUserType {
  ASSOCIATE = 'App\\Models\\Associate',
  VISITOR = 'App\\Models\\Visitor',
}

export enum EVideoCallParticipantStateInCall {
  JOINED = 'joined',
  ENDED = 'ended',
  PENDING = 'pending',
}

export enum EVideoCallParticipantStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  PENDING = 'pending',
}

export interface IVideoCall {
  title: string,
  defaultTitle: string,
  description: string,
  defaultDescription: string,
  brandId: string,
  createdAt: string,
  endDatetime: string,
  expoId: string,
  id: string,
  isDirect: boolean,
  startDatetime: string,
  status: EVideoCallStatus,
  participants: Array<IVideoCallParticipant>,
}

export interface IVideoCallParticipant<T = IBaseUser> {
  id: string,
  userType: EVideoCallParticipantUserType,
  userData: T,
  status: EVideoCallParticipantStatus,
  videoCallId: string,
  isInitiator: boolean,
  stateInCall: EVideoCallParticipantStateInCall,
}

export interface IScheduleVideoCallTimeFrame {
  startDateTime: string,
  endDateTime: string,
}

export type TScheduleVideoCallTimeFrameAvailability = Record<string, boolean>;

export interface IGetVideoCallsFilters {
  statuses?: EVideoCallStatus[],
  participantStatuses?: string[],
  direct?: boolean,
  perPage?: number,
  orderDirection?: EOrderDirection,
  groupByDate?: boolean,
}

export interface IScheduleVideoCallPayload {
  title: string,
  description?: string,
  associates: string[],
  visitors: string[],
  startDatetime: string,
  guests?: string[],
}

export interface IEditVideoCallPayload {
  title: string,
  description: string,
  associates: string[],
  visitors: string[],
  startDatetime?: string,
  guests?: string[],
}

export interface IScheduleVideoCallGetAvailabilityPayload {
  associates: Array<string>,
  visitors: Array<string>,
  startDatetime: string,
  endDatetime: string,
  excludedVideoCallId?: string,
}

export type TVideoCallSuggestedProduct = Pick<IProduct, 'arUrl' | 'description' | 'id' | 'name' | 'teaserDescription' | 'threeDPreviewUrl' | 'titleImage'>;
