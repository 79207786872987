import {
  reactive, computed,
} from '@vue/composition-api';
import * as api from '@api/panel';
import IPanelResource from '@api/resource/panel';
import useBrandList from '@front/composition/use-brand-list';
import omit from '@utils/omit';
import IStoredPanel from '@typing/stored-panel';
import ICreatePanel from '@api/payload/create-panel';
import IEditPanel from '@api/payload/edit-panel';
import IStoredPanelProduct from '@typing/stored-panel-product';
import IAttachedProduct from '@typing/attached-product';
import IReorderPanelProduct from '@api/payload/reorder-panel-product';
import IUploadBroadcastVideo from '@api/payload/upload-broadcast-video';

const state = reactive({
  panelList: [] as IStoredPanel[],
  loadingList: false,
  loadingPanel: [] as string[],
  attachedProductList: [] as IStoredPanelProduct[],
  loadingAttachedProducts: [] as string[],
});

export default function usePanelList() {
  const {
    updateOrCreate: updateOrCreateBrand,
  } = useBrandList();

  function updateOrCreate(panelData: IPanelResource) {
    if (panelData.brand) {
      updateOrCreateBrand(panelData.brand);
    }

    const newData = omit(
      Object.assign({}, panelData, {
        brandId: panelData.brand.id,
      }),
      'brand',
    );

    const panel = state.panelList.find(panel => panel.id === panelData.id);
    if (panel) {
      Object.assign(panel, newData);
    } else {
      state.panelList.push(newData);
    }
  }

  async function loadPanelList(stageId: string) {
    if (state.loadingList) {
      return null;
    }

    state.loadingList = true;
    try {
      const result = await api.loadAll(stageId);
      for (const panel of result.data.data) {
        updateOrCreate(panel);
      }
      return result;
    } finally {
      state.loadingList = false;
    }
  }

  async function createPanel(stageId: string, payload: ICreatePanel) {
    const result = await api.create(stageId, payload);
    updateOrCreate(result.data.data);
    return result;
  }

  async function editPanel(panelId: string, payload: IEditPanel) {
    const result = await api.edit(panelId, payload);
    updateOrCreate(result.data.data);
    return result;
  }

  async function setDateForPanel(panelId: string, startDatetime: string, endDatetime: string) {
    const result = await api.setDate(panelId, startDatetime, endDatetime);
    updateOrCreate(result.data.data);
    return result;
  }

  async function removePanel(panelId: string) {
    await api.remove(panelId);
    state.panelList = state.panelList.filter(panel => panel.id !== panelId);
  }

  function isLoadingPanel(panelId: string) {
    return state.loadingPanel.includes(panelId);
  }

  async function loadPanel(panelId: string) {
    if (isLoadingPanel(panelId)) {
      return null;
    }

    state.loadingPanel.push(panelId);
    try {
      const result = await api.load(panelId);
      updateOrCreate(result.data.data);
      return result;
    } finally {
      state.loadingPanel = state.loadingPanel.filter(id => id !== panelId);
    }
  }

  function getPanel(panelId: string) {
    return state.panelList.find(panel => panel.id === panelId);
  }

  function getPanelsForStage(stageId: string) {
    return state.panelList.filter(panel => panel.stageId === stageId);
  }

  function setAttachedProductList(productList: IAttachedProduct[], panelId: string) {
    state.attachedProductList = productList
      .map(product =>
        Object.assign({}, product, {
          panelId,
        }),
      )
      .sort((a, b) => a.order - b.order);
  }

  function isLoadingAttachedProducts(panelId: string) {
    return state.loadingAttachedProducts.includes(panelId);
  }

  async function loadAttachedProducts(panelId: string) {
    if (isLoadingAttachedProducts(panelId)) {
      return null;
    }

    state.loadingAttachedProducts.push(panelId);

    try {
      const result = await api.loadAttachedProducts(panelId);
      setAttachedProductList(result.data.data, panelId);
      return result;
    } finally {
      state.loadingAttachedProducts = state.loadingAttachedProducts.filter(id => id !== panelId);
    }
  }

  async function detachProducts(panelId: string, products: string[]) {
    const result = await api.detachProducts(panelId, products);
    setAttachedProductList(result.data.data, panelId);
  }

  async function reorderProduct(payload: IReorderPanelProduct) {
    const result = await api.reorderProduct(payload);
    setAttachedProductList(result.data.data, payload.panelId);
    return result;
  }

  async function uploadBroadcastVideo(payload: IUploadBroadcastVideo) {
    const result = await api.uploadBroadcastVideo(payload);
    updateOrCreate(result.data.data);
    return result;
  }

  async function deleteBroadcastVideo(panelId: string) {
    const result = await api.deleteBroadcastVideo(panelId);
    updateOrCreate(result.data.data);
    return result;
  }

  return {
    loadingList: computed(() => state.loadingList),
    loadPanelList,
    getPanelsForStage,
    createPanel,
    editPanel,
    setDateForPanel,
    removePanel,
    loadPanel,
    getPanel,
    isLoadingPanel,
    loadAttachedProducts,
    attachedProductList: computed(() => state.attachedProductList),
    isLoadingAttachedProducts,
    detachProducts,
    reorderProduct,
    uploadBroadcastVideo,
    deleteBroadcastVideo,
  };
}
