import api from '@api/api';
import IHttpResponse from '@api/http-response';
import IEvent from '@typing/event';
import ICreateEvent from '@api/payload/create-event';
import IEditEvent from '@api/payload/edit-event';
import IReorderEvent from '@api/payload/reorder-event';

export const loadAll = (expoId: string): Promise<IHttpResponse<IEvent[]>> => {
  return api.get(`/api/_brand/expos/${expoId}/events`);
};

export const load = (eventId: string): Promise<IHttpResponse<IEvent>> => {
  return api.get(`/api/_brand/events/${eventId}`);
};

export const create = (expoId: string, payload: ICreateEvent): Promise<IHttpResponse<IEvent>> => {
  const formData = new FormData();
  formData.append('name', JSON.stringify(payload.name));
  formData.append('description', JSON.stringify(payload.description));
  formData.append('start', payload.start);
  formData.append('end', payload.end);
  formData.append('image', payload.image);
  return api.post(`/api/_brand/expos/${expoId}/events`, formData);
};

export const edit = (eventId: string, payload: IEditEvent): Promise<IHttpResponse<IEvent>> => {
  const formData = new FormData();
  if (typeof payload.name === 'object') {
    formData.append('name', JSON.stringify(payload.name));
  }

  if (typeof payload.description === 'object') {
    formData.append('description', JSON.stringify(payload.description));
  }

  if (typeof payload.start === 'string') {
    formData.append('start', payload.start);
  }

  if (typeof payload.end === 'string') {
    formData.append('end', payload.end);
  }

  if (payload.image) {
    formData.append('image', payload.image);
  }

  return api.uploadFile(`/api/_brand/events/${eventId}`, formData);
};


export const remove = (eventId: string): Promise<IHttpResponse<any>> => {
  return api.delete(`/api/_brand/events/${eventId}`);
};

export const reorder = ({
  eventId,
  order,
}: IReorderEvent): Promise<IHttpResponse<IEvent[]>> => {
  return api.put(`/api/_brand/events/${eventId}/order`, {
    order,
  });
};
