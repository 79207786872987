export enum EThreadMessageType {
  Text = 'text',
  File = 'file',
}

export enum EThreadMessageStatus {
  New = 'new',
}

export interface IThreadMessageAttachment {
  id: string,
  name: string,
  path: string,
  type: string,
  uploadDate: string
}

export interface IThreadMessage {
  id: string
  threadId: string
  participantId: string
  attachment: null | IThreadMessageAttachment,
  status: EThreadMessageStatus
  text: string
  type: EThreadMessageType
  createdAt: string
  updatedAt: string
}
